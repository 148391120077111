define('loftus/models/receiving-cart', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecordId: _emberData.default.attr('number'),
    zone: _emberData.default.attr('number'),
    full: _emberData.default.attr({ readOnly: true }),
    receivingBins: _emberData.default.hasMany('receiving-bin'),
    bins: Ember.computed.filterBy('receivingBins', 'binType', 'full_tote'),
    cartBins: Ember.computed.filterBy('receivingBins', 'binType', 'full_cart'),
    bin: Ember.computed.alias('cartBins.firstObject')
  });
});