define('loftus/components/list-calls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      newCall: function newCall() {
        var call = this.get('store').createRecord('call', {
          user: this.get('currentUser.user'),
          customer: this.get('customer'),
          startedAt: new Date(),
          updatedAt: new Date()
        });
        this.set('modalCall', call);
      },
      clearModalCall: function clearModalCall() {
        this.set('modalCall', null);
      },
      togglePlay: function togglePlay(call) {
        call.toggleProperty('play');
      }

    }

  });
});