define('loftus/routes/application', ['exports', 'loftus/mixins/secure-default-route-factory', 'ember-simple-auth/mixins/application-route-mixin', 'ember-keyboard-shortcuts/mixins/route', 'loftus/mixins/loading-slider'], function (exports, _secureDefaultRouteFactory, _applicationRouteMixin, _route, _loadingSlider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.Route.reopenClass(_secureDefaultRouteFactory.default); /* global localStorage, navigator */
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _route.default, _loadingSlider.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    title: function title(tokens) {
      tokens = Ember.makeArray(tokens);
      tokens.unshift('Dash');
      return tokens.reverse().join(' - ');
    },
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    afterModel: function afterModel() {
      if (this.get('currentUser.user.jwt')) {
        var session = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
        session.authenticated.token = this.get('currentUser.user.jwt');
        if (session) {
          localStorage.setItem('ember_simple_auth-session', JSON.stringify(session));
        }
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('currentUser').load(); //.catch(() => this.get('session').invalidate());
    },

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      extendSession: function extendSession(jwt) {
        this.get('session').extend(jwt);
      },
      setModalVendor: function setModalVendor(vendor) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalVendor', vendor);
        }, 5);
      },
      clearModalVendor: function clearModalVendor() {
        this.controller.set('modalVendor', null);
      },
      setModalCall: function setModalCall(call) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalCall', call);
        }, 1);
      },
      clearModalCall: function clearModalCall() {
        this.controller.set('modalCall', null);
      },
      setModalContact: function setModalContact(contact) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalContact', contact);
        }, 1);
      },
      clearModalContact: function clearModalContact() {
        this.controller.set('modalContact', null);
      },
      setModalNote: function setModalNote(note) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalNote', note);
        }, 1);
      },
      setModalLead: function setModalLead(lead) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalLead', lead);
        }, 1);
      },
      setModalItem: function setModalItem(item) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalItem', item);
        }, 1);
      },
      getModalItem: function getModalItem(id) {
        var _this = this;
        this.get('store').findRecord('item', id).then(function (item) {
          _this.controller.set('modalItem', item);
        });
      },
      toggleTimeClock: function toggleTimeClock() {
        this.controller.toggleProperty('timeClock');
      },
      toggleReceiptUpload: function toggleReceiptUpload() {
        this.get('store').createRecord('document', { scannedAt: new Date(), likelyModel: 'Receipt', user: this.get('currentUser.user'), url: null });
      },
      toggleCall: function toggleCall() {
        this.controller.set('modalCall', this.controller.get('autoCall'));
      }

    },
    keyboardShortcuts: {
      'ctrl+o': function ctrlO() {
        this.$('.site-search input').focus();
        //this.controllerFor('application').send('toggleOrderSearch');
        return false;
      }
    }
  });
});