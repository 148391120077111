define('loftus/models/special-order-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    quantityShipped: _emberData.default.attr('number'),
    expectedAt: _emberData.default.attr('date'),
    shippedAt: _emberData.default.attr('date'),
    specialOrder: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    emailNotificationAt: _emberData.default.attr('date'),
    cancelAt: _emberData.default.attr('date'),
    canceledAt: _emberData.default.attr('date'),
    userActions: _emberData.default.hasMany('user-action'),
    originalOrders: _emberData.default.hasMany('original-order'),
    order: _emberData.default.belongsTo('order'),
    originalOrderItems: _emberData.default.hasMany('original-order-item'),
    item: _emberData.default.belongsTo('item'),
    orderItem: _emberData.default.belongsTo('order-item'),
    customer: _emberData.default.belongsTo('customer'),
    orderItems: _emberData.default.hasMany('order-item')
  });
});