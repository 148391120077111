define('loftus/components/item-adjustments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      createAdjustment: function createAdjustment() {
        this.get('store').createRecord('item-adjustment', {
          item: this.get('model')
        });
      },
      saveAdjustment: function saveAdjustment(ia) {
        var _this = this;
        ia.save().then(function (ia) {
          if (_this.get('model')) {
            _this.set('model.onHand', ia.get('adjustedQuantity'));
          }
        });
      },
      destroyAdjustment: function destroyAdjustment(ia) {
        ia.destroyRecord();
      },
      approve: function approve(ia) {
        ia.set('approvedAt', new Date());
        ia.save();
      }
    }
  });
});