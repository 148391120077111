define('loftus/components/item-assortment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      save: function save(ai) {
        ai.save();
      },
      destroy: function destroy(ai) {
        ai.destroyRecord();
      },
      insert: function insert() {
        var _this = this;
        this.get('store').queryRecord('item', { filter: { sku: this.get('insertSku') } }).then(function (assorted) {
          _this.set('insertSku', null);
          _this.get('store').createRecord('assortment', { item: _this.get('model'), assortedItem: assorted }).save();
        });
      }
    }
  });
});