define('loftus/services/data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    categories: Ember.computed(function () {
      return this.get('store').findAll('category');
    })
  });
});