define('loftus/components/calls-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    query: {},
    salesOptions: Ember.computed(function () {
      return this.get('store').query('user', { scope: 'with_extension' });
    }),
    directionOptions: ['inbound', 'outbound'],
    purposeOptions: ["Administrative", "Back Order", "Call Out", "Call Out/Email", "Follow Up", "Follow Up/Admin", "Follow Up/Call Out", "Morphsuit Contest", "Negotiation", "New Registration", "Project", "Prospecting", "Returning Call", "Support"],
    statusOptions: ["Attended Dialled", "Cancelled", "Missed", "Overdue", "Received", "Scheduled", "Scheduled Attended", "Scheduled Attended Delay", "Unattended Dialled"],
    actions: {
      contactSelect: function contactSelect(contact) {
        this.set('query.customer', null);
        this.set('query.contact', null);
        this.set('query.lead', null);
        this.set('query.' + contact.model, contact.id);
        this.set('contact', contact.display);
      },
      searchCalls: function searchCalls() {

        var query = {
          customer_id: this.get('query.customer'),
          contact_id: this.get('query.contact'),
          lead_id: this.get('query.lead'),
          user_id: this.get('query.salesPerson.id'),
          direction: this.get('query.direction'),
          purpose: this.get('query.purpose'),
          status: this.get('query.status'),
          startDate: this.get('query.startDate'),
          endDate: this.get('query.endDate'),
          subject: this.get('query.subject'),
          description: this.get('query.description'),
          result: this.get('query.result')
        };

        this.set('calls', null);
        var calls = this.get('store').query('call', { filter: query });
        this.set('calls', calls);
      }

    }

  });
});