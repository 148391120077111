define('loftus/routes/purchase-orders', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.query('purchase-order', {
        page: params.page,
        per_page: params.per_page
      });
    },


    queryParams: {
      page: {
        refreshModel: true
      },
      per_page: {
        refreshModel: true
      }
    }
  });
});