define('loftus/models/received-event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    vendorName: _emberData.default.attr('string'),
    reorderNumber: _emberData.default.attr('string'),
    receivedBy: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    unit: _emberData.default.attr('string'),
    baseCost: _emberData.default.attr('number'),
    freightCost: _emberData.default.attr('number'),
    unitCost: _emberData.default.attr('number'),
    quantityFactored: _emberData.default.attr('number'),
    uvId: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    poNumber: _emberData.default.attr('string'),
    receivedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    purchaseOrderItem: _emberData.default.belongsTo('purchase-order-item'),
    user: _emberData.default.belongsTo('user')
  });
});