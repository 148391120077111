define('loftus/models/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    department: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    salutation: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    homePhone: _emberData.default.attr('string'),
    cellPhone: _emberData.default.attr('string'),
    altPhone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    altEmail: _emberData.default.attr('string'),
    reportsTo: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    altAddress1: _emberData.default.attr('string'),
    altAddress2: _emberData.default.attr('string'),
    altCity: _emberData.default.attr('string'),
    altState: _emberData.default.attr('string'),
    altZip: _emberData.default.attr('string'),
    altCountry: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    emailOptOutAt: _emberData.default.attr('date'),
    leadSource: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    createdById: _emberData.default.attr('number'),
    updatedById: _emberData.default.attr('number'),
    emailOrders: _emberData.default.attr('boolean'),
    lastActivityAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    zohoId: _emberData.default.attr('string'),
    marketing: _emberData.default.attr('boolean'),
    invoices: _emberData.default.attr('boolean'),
    accountsPayable: _emberData.default.attr('boolean'),
    generalContact: _emberData.default.attr('boolean'),
    purchasing: _emberData.default.attr('boolean'),
    pricing: _emberData.default.attr('boolean'),
    legal: _emberData.default.attr('boolean'),
    substitutions: _emberData.default.attr('boolean'),
    customer: _emberData.default.belongsTo('customer', { inverse: 'contacts' }),
    vendor: _emberData.default.belongsTo('vendor', { inverse: 'contacts' }),
    user: _emberData.default.belongsTo('user'),
    alerts: _emberData.default.hasMany('alert'),
    texts: _emberData.default.hasMany('text'),
    entity: Ember.computed('customer', 'vendor', function () {
      return this.get('customer') || this.get('vendor');
    }),
    emailOptOut: Ember.computed('emailOptOutAt', function () {
      return !!this.get('emailOptOutAt');
    }),
    company: Ember.computed('customer.company', function () {
      return this.get('customer.company');
    })

  });
});