define('loftus/components/list-orders', ['exports', 'ember-keyboard-shortcuts/mixins/component', 'moment'], function (exports, _component, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.default, {
    store: Ember.inject.service(),
    tabs: [{ name: 'Open', id: 'open' }, { name: 'Approved', id: 'approved' }, { name: 'Today', id: 'today' }, { name: 'This Week', id: 'this-week' }, { name: 'Shipped Today', id: 'shipped-today' }, { name: 'Unshipped Today', id: 'unshipped-today' }, { name: 'Unshipped This Week', id: 'unshipped-this-week' }, { name: 'Shippable', id: 'shippable' }, { name: 'Unbilled', id: 'unbilled' }, { name: 'Report', id: 'report' }],
    columns: [{ title: 'Date', name: 'placedAt', css: 'date', column: 0, targets: [0], visible: true, type: 'date' }, { title: 'Number', name: 'number', css: 'number', column: 1, targets: [1], visible: true, link: 'id', path: 'order.edit' }, { title: 'PO Number', name: 'poNumber', css: 'po-number', column: 2, targets: [2], visible: true }, { title: 'Origin', name: 'origin', css: 'origin', column: 3, targets: [3], visible: true, type: 'title' }, { title: 'Account', name: 'customerNumber', css: 'number', column: 4, targets: [4], visible: true }, { title: 'Customer', name: 'company', css: 'customer', column: 5, targets: [5], visible: true, link: 'customerId', path: 'customer.edit' }, { title: 'Terms', name: 'terms', css: 'terms', column: 6, targets: [6], visible: true }, { title: 'Status', name: 'status', css: 'status', column: 7, targets: [7], visible: true }, { title: 'Service', name: 'shipVia', css: 'terms', column: 8, targets: [8], visible: true }, { title: 'Call', name: 'callOnArrival', css: 'terms', column: 9, targets: [9], visible: true }, { title: 'Sub Total', name: 'subTotal', css: 'total', column: 10, targets: [10], visible: true, type: 'money', foot: 'sum' }, { title: 'Sales Tax', name: 'salesTax', css: 'total', column: 11, targets: [11], visible: true, type: 'money', foot: 'sum' }, { title: 'Total', name: 'total', css: 'total', column: 12, targets: [12], visible: true, type: 'money', foot: 'sum' }, { title: 'Approved At', name: 'approvedAt', css: 'date', column: 13, targets: [13], visible: true, type: 'datetime' }, { title: 'Booked For', name: 'bookedAt', css: 'date', column: 14, targets: [14], visible: true, type: 'date' }, { title: 'Booked Amount', name: 'bookedAmount', css: 'total', column: 15, targets: [15], visible: true, type: 'money', foot: 'sum' }, { title: 'Ship', name: 'shipWhenAvailable', css: 'terms', column: 16, targets: [16], visible: true }],
    activeTab: 'open',
    orderStatuses: [{ name: 'Open', value: 'open' }, { name: 'Billed', value: 'billed' }, { name: 'Paid', value: 'paid' }, { name: 'Shipped', value: 'shipped' }],
    promoOptions: Ember.computed(function () {
      return this.get('store').findAll('promo');
    }),
    eventOptions: Ember.computed(function () {
      return this.get('store').findAll('event');
    }),
    salesRepOptions: Ember.computed(function () {
      return this.get('store').findAll('sales-rep');
    }),
    openOrders: Ember.computed('model.@each.status', function () {
      return this.get('model').filter(function (order) {
        return order.status === 'open' || !order.status;
      });
    }),
    approvedOrders: Ember.computed('model.@each.approvedAt', 'model.@each.pickableAt', function () {
      return this.get('model').filter(function (order) {
        return order.approvedAt && !order.pickableAt;
      });
    }),
    todaysOrders: Ember.computed('model.@each.status', function () {
      return this.get('model').filter(function (order) {
        return (0, _moment.default)().startOf('day').isSameOrBefore(order.placedAt);
      });
    }),
    thisWeeksOrders: Ember.computed('model.@each.status', function () {
      return this.get('model').filter(function (order) {
        return (0, _moment.default)().startOf('week').isSameOrBefore(order.placedAt);
      });
    }),
    todaysShippedOrders: Ember.computed('model.@each.status', function () {
      return this.get('model').filter(function (order) {
        return order.shippedAt && (0, _moment.default)().startOf('day').isSameOrBefore(order.shippedAt);
      });
    }),
    todaysUnshippedOrders: Ember.computed('model.@each.status', function () {
      return this.get('model').filter(function (order) {
        return !order.shippedAt && (0, _moment.default)().startOf('day').isSameOrBefore(order.placedAt);
      });
    }),
    thisWeeksUnshippedOrders: Ember.computed('model.@each.status', function () {
      return this.get('model').filter(function (order) {
        return !order.shippedAt && (0, _moment.default)().startOf('week').isSameOrBefore(order.placedAt);
      });
    }),
    unbilledOrders: Ember.computed('model.@each.status', function () {
      return this.get('model').filter(function (order) {
        return order.shippedAt && order.status === 'open';
      });
    }),
    shippableOrders: Ember.computed('model.@each.status', function () {
      return this.get('model').filter(function (order) {
        return !order.shipWhenAvailable && order.mustShipBy < (0, _moment.default)().add(2, 'weeks') && order.status === 'open';
      });
    }),

    actions: {
      focusTab: function focusTab(activeTab) {
        this.set('activeTab', activeTab.id);
      },
      setOrderStatus: function setOrderStatus(status) {
        this.set('selected', status);
        if (status) {
          this.set('orderStatus', status.value);
        } else {
          this.set('orderStatus', null);
        }
      },
      pikaDate: function pikaDate(field, date) {
        this.set(field, date);
      },
      fetchReport: function fetchReport() {

        var order = this.get('store').createRecord('order');

        var query = {
          q: 'query',
          options: {
            order_start_date: this.get('orderStartDate'),
            order_end_date: this.get('orderEndDate'),
            approved_start_date: this.get('approvedStartDate'),
            approved_end_date: this.get('approvedEndDate'),
            booked_start_date: this.get('bookedStartDate'),
            booked_end_date: this.get('bookedEndDate'),
            bill_start_date: this.get('billStartDate'),
            bill_end_date: this.get('billEndDate'),
            ship_start_date: this.get('shipStartDate'),
            ship_end_date: this.get('shipEndDate'),
            customer_number: this.get('customerNumber'),
            sales_rep_id: this.get('salesRep.id'),
            po_number: this.get('poNumber'),
            processed_by: this.get('processedBy'),
            origin_code: this.get('originCode'),
            customer_name: this.get('customerName'),
            status: this.get('orderStatus'),
            promo_id: this.get('promo.id'),
            event_id: this.get('event.id'),
            cold_call: this.get('coldCall'),
            taxed: this.get('taxed')
          } };
        var _this = this;
        this.set('queryResults', null);
        order.query(query).then(function (response) {
          _this.set('queryResults', response);
        });
      }
    },
    keyboardShortcuts: {
      'ctrl+s': {
        action: 'save'
      },
      'ctrl+1': function ctrl1() {
        this.send('focusTab', this.get('tabs')[0]);
        return false;
      },
      'ctrl+2': function ctrl2() {
        this.send('focusTab', this.get('tabs')[2 - 1]);
        return false;
      },
      'ctrl+3': function ctrl3() {
        this.send('focusTab', this.get('tabs')[3 - 1]);
        return false;
      },
      'ctrl+4': function ctrl4() {
        this.send('focusTab', this.get('tabs')[4 - 1]);
        return false;
      },
      'ctrl+5': function ctrl5() {
        this.send('focusTab', this.get('tabs')[5 - 1]);
        return false;
      },
      'ctrl+6': function ctrl6() {
        this.send('focusTab', this.get('tabs')[6 - 1]);
        return false;
      },
      'ctrl+7': function ctrl7() {
        this.send('focusTab', this.get('tabs')[7 - 1]);
        return false;
      },
      'ctrl+8': function ctrl8() {
        this.send('focusTab', this.get('tabs')[8 - 1]);
        return false;
      },
      'ctrl+9': function ctrl9() {
        this.send('focusTab', this.get('tabs')[9 - 1]);
        return false;
      },
      'ctrl+0': function ctrl0() {
        this.send('focusTab', this.get('tabs')[10 - 1]);
        return false;
      }

    }

  });
});