define('loftus/components/assortment-picking-bin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    incompleteRecords: Ember.computed.filter('batch.projectRecords.@each.completedAt', function (record) {
      return !record.get('completedAt');
    }),
    actions: {
      continue: function _continue() {
        //this.set('batch',this.store.queryRecord('project-batch',{work: true}));
        this.set('batch.completedAt', new Date());
        if (this.get('batch.id') === '1' || this.get('batch.id') === '2') {
          this.get('router').transitionTo('index');
          return;
        }
        this.get('batch').save();
        if (this.get('batch.project.continueRoute.length') > 0) {
          this.get('router').transitionTo(this.get('batch.project.continueRoute'));
        } else {
          this.get('router').transitionTo('index');
        }
      },
      didTransition: function didTransition() {
        console.log('transitioned');
      }
    }
  });
});