define('loftus/components/documents-list', ['exports', 'ember-drop-zone/utils/get-files', 'ember-uploader', 'cryptojs'], function (exports, _getFiles, _emberUploader, _cryptojs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    getFiles: _getFiles.default,
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      addDocument: function addDocument() {
        var doc = this.get('store').createRecord('document', { scannableType: this.get('type'), scannableId: this.get('model.id') });
        doc.save();
        this.get('model.documents').pushObject(doc);
      },
      uploadFile: function uploadFile() {

        if (this.get('charge.id')) {
          var fileUpload = document.getElementById('file-update-' + this.get('charge.id'));
          this.send('uploadReceipt', this.get('charge'), fileUpload.files);
        } else {
          var _this = this;
          this.get('charge').save().then(function () {
            setTimeout(function () {
              var fileUpload = document.getElementById('file-update-' + _this.get('charge.id'));
              _this.send('uploadReceipt', _this.get('charge'), fileUpload.files);
            }, 100);
          });
        }
      },
      uploadDocument: function uploadDocument(doc, files) {

        var reader = new FileReader();

        reader.onloadend = function () {
          var hash = _cryptojs.default.MD5(reader.result);

          var uploader = _emberUploader.default.S3Uploader.create({
            signingUrl: '/api/documents/' + doc.get('id') + '/sign?md5=' + hash
          });

          uploader.on('didUpload', function (response) {
            // S3 will return XML with url
            var uploadedUrl = $(response).find('Location')[0].textContent;
            // http://yourbucket.s3.amazonaws.com/file.png
            uploadedUrl = decodeURIComponent(uploadedUrl);
            doc.reload();
          });

          //if (!Ember.isEmpty(files)) {
          // Send a sign request then upload to S3
          // this second argument is optional and can to be sent as extra data with the upload
          uploader.upload(files[0]);
          //}

        };

        reader.readAsBinaryString(files[0]);
      }

    }
  });
});