define('loftus/models/bot', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    ssh: _emberData.default.attr('string'),
    localPort: _emberData.default.attr('number'),
    ip: _emberData.default.attr('string'),
    port: _emberData.default.attr('number'),
    command: _emberData.default.attr('number'),
    task: _emberData.default.attr('string'),
    route: _emberData.default.belongsTo('route', { inverse: 'bot' }),
    battery: _emberData.default.attr('number'),
    lastCode: _emberData.default.attr('number'),
    currentCode: _emberData.default.attr('number'),
    xBias: _emberData.default.attr('number'),
    yBias: _emberData.default.attr('number'),
    angularDeviation: _emberData.default.attr('number'),
    angle: _emberData.default.attr('number'),
    action: _emberData.default.attr('number'),
    sidelined: _emberData.default.attr('boolean'),
    lastAction: _emberData.default.attr('number'),
    agvStatus: _emberData.default.attr('string'),
    liftState: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    pausedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    control: _emberData.default.attr('string'),
    camUrl: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    centeringAt: _emberData.default.attr('date'),
    frontCamUrl: _emberData.default.attr('string', { readOnly: true }),
    zone: _emberData.default.belongsTo('zone'),
    calibratingAt: _emberData.default.attr('date'),
    powerOffAt: _emberData.default.attr('date'),
    piOfflineAt: _emberData.default.attr('date'),
    speed: _emberData.default.attr('number'),
    lidar: _emberData.default.attr('number'),
    setLidar: _emberData.default.attr('number'),
    setSpd: _emberData.default.attr('number'),
    lidarBlocks: _emberData.default.attr('number'),
    getRoute: (0, _emberApiActions.memberAction)({ path: 'route', type: 'get' }),
    runRoute: (0, _emberApiActions.memberAction)({ path: 'route', type: 'put' }),
    routes: _emberData.default.hasMany('route'),
    pod: _emberData.default.belongsTo('pod'),
    point: _emberData.default.belongsTo('point'),
    px: Ember.computed.alias('zone.px'),
    scale: Ember.computed.alias('zone.scale'),
    offlineAt: _emberData.default.attr('date', { readOnly: true }),
    heartbeatAt: _emberData.default.attr('date'),
    offline: Ember.computed('offlineAt', 'heartbeatAt', function () {
      return this.get('offlineAt') && this.get('offlineAt') > this.get('heartbeatAt');
    }),
    radius: Ember.computed('zone.scale', function () {
      return this.get('scale') * 36;
    }),
    plateRadius: Ember.computed('zone.scale', function () {
      return this.get('scale') * 23;
    }),
    xx: Ember.computed.alias('point.xx'),
    yy: Ember.computed.alias('point.yy'),
    color: Ember.computed('id', function () {
      if (this.get('id') * 1 === 1) {
        return '#2980B9';
      } else if (this.get('id') * 1 === 2) {
        return '#1ABC9C';
      } else if (this.get('id') * 1 === 3) {
        return '#F1C40F';
      } else if (this.get('id') * 1 === 4) {
        return '#9B59B6';
      } else if (this.get('id') * 1 === 5) {
        return '#34495E';
      } else if (this.get('id') * 1 === 6) {
        return '#3498DB';
      } else if (this.get('id') * 1 === 7) {
        return '#27AE60';
      } else if (this.get('id') * 1 === 8) {
        return '#F39C12';
      } else if (this.get('id') * 1 === 9) {
        return '#8E44AD';
      } else if (this.get('id') * 1 === 10) {
        return '#2C3E50';
      }
    }),
    camStyle: Ember.computed('largeImage', 'point.x', 'point.y', 'xx', 'yy', function () {
      return 'left: ' + (this.get("xx") + 55 * this.get('scale')) + 'px; top: ' + (this.get("yy") + 54 * this.get('scale')) + 'px; position: absolute; height: ' + this.get('scale') * (this.get('largeImage') ? 200 : 30) + 'px; border: 1px solid;';
    }),
    frontCamStyle: Ember.computed('largeImage', 'largeFrontImage', 'point.x', 'point.y', 'xx', 'yy', function () {
      if (this.get('largeFrontImage')) {
        if (this.get('largeImage')) {
          return 'left: ' + (this.get("xx") + 55 * this.get('scale')) + 'px; top: ' + (this.get("yy") + 256 * this.get('scale')) + 'px; position: absolute; height: ' + this.get('scale') * (this.get('largeFrontImage') ? 480 : 30) + 'px; border: 1px solid;';
        } else {
          return 'left: ' + (this.get("xx") + 55 * this.get('scale')) + 'px; top: ' + (this.get("yy") + 84 * this.get('scale')) + 'px; position: absolute; height: ' + this.get('scale') * (this.get('largeFrontImage') ? 480 : 30) + 'px; border: 1px solid;';
        }
      } else {
        return 'left: ' + (this.get("xx") + 55 * this.get('scale')) + 'px; top: ' + (this.get("yy") + 24 * this.get('scale')) + 'px; position: absolute; height: ' + this.get('scale') * (this.get('largeFrontImage') ? 480 : 30) + 'px; border: 1px solid;';
      }
    }),
    controlStyle: Ember.computed('largeImage', 'largeFrontImage', 'point.x', 'point.y', 'xx', 'yy', function () {
      if (this.get('largeImage')) {
        return 'left: ' + (this.get("xx") + 55 * this.get('scale')) + 'px; top: ' + (this.get("yy") + 740 * this.get('scale')) + 'px; position: absolute; padding: 0.25em; background: #fff;';
      } else {
        return 'left: ' + (this.get("xx") + 55 * this.get('scale')) + 'px; top: ' + (this.get("yy") + 570 * this.get('scale')) + 'px; position: absolute; padding: 0.25em; background: #fff;';
      }
    }),

    statusStyle: Ember.computed('xx', 'yy', function () {
      return 'left: ' + (this.get('xx') + this.get('scale') * 20) + 'px; top: ' + (this.get('yy') + this.get('scale') * 60) + 'px; position: absolute; height: ' + this.get('scale') * 10 + 'px;';
    }),
    piStyle: Ember.computed('xx', 'yy', function () {
      return 'left: ' + (this.get('xx') + this.get('scale') * 20) + 'px; top: ' + (this.get('yy') + this.get('scale') * 30) + 'px; position: absolute; height: ' + this.get('scale') * 10 + 'px;';
    }),
    codeStyle: Ember.computed('xx', 'yy', function () {
      return 'left: ' + (this.get('xx') + this.get('scale') * 28) + 'px; top: ' + (this.get('yy') + this.get('scale') * 68) + 'px; position: absolute; height: ' + this.get('scale') * 10 + 'px;';
    }),
    statusIcon: Ember.computed('agvStatus', 'task', 'liftState', 'battery', 'angle', function () {
      // console.log(this.get('heartbeatAt'));
      // console.log(this.get('offlineAt'));
      if (this.get('agvStatus') == 'charging' || this.get('agvStatus') == 'charging_docking') {
        if (this.get('battery') > 90) {
          return 'battery-full';
        } else if (this.get('battery') > 60) {
          return 'battery-three-quarters';
        } else if (this.get('battery') > 30) {
          return 'battery-half';
        } else if (this.get('battery') > 10) {
          return 'battery-quarter';
        } else {
          return 'battery-empty';
        }
      } else if (this.get('agvStatus') === 'alarm') {
        return 'bell-o';
      } else if (this.get('agvStatus') === 'emergency_stop_press') {
        return 'life-ring';
      } else if (this.get('agvStatus') === 'forward') {
        if (this.get('angle') == 0 || this.get('angle') == 360) {
          return 'arrow-right';
        } else if (this.get('angle') == 90) {
          return 'arrow-up';
        } else if (this.get('angle') == 180) {
          return 'arrow-left';
        } else if (this.get('angle') == 270) {
          return 'arrow-down';
        }
      } else if (this.get('agvStatus') === 'backward') {
        if (this.get('angle') == 0 || this.get('angle') == 360) {
          return 'arrow-left';
        } else if (this.get('angle') == 90) {
          return 'arrow-down';
        } else if (this.get('angle') == 180) {
          return 'arrow-right';
        } else if (this.get('angle') == 270) {
          return 'arrow-up';
        }
      } else if (this.get('agvStatus') === 'emergency_stop') {
        return 'stop-circle-o';
      } else if (this.get('agvStatus') === 'stop') {
        return 'stop-circle-o';
      } else if (this.get('agvStatus') === 'precise_stop') {
        return 'stop-circle-o';
      } else if (this.get('agvStatus') === 'rotation') {
        return 'refresh';
      } else if (this.get('agvStatus') === 'manual_control') {
        return 'arrows';
      } else if (this.get('agvStatus') === 'no_status') {
        return 'question';
      }
    }),
    chargeIcon: Ember.computed('agvStatus', function () {
      if (this.get('agvStatus') === 'charging') {
        var x = this.get('scale') * 16 + this.get('point.x') * (this.get('scale') * 52);
        var y = this.get('scale') * 64 + this.get('point.y') * (this.get('scale') * 52);
        return '<rect x="' + (x - this.get('scale') * 12) + '" y="' + (y + this.get('scale') * 26) + '" width="6" height="3" style="fill:rgb(0,255,0);stroke-width:1;stroke:rgb(0,0,0)"></rect><rect x="' + (x - this.get('scale') * 14) + '" y="' + (y + this.get('scale') * 28) + '" width="1" height="1" style="fill:rgb(0,0,0);stroke-width:1;stroke:rgb(0,0,0)"></rect>  ';
      }
    }),
    pauseStyle: Ember.computed('xx', 'yy', 'pausedAt', function () {
      return 'left: ' + (this.get('xx') + this.get('scale') * 2) + 'px; top: ' + (this.get('yy') + this.get('scale') * 2) + 'px; position: absolute; height: ' + this.get('scale') * 10 + 'px;';
    }),
    batteryX: Ember.computed('battery', 'point.x', 'point.y', function () {
      return this.get('xx') + this.get('scale') * 15;
    }),
    batteryY: Ember.computed('battery', 'point.x', 'point.y', function () {
      return this.get('yy') + this.get('scale') * 60;
    }),
    lcx: Ember.computed('angle', 'point.x', 'point.y', function () {
      var x = this.get('scale') * 16;
      return x - this.get('scale') * 4 + this.get('point.x') * (this.get('scale') * 52);
    }),
    lcy: Ember.computed('angle', 'point.x', 'point.y', function () {
      var y = this.get('scale') * 64;
      return y + this.get('scale') * 4 - this.get('scale') * 28 + this.get('point.y') * (this.get('scale') * 52);
    }),
    spdy: Ember.computed('angle', 'point.x', 'point.y', function () {
      var y = this.get('scale') * 64;
      return y + this.get('scale') * 4 + this.get('scale') * 32 + this.get('point.y') * (this.get('scale') * 52);
    }),
    lidarX: Ember.computed('angle', 'point.x', 'point.y', function () {
      var x = this.get('scale') * 16;
      if (this.get('angle') === 0 || this.get('angle') === 360) {
        return x + this.get('scale') * 4 + this.get('scale') * 32 + this.get('point.x') * (this.get('scale') * 52);
      } else if (this.get('angle') === 180) {
        return x + this.get('scale') * 4 - this.get('scale') * 32 + this.get('point.x') * (this.get('scale') * 52);
      } else {
        return x + this.get('scale') * 4 + this.get('point.x') * (this.get('scale') * 52);
      }
    }),
    lidarY: Ember.computed('angle', 'point.x', 'point.y', function () {
      var y = this.get('scale') * 64;
      if (this.get('angle') === 270) {
        return y + this.get('scale') * 4 + this.get('scale') * 32 + this.get('point.y') * (this.get('scale') * 52);
      } else if (this.get('angle') === 90) {
        return y + this.get('scale') * 4 - this.get('scale') * 32 + this.get('point.y') * (this.get('scale') * 52);
      } else {
        return y + this.get('scale') * 4 + this.get('point.y') * (this.get('scale') * 52);
      }
    }),
    lidarRadius: Ember.computed('scale', function () {
      return this.get('scale') * 6;
    }),
    batteryFont: Ember.computed('scale', function () {
      return this.get('scale') * 0.4;
    }),
    idFont: Ember.computed('scale', function () {
      return this.get('scale') * 1.6;
    }),
    codeFont: Ember.computed('scale', function () {
      return this.get('scale') * 0.8;
    }),

    showPlate: Ember.computed('liftState', 'centeringAt', function () {
      if (this.get('liftState') === 'highest') {
        return '#000000';
      } else if (this.get('liftState') === 'rise') {
        return '#444444';
      } else if (this.get('liftState') === 'fall') {
        return '#888888';
      } else if (this.get('centeringAt')) {
        return 'orange';
      } else if (this.get('liftState') === 'lowest') {}
    }),
    fillColor: Ember.computed('sidelined', 'updatedAt', 'offlineAt', 'heartbeatAt', 'agvStatus', 'task', 'liftState', 'battery', 'angle', function () {
      // console.log('Fill Color:');
      // console.log(this.get('offlineAt'));
      // console.log(this.get('heartbeatAt'));
      // console.log(this.get('offline'));
      if (this.get('powerOffAt')) {
        return 'red';
      } else if (this.get('offline')) {
        return 'orangered';
      } else if (this.get('sidelined')) {
        return 'darkorange';
      } else {
        return 'orange';
      }
    }),
    style: Ember.computed('fillColor', 'heartbeatAt', 'offlineAt', 'active', 'route.id', function () {
      if (this.get('route.id')) {
        return 'fill:' + this.get('fillColor') + ';stroke:' + this.get('color') + ';stroke-width:3;';
      } else if (this.get('active')) {
        return 'fill:' + this.get('fillColor') + ';stroke:red;stroke-width:1;';
      } else {
        return 'fill:' + this.get('fillColor') + ';stroke:black;stroke-width:1;';
      }
    }),
    x: Ember.computed('id', 'zone.id', 'point.x', function () {
      if (this.get('zone.id') < 6) {
        var x = this.get('scale') * 16;
        return x + this.get('scale') * 4 + this.get('point.x') * this.get('px');
      }
    }),
    y: Ember.computed('id', 'zone.id', 'point.y', function () {
      if (this.get('zone.id') < 6) {
        var y = this.get('scale') * 64;
        return y + this.get('scale') * 4 + this.get('point.y') * this.get('px');
      }
    }),
    textX: Ember.computed('xx', 'id', 'zone.id', 'point.x', function () {
      if (1 * this.get('id') < 10) {
        return this.get('xx') + 40 * this.get('scale');
      } else {
        return this.get('xx') + 36 * this.get('scale');
      }
    }),
    textY: Ember.computed('yy', 'id', 'zone.id', 'point.y', function () {
      return this.get('yy') + this.get('scale') * 58;
    }),
    codeX: Ember.computed('id', 'zone.id', 'point.x', function () {
      if (this.get('zone.id') < 6) {
        var x = this.get('scale') * 16;
        return x + this.get('scale') * 32 + this.get('point.x') * this.get('px');
      }
    }),
    codeY: Ember.computed('id', 'zone.id', 'point.y', function () {
      if (this.get('zone.id') < 6) {
        var y = this.get('scale') * 64;
        return y - this.get('scale') * 26 + this.get('point.y') * this.get('px');
      }
    })

  });
});