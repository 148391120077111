define('loftus/models/quote', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    startedAt: _emberData.default.attr('date'),
    terms: _emberData.default.attr('string'),
    poNumber: _emberData.default.attr('string'),
    enteredBy: _emberData.default.attr('string'),
    origin: _emberData.default.attr('string'),
    replyBy: _emberData.default.attr('string'),
    total: _emberData.default.attr('number'),
    contact: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    shipVia: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    labeledPricing: _emberData.default.attr('boolean'),
    specialOrder: _emberData.default.attr('boolean'),
    coldCall: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    user: _emberData.default.belongsTo('user'),
    shippingService: _emberData.default.belongsTo('shipping-service'),
    event: _emberData.default.belongsTo('event'),
    quoteItems: _emberData.default.hasMany('quote-item'),
    ownedItems: Ember.computed.alias('quoteItems'),
    orders: _emberData.default.hasMany('orders'),
    orderIds: _emberData.default.attr({ readOnly: true }),
    customer: _emberData.default.belongsTo('customer', { async: true }),
    salesRep: _emberData.default.belongsTo('sales-rep'),
    promo: _emberData.default.belongsTo('promo'),
    shipTo: _emberData.default.belongsTo('ship-to'),
    validatedAt: _emberData.default.attr('date', { readOnly: true }),
    prop65Labeling: _emberData.default.attr('string'),
    validatePoNumber: (0, _emberApiActions.collectionAction)({ path: 'validate_po_number', type: 'get' }),
    invoiceSelected: (0, _emberApiActions.collectionAction)({ path: 'invoice_selected', type: 'put' }),
    orderReturns: _emberData.default.hasMany('order-returns', { inverse: 'quote' }),
    rma: Ember.computed.alias('orderReturns.firstObject'),
    createOrder: (0, _emberApiActions.memberAction)({ path: 'create_order', type: 'post' }),
    prop65Needed: Ember.computed('customer.prop65', 'quoteItems.@each.prop65', function () {
      var option = false;
      this.get('quoteItems').forEach(function (qi) {
        if (qi.get('prop65')) {
          option = true;
        }
      });
      return option && this.get('customer.prop65');
    }),
    prop65Satisfied: Ember.computed('customer.prop65', 'prop65Labeling', 'quoteItems.@each.prop65', function () {
      if (!this.get('customer.prop65')) {
        return true;
      }
      if (this.get('prop65Labeling')) {
        return true;
      }
      var option = true;
      this.get('quoteItems').forEach(function (qi) {
        if (qi.get('prop65')) {
          option = false;
        }
      });
      return option;
    }),
    sortOrder: ['position'],
    sortedQuoteItems: Ember.computed.sort('ownedItems', 'sortOrder'),
    inflateOption: Ember.computed('customer.alwaysInflate', 'ownedItems.@each.canInflate', function () {
      return !this.get('customer.alwaysInflate') && this.get('ownedItems').filterBy('canInflate').length > 0;
    }),
    invoiceDisabled: Ember.computed('orders', function () {
      return this.get('orders.length') > 0;
    }),
    creditInfo: Ember.computed('customer.creditLimit', 'customer.creditUsed', 'subTotal', function () {
      if (this.get('customer.creditLimit') > 0) {
        if (this.get('customer.creditUsed') * 1 > this.get('customer.creditLimit')) {
          return 'Customer is over credit limit.';
        } else if (this.get('subTotal') + this.get('customer.creditUsed') * 1 > this.get('customer.creditLimit')) {
          return 'This order puts customer over credit limit.';
        }
      }
    }),
    poNumberObserver: Ember.observer('poNumber', 'origin', 'customer.id', function () {
      var _this = this;
      if ((this.get('poNumber') && this.get('poNumber').length > 0 || this.get('origin') && this.get('origin').length > 0) && this.get('customer') && this.get('customer.id')) {
        this.validatePoNumber({ order: { po_number: this.get('poNumber'), customer_id: this.get('customer.id'), origin: this.get('origin'), created_at: this.get('createdAt') } }).then(function (response) {
          _this.set('poDuplicated', response.poDuplicated);
          _this.set('duplicateId', response.orderId);
          if (response.poDuplicated) {
            _this.set('duplicateClass', 'duplicate-po');
          } else {
            _this.set('duplicateClass', null);
          }

          _this.set('special', response.special);
          _this.set('customer.recent', response.recent);
        });
      } else {
        this.set('poDuplicated', false);
        this.set('duplicateClass', null);
      }
    }),
    ecSalesTax: Ember.computed('ecSubTotal', 'customer.taxable', function () {
      if (!this.get('open')) {
        return this.get('salesTax');
      }
      if (this.get('customer.taxable')) {
        return Math.round(this.get('ecSubTotal') * 7.75) / 100;
      } else {
        return 0;
      }
    }),
    willCall: Ember.computed('shippingService.id', function () {
      return this.get('shippingService.name') == 'Will Call';
    }),
    hazmatFee: Ember.computed('ownedItems.@each.quantity', 'ownedItems.@each.quantityBackordered', 'ownedItems.@each.hazmat', 'shippingService.id', function () {
      if (!this.get('willCall') && this.get('ownedItems').filterBy('hazmat', 'pressurized').length > 0) {
        var hazmat = { value: 0, cases: 0 };
        this.get('ownedItems').filterBy('hazmat', 'pressurized').forEach(function (oi) {
          var cs = oi.get('item.title').slice(0, 2);
          if (!hazmat[cs]) {
            hazmat[cs] = { qty: 0, value: 0, caseQty: oi.get('item.caseQuantity') };
          }
          hazmat[cs]['qty'] = hazmat[cs]['qty'] + oi.get('quantity');
          hazmat['value'] = hazmat['value'] + oi.get('extPrice');
          hazmat['cases'] = hazmat['cases'] + oi.get('quantity') / oi.get('item.caseQuantity');
        });

        if (hazmat['value'] < 750) {

          if (hazmat['12'] && hazmat['12']['qty'] % hazmat['12']['caseQty'] > 0) {}
          if (hazmat['18'] && hazmat['18']['qty'] % hazmat['18']['caseQty'] > 0) {}
          if (hazmat['24'] && hazmat['24']['qty'] % hazmat['24']['caseQty'] > 0) {}
          return Math.round(Math.ceil(hazmat.cases) * 100) / 100 * 30;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }),
    validations: Ember.computed('changed', 'prop65Labeling', 'unfulfillable', 'notifyBackorders', 'fillBackorders', 'cancelAt', 'origin', 'specialOrder', 'event.id', 'ownedItems.@each.eventId', 'ownedItems.@each.quantity', 'ownedItems.@each.quantityBackordered', 'ownedItems.@each.hazmat', 'event.shippingStartsOn', 'payments.length', 'validatedAt', 'customer.disablePurchasing', 'paymentToken.id', 'status', 'enteredBy', 'approvedAt', 'approvedBy', 'paymentToken.id', 'terms', 'shippingService.id', 'city', 'state', 'country', 'shippingService.carrier', 'canShipBy', 'event.shippingStartsOn', function () {

      var validations = [];

      if (this.get('customer.disablePurchasing')) {
        validations.pushObject('Customer cannot purchase');
      }

      if (!this.get('terms')) {
        validations.pushObject('Terms must be selected.');
      }

      if (this.get('terms') && (this.get('terms').indexOf('CASH') > -1 || this.get('terms').indexOf('CREDIT CARD') > -1) && this.get('terms') !== 'CASH' && !this.get('paymentToken.id')) {
        validations.pushObject('Credit Card must be selected');
      }

      if (!this.get('shippingService.id')) {
        validations.pushObject('Must select a Shipping Service');
      }

      if (!this.get('prop65Satisfied')) {
        validations.pushObject('Select Prop 65 Handling');
      }

      if (!this.get('willCall') && this.get('ownedItems').filterBy('hazmat', 'pressurized').length > 0) {
        var hazmat = { value: 0, cases: 0 };
        this.get('ownedItems').filterBy('hazmat', 'pressurized').forEach(function (oi) {
          var cs = oi.get('item.title').slice(0, 2);
          if (!hazmat[cs]) {
            hazmat[cs] = { qty: 0, value: 0, caseQty: oi.get('item.caseQuantity') };
          }
          hazmat[cs]['qty'] = hazmat[cs]['qty'] + oi.get('quantity');
          hazmat['value'] = hazmat['value'] + oi.get('extPrice');
          hazmat['cases'] = hazmat['cases'] + oi.get('quantity') / oi.get('item.caseQuantity');
        });

        if (hazmat['value'] < 750) {

          if (hazmat['12'] && hazmat['12']['qty'] % hazmat['12']['caseQty'] > 0) {
            validations.pushObject('Must add ' + (hazmat['12']['caseQty'] - hazmat['12']['qty'] % hazmat['12']['caseQty']) + ' 12" cannons');
          }
          if (hazmat['18'] && hazmat['18']['qty'] % hazmat['18']['caseQty'] > 0) {
            validations.pushObject('Must add ' + (hazmat['18']['caseQty'] - hazmat['18']['qty'] % hazmat['18']['caseQty']) + ' 18" cannons');
          }
          if (hazmat['24'] && hazmat['24']['qty'] % hazmat['24']['caseQty'] > 0) {
            validations.pushObject('Must add ' + (hazmat['24']['caseQty'] - hazmat['24']['qty'] % hazmat['24']['caseQty']) + ' 24" cannons');
          }
        }
      }

      if (this.get('customer.preauthRequiredAt') && this.get('terms') && this.get('terms') === 'CREDIT CARD' && this.get('payments.length') === 0 && !this.get('canShipBy') && !this.get('event.shippingStartsOn') && !this.get('specialOrder')) {
        validations.pushObject('Pre-Auth Required');
      }

      if (this.get('unfulfillable')) {
        if (!this.get('event') && !this.get('notifyBackorders') && !this.get('fillBackorders')) {
          validations.pushObject('Select Fill or Notify Backorders');
        } else if (this.get('fillBackorders') && !this.get('cancelAt')) {
          validations.pushObject('Select Cancel Date for Backorders');
        }

        if (this.get('event') && this.get('event.preorderEndsAt') < new Date()) {
          validations.pushObject('Preorder ended. Can\'t oversell.');
        }
      }

      // if (this.get('city') !== this.get('city').toUpperCase()) {
      //   validations.pushObject('Shipment City must be capitalized');
      // }

      // if (this.get('state') && this.get('state') !== this.get('state').toUpperCase()) {
      //   validations.pushObject('Shipment State must be capitalized');
      // }

      if (this.get('country') && this.get('country').length !== 2) {
        validations.pushObject('Shipment Country must be 2 characters');
      }

      if (this.get('country') && this.get('country') === 'US' && this.get('state').length !== 2) {
        validations.pushObject('Shipment State must be 2 characters');
      }

      if (this.get('country') && this.get('country') !== this.get('country').toUpperCase()) {
        validations.pushObject('Shipment Country must be capitalized');
      }

      if (!this.get('validatedAt')) {
        validations.pushObject('Shipment Address must be validated');
      }

      if (this.get('terms') === 'PROFORMA') {
        validations.pushObject('PROFORMA can\'t be approved');
      }

      if (this.get('changed')) {
        validations.pushObject('Save Quote to Commit to Order');
      }

      if (validations.length > 0) {
        return validations;
      }
    }),
    hasDuplicateItems: Ember.computed('ownedItems.@each.duplicated', function () {
      return this.get('ownedItems').filterBy('duplicated', true).length > 0;
    }),
    freightDiscountShortage: Ember.computed('customer.discountFreightPercent', 'customer.noFreightAmount', 'subTotal', function () {
      if (this.get('customer.discountFreightPercent') > 0 && this.get('subTotal') > 0) {
        return this.get('customer.noFreightAmount') - this.get('subTotal');
      }
    }),
    changed: Ember.computed('hasDirtyAttributes', 'ownedItems.@each.hasDirtyAttributes', 'currentState', function () {
      return this.get('hasDirtyAttributes') || this.get('ownedItems').filterBy('hasDirtyAttributes', true).length > 0 || this.get('promo.hasDirtyAttributes');
    }),
    saveButtonClass: Ember.computed('saveStarted', 'commitStarted', function () {
      if (this.get('saveStarted') || this.get('commitStarted') || this.get('hasDuplicateItems')) {
        return 'disabled';
      }
    }),
    nonCases: Ember.computed('quoteItems.@each.quantity', function () {
      var nonCases = 0;
      this.get('quoteItems').forEach(function (qi) {
        if (!qi.get('item.caseQuantity') || qi.get('item.caseQuantity') * 1 < 1 || qi.get('quantity') % qi.get('item.caseQuantity') > 0) {
          nonCases += 1;
        }
      });
      console.log(nonCases);
      if (nonCases > 0) {
        return true;
      }
    }),
    amazonError: Ember.computed('ecSubTotal', 'shippingService.id', 'nonCases', function () {
      if (this.get('shippingService.id') * 1 === 40 && (this.get('ecSubTotal') < 1000 || this.get('nonCases'))) {
        return true;
      }
    }),
    saveButtonText: Ember.computed('saveStarted', 'commitStarted', 'changed', 'shippingService.id', 'ecSubTotal', function () {
      if (this.get('hasDuplicateItems')) {
        return 'Remove duplicates';
      } else if (this.get('amazonError')) {
        if (this.get('ecSubTotal') < 1000) {
          return 'Over $1000 Required';
        } else {
          return 'Only Cases Required';
        }
      } else if (this.get('commitStarted')) {
        return 'Committing Quote... ';
      } else if (this.get('saveStarted')) {
        return 'Saving Order... ';
      } else if (this.get('changed')) {
        return 'Save Quote';
      } else {
        return 'Commit to Order ';
      }
    }),

    baseSubTotal: Ember.computed('ownedItems.@each.basePrice', 'ownedItems.@each.quantity', function () {
      return Math.round(this.get('ownedItems').reduce(function (acc, value) {
        acc += value.get('basePrice') * value.get('quantity');
        return acc;
      }, 0) * 100) / 100;
    }),
    promoSubTotal: Ember.computed('promo.id', 'promo.promoItems.@each.discountCategory', 'ownedItems.@each.basePrice', 'ownedItems.@each.quantity', function () {
      if (!this.get('promo.id')) {
        return 0;
      }
      return Math.round(this.get('ownedItems').reduce(function (acc, value) {

        if (value.get('promoEligible')) {
          acc += value.get('basePrice') * value.get('quantity');
        }
        return acc;
      }, 0) * 100) / 100;
    }),
    ecAvailable: Ember.computed('ownedItems.@each.availableExt', function () {
      return Math.round(this.get('ownedItems').reduce(function (acc, value) {
        acc += value.get('availableExt');
        return acc;
      }, 0) * 100) / 100;
    }),
    ecSmallRequired: Ember.computed('ownedItems.@each.availableExt', function () {
      return Math.round((100 - this.get('ecAvailable')) * 100) / 100;
    }),
    ecSubTotal: Ember.computed('ownedItems.@each.extPrice', function () {
      return Math.round(this.get('ownedItems').reduce(function (acc, value) {
        acc += value.get('extPrice');
        return acc;
      }, 0) * 100) / 100;
    }),
    ecTotal: Ember.computed('ecSubTotal', 'ecFreightAllowance', 'ecSalesTax', 'ecCredit', function () {
      var total = Math.round((this.get('ecSubTotal') + (this.get('ecFreightAllowance') || 0) + (this.get('ecSalesTax') || 0) + (this.get('ecCredit') || 0)) * 100) / 100;
      this.set('total', total);
      return total;
    }),
    creditDisplay: Ember.computed('ecCredit', function () {
      return this.get('ecCredit') * -1;
    }),
    ecCredit: Ember.computed('ecSubTotal', 'customer.discountPercent', function () {
      if (this.get('closed')) {
        return 0;
      } else if (this.get('ecSubTotal') <= 0) {
        return 0;
      } else if (this.get('customer.chain.discountPercent') && this.get('customer.chain.discountPercent') > 0) {
        return Math.round(this.get('customer.chain.discountPercent') / 100 * this.get('ecSubTotal') * -100) / 100;
      } else if (this.get('customer.discountPercent') && this.get('freightAllowance') === 0) {
        return Math.round(this.get('customer.discountPercent') / 100 * this.get('ecSubTotal') * -100) / 100;
      } else {
        return 0;
      }
    }),
    ecCreditDisplay: Ember.computed('ecCredit', function () {
      return this.get('ecCredit') * -1;
    })
  });
});