define('loftus/models/role', ['exports', 'ember-data', 'loftus/models/authorizes'], function (exports, _emberData, _authorizes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authorizes.default.extend({
    name: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    assignedPermissions: _emberData.default.hasMany('assigned-permission'),
    permissions: _emberData.default.hasMany('permission')
  });
});