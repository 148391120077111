define('loftus/controllers/printers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    formatOptions: ['epl', 'zpl', 'ps'],
    connectionOptions: ['mqtt', 'ssh'],
    actions: {
      save: function save() {
        this.get('model').filterBy('hasDirtyAttributes').invoke('save');
      }
    }
  });
});