define('loftus/components/manage-backorders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    actions: {
      processBackorder: function processBackorder(oi) {
        oi.set('backorderProcessedAt', new Date());
        oi.set('backorderProcessedBy', this.get('currentUser.user'));
        oi.save();
      },
      destroyBackorder: function destroyBackorder(oi) {
        oi.destroyRecord();
      },
      removeBackorder: function removeBackorder(oi) {
        oi.set('backorderReorderedAt', new Date());
        oi.save();
      }
    }
  });
});