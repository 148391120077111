define('loftus/components/quantity-discounts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    actions: {
      addDiscount: function addDiscount() {

        var discount = this.get('item.store').createRecord('quantity-discount', {
          level: this.get('item.discounts.length') + 1
        });
        this.get('item.discounts').addObject(discount);
      },
      saveDiscount: function saveDiscount(qd) {
        qd.save();
      }
    }
  });
});