define('loftus/components/hyper-search', ['exports', 'ember-hypersearch/components/hyper-search'], function (exports, _hyperSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hyperSearch.default;
    }
  });
});