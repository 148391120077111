define('loftus/controllers/stations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    sortedStations: Ember.computed.sort('model', 'sortOrder'),
    sortOrder: ['name'],
    userOptions: Ember.computed(function () {
      return this.get('store').query('user', { scope: 'active' });
    }),
    actions: {
      save: function save(station) {
        station.save();
        station.get('users').invoke('save');
        this.get('store').peekAll('user').filterBy('hasDirtyAttributes').invoke('save');
      }
    }
  });
});