define('loftus/components/new-order-event', ['exports', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.default, {
    accessEvents: [{ name: 'View Order', events: ['Print Pick Ticket', 'Print Order', 'Verify Total', 'Open Order Report', 'Review Order'] }, { name: 'Edit Order', events: ['Change Sales Rep', 'Add Payment Info', 'Edit Web Order', 'Correct Order', 'Add Items', 'Delete Order', 'Charge Order', 'Fix Pricing'] }, { name: 'Order Support', events: ['Missing Items', 'Not Received', 'Tracking Info', 'Invoice Request', 'Return'] }],
    actions: {
      createOrderEvent: function createOrderEvent(event) {
        this.sendAction('action', event || this.get('accessEvent'));
      }
    },
    keyboardShortcuts: {
      'return': {
        action: 'createOrderEvent'
      }
    }
  });
});