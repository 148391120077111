define('loftus/controllers/user/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    actions: {
      save: function save() {
        this.get('model').save();
      }
    }
  });
});