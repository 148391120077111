define('loftus/models/quantity-discount', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    level: _emberData.default.attr('number'),
    unit: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    retail: _emberData.default.attr('number'),
    jobber: _emberData.default.attr('number'),
    retailExt: Ember.computed('retail', 'quantity', function () {
      return this.get('retail') * this.get('quantity');
    }),
    jobberExt: Ember.computed('jobber', 'quantity', function () {
      return this.get('jobber') * this.get('quantity');
    }),
    wholesale: _emberData.default.attr('number'),
    preferred: _emberData.default.attr('number'),
    premium: _emberData.default.attr('number'),
    wholesaleExt: Ember.computed('wholesale', 'quantity', function () {
      return this.get('wholesale') * this.get('quantity');
    }),
    preferredExt: Ember.computed('preferred', 'quantity', function () {
      return this.get('preferred') * this.get('quantity');
    }),
    premiumExt: Ember.computed('premium', 'quantity', function () {
      return this.get('premium') * this.get('quantity');
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item')
  });
});