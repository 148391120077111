define('loftus/components/manage-pages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      addPage: function addPage() {
        var _this = this;
        this.get('store').createRecord('page', { title: 'New Page' }).save().then(function (page) {
          _this.get('router').transitionTo('pages.edit', page);
        });
      }
    }
  });
});