define('loftus/models/pallet', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('number'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number'),
    status: _emberData.default.attr('string', { readOnly: true }),
    shippedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date'),
    packed: _emberData.default.attr('boolean', { readOnly: true }),
    measured: _emberData.default.attr('boolean', { readOnly: true }),
    estWeight: _emberData.default.attr('number', { readOnly: true }),
    ship: _emberData.default.attr('boolean'),
    customer: _emberData.default.belongsTo('customer'),
    shipment: _emberData.default.belongsTo('shipment'),
    freightShipment: _emberData.default.belongsTo('freight-shipment')
  });
});