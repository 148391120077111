define('loftus/models/customer', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    contact: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    cellPhone: _emberData.default.attr('string'),
    altPhone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    terms: _emberData.default.attr('string'),
    pcaNumber: _emberData.default.attr('string'),
    pfaNumber: _emberData.default.attr('string'),
    ncaNumber: _emberData.default.attr('string'),
    club: Ember.computed('pcaNumber', 'pfaNumber', 'ncaNumber', function () {
      return ('' + this.get('pcaNumber') + this.get('pfaNumber') + this.get('ncaNumber')).replace(/null/g, '').length > 2;
    }),
    balance: _emberData.default.attr('number'),
    enteredAt: _emberData.default.attr('date'),
    accountClosedOn: _emberData.default.attr('date'),
    amazonSeller: _emberData.default.attr('string'),
    limitSelection: _emberData.default.attr('boolean'),
    permittedIds: _emberData.default.attr({ readOnly: true }),
    details: _emberData.default.attr({ readOnly: true }),
    enteredBy: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    lastPaymentAmount: _emberData.default.attr('number'),
    lastPaymentOn: _emberData.default.attr('date'),
    creditLimit: _emberData.default.attr('number'),
    creditUsed: _emberData.default.attr({ readOnly: true }),
    salesRep: _emberData.default.belongsTo('sales-rep'),
    user: _emberData.default.belongsTo('user'),
    mtdSales: _emberData.default.attr('number'),
    ytdSales: _emberData.default.attr('number'),
    financeCharges: _emberData.default.attr('boolean'),
    printStatement: _emberData.default.attr('boolean'),
    mailInvoice: _emberData.default.attr('boolean'),
    emailInvoice: _emberData.default.attr('boolean'),
    invoiceEmail: _emberData.default.attr('string'),
    invoiceContact: _emberData.default.attr('string'),
    inflates: _emberData.default.attr('string'),
    district: _emberData.default.attr('string'),
    storeNumber: _emberData.default.attr('string'),
    prop65: _emberData.default.attr('boolean'),
    holdForSubstitutions: _emberData.default.attr('boolean'),
    prop65AcknowledgedAt: _emberData.default.attr('date'),
    preauthRequiredAt: _emberData.default.attr('date'),
    customerType: _emberData.default.attr('string'),
    discountType: _emberData.default.attr('string'),
    discountPercent: _emberData.default.attr('number'),
    noFreightAmount: _emberData.default.attr('number'),
    discountFreightAmount: _emberData.default.attr('number'),
    discountFreightPercent: _emberData.default.attr('number'),
    loftusDiscount: _emberData.default.attr('number'),
    taxable: _emberData.default.attr('boolean'),
    taxExemptNumber: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    commission: _emberData.default.attr('number'),
    actionAt: _emberData.default.attr('date'),
    active: _emberData.default.attr('boolean'),
    closed: _emberData.default.attr('boolean'),
    flashMessage: _emberData.default.attr('string'),
    commercialAddress: _emberData.default.attr('boolean'),
    fillBackorders: _emberData.default.attr('boolean'),
    ivLevel: _emberData.default.attr('number'),
    comments: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    upsShipperNumber: _emberData.default.attr('string'), fedexShipperNumber: _emberData.default.attr('string'),
    customer: _emberData.default.belongsTo('customer'),
    customers: _emberData.default.hasMany('customer', { inverse: 'customer' }),
    customerMailers: _emberData.default.hasMany('customer-mailer', { inverse: 'customer' }),
    mailers: _emberData.default.hasMany('mailer'),
    shippingService: _emberData.default.belongsTo('shipping-service'),
    customerVendorAgreements: _emberData.default.hasMany('customer-vendor-agreement'),
    contacts: _emberData.default.hasMany('contact', { inverse: 'customer' }),
    calls: _emberData.default.hasMany('calls', { inverse: 'customer' }),
    notes: _emberData.default.hasMany('notes', { inverse: 'customer' }),
    returns: _emberData.default.hasMany('order-returns', { inverse: 'customer' }),
    customerItems: _emberData.default.hasMany('customer-item', { inverse: 'customer' }),
    imprints: _emberData.default.hasMany('customer-imprints', { inverse: 'customer' }),
    itemAlerts: _emberData.default.hasMany('item-alert'),
    paymentTokens: _emberData.default.hasMany('payment-token', { inverse: 'customer' }),
    allTokens: Ember.computed('paymentTokens.@each.id', 'chain.paymentTokens.@each.id', function () {
      console.log('All Tokens');
      console.log(this.get('chain.id'));

      if (!this.get('chain.id')) {
        return this.get('paymentTokens');
      } else {
        var tokens = [];
        this.get('paymentTokens').forEach(function (token) {
          console.log(token);
          console.log(tokens.indexOf(token));
          if (tokens.indexOf(token) === -1) {
            tokens.pushObject(token);
          }
        });
        this.get('chain.paymentTokens').forEach(function (token) {
          console.log(token);
          console.log(tokens.indexOf(token));
          if (tokens.indexOf(token) === -1) {
            tokens.pushObject(token);
          }
        });
        console.log(tokens);
        console.log(tokens.length);
        return tokens;
      }
    }),
    prop65Restricted: Ember.computed('prop65', 'prop65AcknowledgedAt', function () {
      return this.get('prop65') && !this.get('prop65AcknowledgedAt');
    }),
    prop65Permitted: Ember.computed('prop65', 'prop65AcknowledgedAt', function () {
      return !this.get('prop65') || this.get('prop65AcknowledgedAt');
    }),
    categoryDiscounts: _emberData.default.hasMany('customer-category-discount'),
    shipTos: _emberData.default.hasMany('ship-to', { inverse: 'customer' }),
    defaultShipToId: _emberData.default.attr('number'),
    alwaysInflate: Ember.computed('inflates', function () {
      return this.get('inflates') === 'inflates_available';
    }),
    requireInners: _emberData.default.attr('boolean'),
    orders: _emberData.default.hasMany('order', { async: true }),
    quotes: _emberData.default.hasMany('quote', { async: true }),
    webUsers: _emberData.default.hasMany('web-user'),
    carts: _emberData.default.hasMany('cart'),
    documents: _emberData.default.hasMany('document'),
    allowRestrictedServices: _emberData.default.attr('boolean'),
    payments: _emberData.default.hasMany('order-payment', { async: true }),
    billedOrders: _emberData.default.attr({ readOnly: true }),
    accounts: _emberData.default.attr({ readOnly: true }),
    registerToken: (0, _emberApiActions.memberAction)({ path: 'register_token', type: 'put' }),
    unregisterToken: (0, _emberApiActions.memberAction)({ path: 'unregister_token', type: 'put' }),
    getChartData: (0, _emberApiActions.memberAction)({ path: 'chart_data', type: 'get' }),
    getOrderHistory: (0, _emberApiActions.memberAction)({ path: 'order_history', type: 'get' }),
    getOrderedItems: (0, _emberApiActions.memberAction)({ path: 'ordered_items', type: 'get' }),
    getBackorders: (0, _emberApiActions.memberAction)({ path: 'backorders', type: 'get' }),
    getItemHistory: (0, _emberApiActions.memberAction)({ path: 'item_history', type: 'get' }),
    getBilledOrders: (0, _emberApiActions.memberAction)({ path: 'billed_orders', type: 'get' }),
    emailProp65: (0, _emberApiActions.memberAction)({ path: 'email_prop_65', type: 'put' }),
    emailPriceFile: (0, _emberApiActions.memberAction)({ path: 'email_price_file', type: 'put' }),
    logEvent: (0, _emberApiActions.memberAction)({ path: 'log_event', type: 'put' }),
    specialOrderItems: _emberData.default.hasMany('special-order-item'),
    backorderedItems: Ember.computed('orders.@each.id', function () {
      var bos = [];
      this.get('orders').forEach(function (order) {
        order.get('orderItems').filter(function (i) {
          return i.get('quantityBackordered') > 0;
        }).forEach(function (i) {
          bos.pushObject(i);
        });
      });
      console.log(bos);
      if (bos.length > 0) {
        return bos;
      }
    }),
    chain: _emberData.default.belongsTo('chain'),
    maxDaysPastDue: _emberData.default.attr('number'),
    disablePurchasing: _emberData.default.attr('boolean'),
    openTransactions: _emberData.default.attr(),
    modelName: Ember.computed(function () {
      return this.get('constructor.modelName');
    }),
    pastDueNotice: Ember.computed('maxDayPastDue', 'disablePurchasing', 'openTransactions', function () {
      if (this.get('disablePurchasing')) {
        return 'Purchasing Disabled';
      }
      if (this.get('maxDaysPastDue') > 0) {
        var max = this.get('maxDaysPastDue');
        var warnings = this.get('openTransactions').filter(function (item) {
          return item[0] > max;
        }).reduce(function (acc, val) {
          acc.push(val[2] + ' is ' + val[0] + ' days past due.');
          return acc;
        }, []);
        if (warnings.length > 0) {
          return warnings.join("\n");
        }
      }
    }),
    grocer: Ember.computed('district', function () {
      return this.get('category') && this.get('category').indexOf('Groc') > -1;
    }),
    autoComplete: (0, _emberApiActions.collectionAction)({ path: 'ac', type: 'get' }),
    chargeOrders: (0, _emberApiActions.memberAction)({ path: 'charge_orders', type: 'put' }),
    changed: Ember.computed('hasDirtyAttributes', 'imprints.@each.hasDirtyAttributes', 'shipTos.@each.hasDirtyAttributes', 'customerItems.@each.hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') || this.get('imprints').filterBy('hasDirtyAttributes', true).length > 0 || this.get('shipTos').filterBy('hasDirtyAttributes', true).length > 0 || this.get('customerItems').filterBy('hasDirtyAttributes', true).length > 0;
    }),
    emailReturnLabelData: Ember.computed('contact', 'email', function () {
      return {
        name: this.get('contact'),
        email: this.get('email'),
        subject: 'Loftus International Return Label',
        body: 'Your return label is attached.'
      };
    }),
    hasShippingAccount: Ember.computed('fedexShipperNumber', 'upsShipperNumber', function () {
      return this.get('fedexShipperNumber.length') > 0 || this.get('upsShipperNumber.length') > 0;
    }),
    saveButtonText: Ember.computed('changed', function () {
      if (this.get('changed')) {
        return 'Save Customer';
      } else {
        return 'Customer Saved';
      }
    })

  });
});