define('loftus/components/core-values', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        messages: [{ type: 'Core Value', value: 'Hard Work', description: 'We take pride in hard work and the satisfaction of a job well-done.',
            quotes: [{ value: 'Giving everything your 100% effort.  Going the extra mile and go out of your way to do everything you can.', author: 'Charity' }]
        }, { type: 'Core Value', value: '100% Here', description: 'We are focused and committed to getting our job done right.',
            quotes: [{ value: "When you come in to work you give it everything you can and get everything done before you leave.", author: 'Anna Maria' }]
        }, { type: 'Core Value', value: 'Positive Attitude', description: 'We make a positive impact through our interactions.',
            quotes: [{ value: 'Try to keep each other positive throughout the day.  Bring good vibes no matter what is going on in our lives.  GOOD VIBES ONLY!', author: 'Donna' }]
        }, { type: 'Core Value', value: 'Always Improving', description: 'We consistently strive to be better in all we do.',
            quotes: [{ value: "Going beyond your job, increase your own rythm.  Learn how others do the same job and adapt.", author: 'Jessica' }]
        }, { type: 'Core Value', value: 'Tribe Matters', description: 'We enthusiastically support each other in achieving what we can\'t do alone.',
            quotes: [{ value: "If someone is having a bad day, check in and let them know you're there if they need you.", author: 'Jonathan' }]
        }, { type: 'Purpose and Passion', value: 'We strive to create partnerships and provide products that bring joy to life' }, { type: 'Niche', value: 'Preferred purveyors of fun' }, { type: 'Our Target', value: 'We elevate the standards of our industry in', bullets: ['Warehousing', 'Marketing', 'Sales', 'Customer Service', 'Product Innovation'] }],
        message: Ember.computed(function () {
            var max = this.get('messages.length');
            var rand = Math.floor(Math.random() * max);
            return this.get('messages')[rand];
        }),
        quote: Ember.computed('message', function () {
            if (this.get('message.quotes')) {
                var max = this.get('message.quotes.length');
                var rand = Math.floor(Math.random() * max);
                return this.get('message.quotes')[rand];
            }
        })

    });
});