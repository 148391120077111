define('loftus/components/list-notes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      newNote: function newNote() {
        var note = this.get('store').createRecord('note', {
          user: this.get('currentUser.user'),
          customer: this.get('customer'),
          updatedAt: new Date()
        });

        this.set('modalNote', note);
      },
      clearModalNote: function clearModalNote() {
        this.set('modalNote', null);
      }

    }

  });
});