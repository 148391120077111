define('loftus/models/box-content', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    box: _emberData.default.belongsTo('box'),
    boxId: _emberData.default.attr('number'),
    user: _emberData.default.belongsTo('user'),
    pickedItem: _emberData.default.belongsTo('picked-item'),
    pickedItemId: _emberData.default.attr('number'),
    orderItemId: _emberData.default.attr('number'),
    boxTag: _emberData.default.attr('string'),
    timeElapsed: _emberData.default.attr('number'),
    canDelete: Ember.computed('quantity', function () {
      return this.get('quantity') * 1 === 0;
    }),
    quantitySaved: Ember.computed('quantity', 'hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') ? 0 : this.get('quantity');
    }),
    unscannable: _emberData.default.attr('boolean')

  });
});