define('loftus/models/purchase-order', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    shipTo: _emberData.default.attr('string'),
    subTotal: _emberData.default.attr('number'),
    freight: _emberData.default.attr('number'),
    total: _emberData.default.attr('number'),
    duty: _emberData.default.attr('number'),
    itemCount: _emberData.default.attr('number'),
    terms: _emberData.default.attr('string'),
    shipVia: _emberData.default.attr('string'),
    placedBy: _emberData.default.attr('string'),
    shipToType: _emberData.default.attr('string'),
    customerNumber: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    confirmationNumber: _emberData.default.attr('string'),
    confirmedAt: _emberData.default.attr('date'),
    dropShip: _emberData.default.attr('boolean'),
    requiredOn: _emberData.default.attr('date'),
    placedAt: _emberData.default.attr('date'),
    receivedAt: _emberData.default.attr('date'),
    deliveredAt: _emberData.default.attr('date'),
    itemKeys: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    vendor: _emberData.default.belongsTo('vendor'),
    customer: _emberData.default.belongsTo('customer'),
    order: _emberData.default.belongsTo('order'),
    purchaseOrderItems: _emberData.default.hasMany('purchase-order-item'),
    purchaseOrderApprovals: _emberData.default.hasMany('purchase-order-approval'),
    sortedPurchaseOrderItems: Ember.computed.sort('purchaseOrderItems', 'sortOrder'),
    documents: _emberData.default.hasMany('document'),
    getContacts: (0, _emberApiActions.collectionAction)({ path: 'contacts', type: 'get' }),
    pdf: (0, _emberApiActions.memberAction)({ path: 'pdf', type: 'get' }),
    excel: (0, _emberApiActions.memberAction)({ path: 'excel', type: 'get' }),
    casesPdf: (0, _emberApiActions.memberAction)({ path: 'case_pdf', type: 'get' }),
    innersPdf: (0, _emberApiActions.memberAction)({ path: 'inner_pdf', type: 'get' }),
    emailPdf: (0, _emberApiActions.memberAction)({ path: 'email_pdf', type: 'put' }),
    receive: (0, _emberApiActions.memberAction)({ path: 'receive', type: 'put' }),
    requestApproval: (0, _emberApiActions.memberAction)({ path: 'request_approval', type: 'put' }),
    approved: Ember.computed('purchaseOrderApprovals.@each.approvedAt', function () {
      return !!this.get('placedAt') || this.get('purchaseOrderApprovals').filterBy('approvedAt').length == 3;
    }),
    receivedEvents: Ember.computed('purchaseOrderItems.@each.receivedEvents', function () {
      return this.get('purchaseOrderItems').reduce(function (acc, val) {
        var events = [];
        val.get('receivedEvents').forEach(function (event) {
          events.pushObject(event);
        });
        return acc.concat(events);
      }, []);
    }),
    emailData: Ember.computed('number', function () {
      return { name: '',
        email: '',
        subject: 'Loftus International Purchase Order #' + this.get('number'),
        body: 'Purchase order is attached.' };
    }),
    poiKeypads: Ember.computed.filter('purchaseOrderItems.@each.keypadVisible', function (poi) {
      return poi.get('keypadVisible');
    }),
    keypadVisible: Ember.computed('poiKeypads', function () {
      return this.get('poiKeypads.length') > 0;
    }),
    remainingItems: Ember.computed.filter('purchaseOrderItems.@each.quantityRemaining', function (poi) {
      return poi.get('quantityRemaining') > 0;
    }),
    canContinue: Ember.computed('remainingItems', function () {
      return this.get('remainingItems').length === 0;
    }),
    sortOrder: ['position', 'noteSort', 'sku', 'uvId'],
    hasMultiples: Ember.computed('purchaseOrderItems.@each.quantityRemaining', function () {
      var multiples = this.get('purchaseOrderItems').filter(function (i) {
        return i.get('item.receivingMultiple') * 1 > 0; // || i.get('item.purchasingMultiple') > 0;
      });
      return multiples.length > 0;
    }),
    showRemaining: false,
    searchInput: '',
    changed: Ember.computed('hasDirtyAttributes', 'purchaseOrderItems.@each.hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') || this.get('purchaseOrderItems').filterBy('hasDirtyAttributes', true).length > 0;
    }),
    buttonText: Ember.computed('changed', function () {
      if (this.get('changed')) {
        return 'Save Purchase Order';
      } else {
        return 'Purchase Order Saved';
      }
    })
  });
});