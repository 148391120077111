define('loftus/components/leads-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    query: {},
    salesOptions: Ember.computed(function () {
      return this.get('store').query('user', { filter: { department: 'SALES' } });
    }),
    sourceOptions: ["ASD Trade Show", "Cold Call", "External Referral", "Halloween Trade Show", "Morphsuit", "Balloon Planet", "PFA", "Trade Show", "Web Download", "Web Registration", "Web Research"],
    industryOptions: ["BALLOONS", "HALLOWEEN", "MAGIC", "MARKETPLACE SELLER", "NOVELTY", "PARTY STORE"],
    actions: {
      contactSelect: function contactSelect(contact) {
        this.set('query.customer', null);
        this.set('query.contact', null);
        this.set('query.lead', null);
        this.set('query.' + contact.model, contact.id);
        this.set('contact', contact.display);
      },
      searchLeads: function searchLeads() {
        var query = {
          id: this.get('query.lead'),
          user_id: this.get('query.salesPerson.id'),
          source: this.get('query.source'),
          industry: this.get('query.industry'),
          startDate: this.get('query.startDate'),
          endDate: this.get('query.endDate'),
          description: this.get('query.description'),
          applicationReceived: this.get('query.applicationReceived')
        };

        this.set('leads', null);
        var leads = this.get('store').query('lead', { filter: query });
        this.set('leads', leads);
      }

    }

  });
});