define('loftus/controllers/purchase-order-issues', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      resolved: function resolved(poi) {
        poi.set('resolvedAt', new Date());
        poi.save();
      }
    }
  });
});