define('loftus/components/display-notification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      confirmRead: function confirmRead() {
        this.set('notification.readAt', new Date());
        this.get('notification').save();
      },
      close: function close() {
        this.set('notification.readAt', new Date());
      }
    }
  });
});