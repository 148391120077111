define('loftus/components/simple-chat', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cableService: Ember.inject.service('cable'),
    speech: Ember.inject.service(),
    messages: [],
    username: 'guest',
    body: 'message body',

    setupSubscription: Ember.on('init', function () {
      var _this2 = this;

      var _this = this;
      var consumer = this.get('cableService').createConsumer('wss://api.loftus.com/websocket');
      var subscription = consumer.subscriptions.create("MessagesChannel", {
        received: function received(data) {
          _this2.get('messages').pushObject({ username: data.username, body: data.body });
          _this.get('speech').speak(data.username + ' says: ' + data.body);
        }
      });

      this.set('subscription', subscription);
    }),

    actions: {
      sendMessage: function sendMessage() {
        this.get('subscription').send({ username: this.get('username'), body: this.get('body') });
      }
    }
  });
});