define('loftus/adapters/application', ['exports', 'ember-inflector', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberInflector, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: '/api/',
    authorizer: 'authorizer:custom',
    urlForQuery: function urlForQuery(query) {
      if (query.resource) {
        var resource = query.resource;
        delete query.resource;
        return this._super.apply(this, arguments) + '/' + resource;
      }

      return this._super.apply(this, arguments);
    },
    pathForType: function pathForType(type) {
      var decamelized = Ember.String.decamelize(type);
      var underscored = Ember.String.underscore(decamelized);
      return (0, _emberInflector.pluralize)(underscored);
    }
  });
});