define('loftus/models/assembly', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    partType: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    unit: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    partItem: _emberData.default.belongsTo('item'),
    multiple: Ember.computed('partItem.uom', 'unit', 'quantity', function () {
      if (this.get('unit') === this.get('partItem.uom') || this.get('quantity') === 0) {
        if (this.get('item.salesMultiple') * 1 > 1 && this.get('item.salesMultiple') === this.get('quantity')) {
          return 1;
        } else if (this.get('item.salesMultiple') * 1 > 1 && this.get('item.salesMultiple') > this.get('quantity')) {
          console.log(this.get('item.SalesMultiple') / this.get('quantity'));
          return this.get('item.SalesMultiple') / this.get('quantity');
        }
        return 1;
      } else if (this.get('unit') === 'EA' && this.get('partItem.uom') !== this.get('unit') && this.get('partItem.unitQuantity') > 1) {
        return this.get('partItem.unitQuantity') / this.get('quantity');
      } else {
        return 1;
      }
    }),
    cost: Ember.computed('quantity', 'multiple', 'partItem.averageCost', function () {
      if (this.get('quantity') > 0) {
        if (this.get('unit') === this.get('partItem.uom')) {
          return this.get('partItem.averageCost') * this.get('quantity');
        } else {
          return this.get('partItem.averageCost') / this.get('multiple');
        }
      }
      return 0;
    }) });
});