define('loftus/components/project-cubiscan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    speech: Ember.inject.service(),
    actions: {

      confirmClearBin: function confirmClearBin(bin) {
        this.set('bin', bin);
        this.toggleProperty('confirmClear');
      },
      toggleConfirmClear: function toggleConfirmClear() {
        this.toggleProperty('confirmClear');
      },
      clearBin: function clearBin() {
        if (this.get('bin')) {
          this.set('bin.item', null);
          this.get('bin').save();
          this.set('confirmClear', null);
        }
      },
      printLabel: function printLabel() {
        var _this = this;
        this.get('record.model').then(function (item) {
          item.printLabels({ format: 'case_measurements' }).then(function () {
            _this.set('record.printed', true);
            setTimeout(function () {
              _this.$('.scan').focus();
            }, 30);
          });
        });
      },
      submitScan: function submitScan() {
        if (this.get('scan') && this.get('scan').toUpperCase() === this.get('record.model.sku')) {
          this.set('record.completedAt', new Date());
          this.get('record').save();
        } else {
          this.get('speech').speak('No');
          this.set('scan', null);
        }
      },
      skipItem: function skipItem() {
        this.set('record.completedAt', new Date());
        this.get('record').save();
      }

    }
  });
});