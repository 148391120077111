define('loftus/components/edit-chain', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    pageOptions: Ember.computed(function () {
      return this.get('store').query('page', { filter: { depth: 0, chain_id: this.get('chain.id') }, scope: ['chain'] });
    }),
    actions: {
      save: function save() {
        this.get('chain').save();
      }
    }
  });
});