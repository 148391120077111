define('loftus/models/pod-pick', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    quantityPicked: _emberData.default.attr('number'),
    priority: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    shipment: _emberData.default.belongsTo('shipment'),
    orderItem: _emberData.default.belongsTo('order-item'),
    bin: _emberData.default.belongsTo('bin'),
    point: _emberData.default.belongsTo('point'),
    route: _emberData.default.belongsTo('route'),
    user: _emberData.default.belongsTo('user'),
    pickedAt: _emberData.default.attr('date'),
    station: _emberData.default.belongsTo('station'),
    leadShort: _emberData.default.attr('boolean'),
    uuid: _emberData.default.attr('string', { readOnly: true }),
    start: (0, _emberApiActions.collectionAction)({ path: 'start', type: 'put' }),
    smallCase: Ember.computed('quantityPicked', 'quantity', 'orderItem.item.caseVolume', function () {
      if (this.get('orderItem.item.caseVolume') < 1500) {
        return true;
      }
    }),
    splitCase: Ember.computed.alias('quantityPicked', 'quantity', function () {
      return this.get('quantityPicked' && this.get('quantityPicked') < this.get('quantity'));
    })
  });
});