define('loftus/helpers/foot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(values) {
      var column = values[0];
      var data = values[1];
      if (column.foot === 'count') {
        return data.length;
      } else if (column.foot === 'sum' && data && data.length > 0) {
        return data.reduce(function (acc, val) {
          return acc + val[column.name] * 1;
        }, 0);
      }
    }
  });
});