define('loftus/controllers/project/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    allUsers: Ember.computed(function () {
      return this.store.query('user', { scope: 'active' });
    }),
    actions: {
      submitProject: function submitProject(project) {
        project.save();
      }
    }
  });
});