define('loftus/components/app-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    search: '',
    actions: {
      doSearch: function doSearch() {
        var search = this.get('search');
      }
    }
  });
});