define('loftus/models/product-type-property', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    required: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    productType: _emberData.default.belongsTo('product-type'),
    property: _emberData.default.belongsTo('property')
  });
});