define('loftus/routes/orders/packed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').query('order', { q: 'filter', filter: { packed: true } });
    }
  });
});