define('loftus/controllers/role/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    allPermissions: Ember.computed(function () {
      return this.store.findAll('permission');
    }),
    actions: {
      update: function update() {
        this.get('model').save().then(function (response) {});
      }
    }
  });
});