define('loftus/models/collection', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    sortOrder: _emberData.default.attr('string'),
    templateSuffix: _emberData.default.attr('string'),
    bodyHtml: _emberData.default.attr('string'),
    handle: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    risque: _emberData.default.attr('boolean'),
    page: _emberData.default.belongsTo('page'),
    chain: _emberData.default.belongsTo('chain'),
    collectionItems: _emberData.default.hasMany('collection-item'),
    merge: (0, _emberApiActions.memberAction)({ path: 'merge', type: 'put' }),
    addSkus: (0, _emberApiActions.memberAction)({ path: 'add_skus', type: 'put' })
  });
});