define('loftus/models/log', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    bot: _emberData.default.attr('string'),
    route: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    entry: _emberData.default.attr({})
  });
});