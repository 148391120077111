define('loftus/models/picking-tote', ['exports', 'loftus/models/current', 'ember-data', 'ember-api-actions'], function (exports, _current, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _current.default.extend({
    sortingAt: _emberData.default.attr('date'),
    shippingAt: _emberData.default.attr('date'),
    filledAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    tote: _emberData.default.belongsTo('tote'),
    shipment: _emberData.default.belongsTo('shipment'),
    userId: _emberData.default.attr('number'),
    packer: _emberData.default.belongsTo('user'),
    pickedItems: _emberData.default.hasMany('picked-item'),
    pickingBins: _emberData.default.hasMany('picking-bin'),
    pickingBin: Ember.computed.alias('pickingBins.firstObject'),
    showRemaining: false,
    remainingItems: Ember.computed.filter('pickedItems.@each.quantityRemaining', function (pickedItem) {
      return pickedItem.get('quantityRemaining') > 0;
    }),
    completedItems: Ember.computed.filter('pickedItems.@each.quantityRemaining', function (pickedItem) {
      return pickedItem.get('quantityRemaining') === 0;
    }),
    isComplete: Ember.computed('remainingItems', function () {
      return this.get('remainingItems.length') === 0;
    }),
    progress: Ember.computed('pickedItems', 'remainingItems', function () {
      return this.get('pickedItems.length') - this.get('remainingItems.length') + 1;
    }),
    canContinue: Ember.computed('remainingItems', function () {
      var canContinue = this.get('remainingItems').length === 0;
      if (canContinue) {
        this.set('showRemaining', false);
      }
      return this.get('remainingItems').length === 0;
    }),
    canSearch: Ember.computed('keypadVisible', 'issueVisible', function () {
      return !this.get('keypadVisible') && !this.get('issueVisible');
    }),
    piKeypads: Ember.computed.filter('pickedItems.@each.keypadVisible', function (poi) {
      return poi.get('keypadVisible');
    }),
    keypadVisible: Ember.computed('piKeypads', function () {
      return this.get('piKeypads.length') > 0;
    }),
    piIssues: Ember.computed.filter('pickedItems.@each.createIssue', function (poi) {
      return poi.get('createIssue');
    }),
    issueVisible: Ember.computed('piIssues', function () {
      return this.get('piIssues.length') > 0;
    }),
    inflated: Ember.computed.filterBy('pickedItems', 'inflated'),
    hasInflates: Ember.computed('inflated.length', function () {
      return this.get('inflated.length') > 0;
    }),
    searchInput: '',
    unchecked: Ember.computed.filter('pickedItems.@each.checker', function (pi) {
      return !pi.get('checker.id');
    }),
    markInflatesComplete: (0, _emberApiActions.memberAction)({ path: 'inflates_complete', type: 'put' })

  });
});