define('loftus/models/web-user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    lastLoginAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    passwordReset: _emberData.default.attr('string'),
    passwordResetExpiresAt: _emberData.default.attr('date'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),
    resetPassword: _emberData.default.attr('boolean'),
    customer: _emberData.default.belongsTo('customer'),
    contact: _emberData.default.belongsTo('contact'),
    isRep: _emberData.default.attr('boolean'),
    salesRep: _emberData.default.belongsTo('sales-rep')
  });
});