define('loftus/models/received-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    quantityStocked: _emberData.default.attr('number'),
    quantityAdded: 0,
    stocker: _emberData.default.belongsTo('user'),
    stockedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    purchaseOrderItem: _emberData.default.belongsTo('purchase-order-item'),
    receivingTote: _emberData.default.belongsTo('receiving-tote'),
    user: _emberData.default.belongsTo('user'),
    receivingToteId: _emberData.default.attr('number'),
    freightItem: _emberData.default.belongsTo('freight-item'),
    bin: _emberData.default.belongsTo('bin'),
    stockingBin: Ember.computed('bin.location', 'purchaseOrderItem.item.stockBin.location', 'purchaseOrderItem.item.updatedStockBin.location', function () {
      if (this.get('purchaseOrderItem.item.updatedStockBin')) {
        return this.get('purchaseOrderItem.item.updatedStockBin');
      }
      if (this.get('bin.id')) {
        return this.get('bin');
      }
      return this.get('purchaseOrderItem.item.stockBin');
    }),
    toteId: _emberData.default.attr('number'),
    unscanned: _emberData.default.attr('boolean'),
    timeElapsed: _emberData.default.attr('number'),
    receivingIssues: _emberData.default.hasMany('receiving-issue'),
    receivingIssue: '',
    keypadValue: Ember.computed('quantityRemaining', function () {
      return this.get('quantityRemaining');
    }),
    keypadVisible: false,
    createIssue: false,
    detailsVisible: Ember.computed('keypadVisible', 'createIssue', function () {
      return !this.get('keypadVisible') && !this.get('createIssue');
    }),
    cases: Ember.computed('quantityRemaining', function () {
      if (Math.floor(this.get('quantityRemaining') / this.get('purchaseOrderItem.item.caseQuantity')) > 0) {
        return Math.floor(this.get('quantityRemaining') / this.get('purchaseOrderItem.item.caseQuantity')) + ' @ ' + this.get('purchaseOrderItem.item.caseQuantity');
      } else {
        return 'Partial';
      }
    }),
    quantityRemaining: Ember.computed.alias('quantityUnstocked'),
    quantityUnstocked: Ember.computed('quantity', 'quantityStocked', function () {
      var unstocked = this.get('quantity') - (this.get('quantityStocked') || 0) * 1;
      if (isNaN(unstocked)) {
        return 0;
      } else {
        return unstocked;
      }
    }),
    visible: Ember.computed('receivingTote.searchInput', 'receivingTote.showRemaining', 'receivingTote.visibleId', function () {
      var search = ('' + this.get('receivingTote.searchInput')).toUpperCase();
      var showRemaining = this.get('receivingTote.showRemaining');
      return this.get('quantityUnstocked') > 0 && (search !== '' && (search === this.get('purchaseOrderItem.item.sku') || search === this.get('purchaseOrderItem.item.upc')) || this.get('id') == this.get('receivingTote.visibleId') || search === 'RCVD' + this.get('id') || showRemaining);
    }),
    maxQuantity: Ember.computed('receivingIssue', 'quantityRemaining', function () {
      if (this.get('receivingIssue') === 'Overage') {
        return null;
      } else {
        return this.get('quantityRemaining');
      }
    }),
    scanCode: Ember.computed.alias('stockingBin.location'),
    sameItems: Ember.computed('receivingTote.@each.receivedItems', function () {
      var _this = this;
      return this.get('receivingTote.receivedItems').filter(function (ri) {
        return ri.get('quantityRemaining') > 0 && _this.get('purchaseOrderItem.item.id') === ri.get('purchaseOrderItem.item.id') && _this.get('receivingTote.id') == ri.get('receivingTote.id');
      }).length;
    })

  });
});