define('loftus/components/pack-shipment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    actions: {
      submit: function submit() {
        var boxRegex = /^(BOX|box)(\d+)\d{3}$/;
        var scan = this.get('boxTag');
        if (scan && scan.match(boxRegex) && scan.match(boxRegex)[2]) {
          this.get('router').transitionTo('shipment.process', scan.match(boxRegex)[2]);
        }
      }
    }
  });
});