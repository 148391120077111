define('loftus/components/project-tasks', ['exports', 'ember-concurrency', 'moment'], function (exports, _emberConcurrency, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    speech: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    checked: 0,
    task: Ember.computed.alias('record.model'),
    didInsertElement: function didInsertElement() {
      this.send('updateClock');
    },
    complete: Ember.computed('checked', function () {
      console.log('check complete');
      return this.get('task.parts.length') == this.get('checked');
    }),
    actions: {
      toggle: function toggle(part) {

        if (part.checked) {
          this.set('checked', this.get('checked') - 1);
        } else {
          this.set('checked', this.get('checked') + 1);
        }

        console.log(this.get('checked'));
      },
      updateClock: function updateClock() {
        var endTime = (0, _moment.default)(this.get('record.createdAt')).add(this.get('task.expectedTime'), 'minutes');
        var dur = _moment.default.duration(endTime.diff((0, _moment.default)()));
        if ((0, _moment.default)() < endTime) {
          this.set('clock', dur.minutes() + ':' + (dur.seconds() < 10 ? '0' : '') + dur.seconds());
        } else {
          this.set('clock', '0:00');
        }

        var _this = this;
        if (!this.get('complete')) {
          setTimeout(function () {
            _this.send('updateClock', 250);
          });
        }
      },
      completeProject: function completeProject() {
        this.set('record.completedAt', new Date());
        this.get('record').save();
      }
    }
  });
});