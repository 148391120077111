define('loftus/components/burger-menu', ['exports', 'ember-burger-menu/components/burger-menu'], function (exports, _burgerMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _burgerMenu.default;
    }
  });
});