define('loftus/models/cart', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    poNumber: _emberData.default.attr('string'),
    total: _emberData.default.attr('number'),
    contact: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    shipVia: _emberData.default.attr('string'),
    shipComment: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    terms: _emberData.default.attr('string'),
    mustShipBy: _emberData.default.attr('date'),
    dropShip: _emberData.default.attr('boolean'),
    webUser: _emberData.default.belongsTo('web-user'),
    customer: _emberData.default.belongsTo('customer'),
    shipTo: _emberData.default.belongsTo('ship-to'),
    promo: _emberData.default.belongsTo('promo'),
    paymentToken: _emberData.default.belongsTo('payment-token'),
    cartItems: _emberData.default.hasMany('cart-item'),
    orders: _emberData.default.hasMany('order'),
    order: _emberData.default.attr('number', { readOnly: true })
  });
});