define('loftus/models/product-group', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    upc: _emberData.default.attr('string'),
    retail: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    productType: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    items: _emberData.default.hasMany('item'),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' })
  });
});