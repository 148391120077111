define('loftus/components/manage-timeclocks', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    showDetails: true,
    punchedIn: Ember.computed.filterBy('model.timeClocks', 'outAt', null),
    hours: Ember.computed.mapBy('model.timeClocks', 'hours'),
    unroundedHours: Ember.computed.sum('hours'),
    sort: ['inAt:desc'],
    sortedTime: Ember.computed.sort('model.timeClocks', 'sort'),
    totalHours: Ember.computed('unroundedHours', function () {
      return Math.round(this.get('unroundedHours') * 100) / 100;
    }),
    actions: {
      editTime: function editTime(time) {
        if (time.get('editTime') && time.get('hasDirtyAttributes')) {
          time.save();
        }
        time.toggleProperty('editTime');
      },
      destroyTime: function destroyTime(time) {
        var destroy = confirm('Are you sure you want to delete this time clock entry?');
        if (destroy) {
          time.destroyRecord();
        }
      },
      toggleTardy: function toggleTardy(time) {
        time.toggleProperty('tardy');
        time.set('editTime', false);
        time.save();
      },
      toggleAbsent: function toggleAbsent(time) {
        time.toggleProperty('absent');
        time.set('editTime', false);
        time.save();
      },
      toggleNotice: function toggleNotice(time) {
        time.toggleProperty('noNotice');
        time.set('editTime', false);
        time.save();
      },
      addTime: function addTime() {
        var time = new Date();
        this.get('store').createRecord('time-clock', {
          user: this.get('model'),
          inAt: time,
          outAt: time,
          editTime: true
        });
      },
      toggleDetails: function toggleDetails() {
        this.toggleProperty('showDetails');
      },
      timeUpdated: function timeUpdated(time, target, value) {
        var parsed = (0, _moment.default)(value.target.value, 'MM/DD/YY hh:mm:ss a', true);
        if (parsed._isValid) {
          if (target === 'in') {
            time.set('inAt', parsed.toDate());
          } else {
            time.set('outAt', parsed.toDate());
          }
        } else if (!value.target.value || value.target.value === '') {
          if (target === 'in') {
            time.set('inAt', null);
          } else {
            time.set('outAt', null);
          }
        }
      }

    }

  });
});