define('loftus/routes/quote/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var quote = this.store.createRecord('quote', {
        total: 0,
        status: 'open'

      });

      return quote;
    },

    titleToken: 'New Quote'
  });
});