define("loftus/controllers/pods/manage", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    formats: ["Full Pod", "Half Pod", "2x2x1", "2x2x2", "2x2x3", "2x2x4", "2x3x2", "2x3x3", "2x3x4", "2x3x6", "2x4x2", "2x4x3", "2x4x4", "2x4x5", "2x4x6", "2x6x6", "2x8x8"],
    materials: ['wood', 'metal'],
    actions: {
      newPod: function newPod() {
        this.store.createRecord('pod');
      },
      savePod: function savePod(pod) {
        pod.save();
      },
      print: function print(pod, format) {
        pod.print({ label_type: format });
      },
      clear: function clear(pod) {
        pod.set('clear', true);
        pod.save();
      }
    }

  });
});