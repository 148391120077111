define('loftus/models/work-order-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantityOrdered: _emberData.default.attr('number'),
    quantityReceived: _emberData.default.attr('number'),
    quantityReceivedToDate: _emberData.default.attr('number'),
    productionCost: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    workOrder: _emberData.default.belongsTo('work-order'),
    workOrderItemParts: _emberData.default.hasMany('work-order-item-part'),
    item: _emberData.default.belongsTo('item')
  });
});