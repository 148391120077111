define('loftus/components/customer-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    customerNumber: '',
    results: [],
    didInsertElement: function didInsertElement() {
      $('input.ember-power-select-search-input').focus();
    },
    actions: {
      select: function select(customer) {
        this.sendAction('toggle');
        this.sendAction('transition', 'customer.edit', customer.id);
      },
      searchAsync: function searchAsync(data) {
        if (data.length > 2) {
          return this.get('store').createRecord('customer').autoComplete({ search: data });
        }
      },
      autoComplete: function autoComplete() {

        if (this.get('customerNumber').length < 3 || this.get('customerNumber') === this.get('lastSearch')) {
          return;
        }

        var _this = this;
        this.get('store').createRecord('customer').autoComplete({ search: this.get('customerNumber') }).then(function (customers) {
          _this.set('lastSearch', _this.get('customerNumber'));
          _this.set('results', customers);
          return customers;
        });
      },
      toggleSearch: function toggleSearch() {
        this.sendAction('toggle');
      },
      searchCustomers: function searchCustomers() {
        var _this = this;
        if (this.get('customerNumber') !== '') {
          this.get('store').queryRecord('customer', { filter: { number: this.get('customerNumber') } }).then(function (customer) {
            _this.sendAction('transition', 'customer.edit', customer);
            _this.sendAction('toggle');
            _this.set('customerNumber', '');
          });
          // let _this = this;
          // this.get('store').findRecord('order',this.get('orderId')).then(function(order){
          //   _this.sendAction('transition','order.edit',order.get('id'));
          //   _this.set('orderNumber','');
          // });
        }
      }
    }
  });
});