define('loftus/models/picked-item', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    pickedAt: _emberData.default.attr('date'),
    checker: _emberData.default.belongsTo('user'),
    quantityCounted: _emberData.default.attr('number'),
    checkedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    orderItem: _emberData.default.belongsTo('order-item'),
    bin: _emberData.default.belongsTo('bin'),
    workOrderItemPart: _emberData.default.belongsTo('work-order-item-part'),
    user: _emberData.default.belongsTo('user'),
    toteId: _emberData.default.attr('number'),
    boxTag: _emberData.default.attr('string'),
    pickingTote: _emberData.default.belongsTo('picking-tote'),
    unscanned: _emberData.default.attr('boolean'),
    timeElapsed: _emberData.default.attr('number'),
    uuid: _emberData.default.attr('string'),
    unscannable: false,
    unstocked: Ember.computed.mapBy('receivedItems', 'quantityUnstocked'),
    quantityUnstocked: Ember.computed.sum('unstocked'),
    keypadVisible: false,
    createIssue: false,
    box: _emberData.default.belongsTo('box'),
    detailsVisible: Ember.computed('keypadVisible', 'createIssue', function () {
      return !this.get('keypadVisible') && !this.get('createIssue');
    }),
    toteInput: '',
    pickingIssue: '',
    boxContents: _emberData.default.hasMany('box-content'),
    boxSort: ['id'],
    sortedBoxContents: Ember.computed.sort('boxContents', 'boxSort'),
    boxedAfterCurrent: Ember.computed('keypadValue', 'orderItem.quantityBoxed', function () {
      return this.get('orderItem.quantityBoxed') + (this.get('keypadValue') || 0) * 1;
    }),
    pickingIssues: _emberData.default.hasMany('picking-issue'),
    boxed: Ember.computed.map('boxContents.@each.quantitySaved', function (item) {
      return +item.get('quantitySaved');
    }),
    innersRemaining: Ember.computed('quantityRemaining', 'orderItem.item.innerQuantity', 'orderItem.item.innerCode', function () {
      if (this.get('orderItem.item.uom') === 'EA' && this.get('orderItem.item.innerCode') && this.get('orderItem.item.innerCode.length') > 0 && this.get('orderItem.item.innerQuantity') && this.get('quantityRemaining') % this.get('orderItem.item.innerQuantity') === 0) {
        return this.get('quantityRemaining') / this.get('orderItem.item.innerQuantity');
      }
    }),
    quantityBoxed: Ember.computed.sum('boxed'),
    quantityRemaining: Ember.computed('quantity', 'quantityBoxed', 'boxContents.@each.quantitySaved', function () {
      return this.get('quantity') - (this.get('quantityBoxed') || 0);
    }),
    keypadMax: Ember.computed('quantityRemaining', 'orderItem.item.innerQuantity', 'orderItem.item.innerCode', 'quantity', function () {
      return this.get('innersRemaining') || this.get('quantityRemaining');
    }),
    keypadValue: Ember.computed('quantityRemaining', function () {
      return this.get('quantityRemaining');
    }),
    visibleDeprecate: Ember.computed('pickingTote.searchInput', 'pickingTote.showRemaining', function () {
      var search = (this.get('pickingTote.searchInput') + '').toUpperCase();
      return this.get('quantityRemaining') > 0 && search !== '' && (search === this.get('orderItem.item.sku') || search === this.get('orderItem.item.upc'));
    }),
    shipment: Ember.computed.alias('orderItem.shipment'),
    boxes: Ember.computed('orderItem.shipment.boxes', function () {
      return this.get('shipment.boxes');
    }),
    needsCustomerLabel: Ember.computed('labelPrinted', 'customerLabels', function () {
      console.log(this.get('customerLabels.length'));
      console.log(!this.get('labelPrinted'));
      return this.get('customerLabels.length') > 0 && !this.get('labelPrinted');
    }),
    customerLabel: Ember.computed('customerLabels', function () {
      return this.get('customerLabels.firstObject');
    }),
    customerLabels: Ember.computed('orderItem.item.customerItems.@each.barcode', 'orderItem.order.customer.customer', function () {
      var customer = this.get('orderItem.order.customer.customer');
      return this.get('orderItem.item.customerItems').reduce(function (acc, value) {
        if (value.get('customer.id') === customer.get('id') && value.get('barcode')) {
          acc.push(value);
        }
        return acc;
      }, []);
    }),
    printLabels: (0, _emberApiActions.memberAction)({ path: 'print_labels', type: 'put' }),
    allBins: Ember.computed.alias('shipment.allBins'),
    boxCode: Ember.computed('shipment.boxes', function () {
      if (!this.get('box') || this.get('box.boxNumber') === 0) {
        return 'Tag Needed';
      } else {
        var pad = '';
        if (this.get('box.boxNumber') < 10) {
          pad = '00';
        } else {
          pad = '0';
        }
        return 'BOX' + this.get('shipment.id') + pad + this.get('box.boxNumber');
      }
    }),
    binLocation: Ember.computed('orderItem.shipment.pickingBin', function () {
      return this.get('orderItem.shipment').get('pickingBin.location');
    }),
    scanText: 'Scan Box',
    inflated: Ember.computed.alias('orderItem.inflated')

  });
});