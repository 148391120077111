define('loftus/models/survey', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    anonymous: _emberData.default.attr('boolean'),
    questions: _emberData.default.attr('json'),
    instructions: _emberData.default.attr('string'),
    answers: _emberData.default.attr('json'),
    details: _emberData.default.attr('json'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    userActions: _emberData.default.hasMany('user-action'),
    contact: _emberData.default.belongsTo('contact'),
    user: _emberData.default.belongsTo('user')
  });
});