define('loftus/models/vendor', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    remitAddress1: _emberData.default.attr('string'),
    remitAddress2: _emberData.default.attr('string'),
    remitCity: _emberData.default.attr('string'),
    remitState: _emberData.default.attr('string'),
    remitZip: _emberData.default.attr('string'),
    remitCountry: _emberData.default.attr('string'),
    balance: _emberData.default.attr('number'),
    ytdPayments: _emberData.default.attr('number'),
    discounts: _emberData.default.attr('number'),
    days: _emberData.default.attr('string'),
    ytdDiscounts: _emberData.default.attr('number'),
    ytdLost: _emberData.default.attr('number'),
    phone: _emberData.default.attr('string'),
    import: _emberData.default.attr('boolean'),
    fax: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    terms: _emberData.default.attr('string'),
    federalId: _emberData.default.attr('string'),
    exempt: _emberData.default.attr('boolean'),
    checkMemo: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    reconciledAt: _emberData.default.attr('date'),
    commClass: _emberData.default.attr('string'),
    minimum: _emberData.default.attr('number'),
    pendingTotal: _emberData.default.attr('number', { readOnly: true }),
    daysOut: _emberData.default.attr('number'),
    transitDays: _emberData.default.attr('number'),
    daysToStock: _emberData.default.attr('number'),
    active: _emberData.default.attr('boolean'),
    policy: _emberData.default.attr('string'),
    policyDate: _emberData.default.attr('date'),
    policyUrl: _emberData.default.attr('string'),
    contacts: _emberData.default.hasMany('contact'),
    purchaseOrders: _emberData.default.hasMany('purchase-order'),
    freightShipments: _emberData.default.hasMany('freight-shipment'),
    items: _emberData.default.hasMany('item'),
    previewItems: Ember.computed('items', function () {
      return this.get('items');
    }),
    poPreview: (0, _emberApiActions.memberAction)({ path: 'po_preview', type: 'get' }),
    getPendingPO: (0, _emberApiActions.memberAction)({ path: 'po_preview?pending=true', type: 'get' }),
    createPendingPO: (0, _emberApiActions.memberAction)({ path: 'po_preview?create=true', type: 'get' }),
    modelName: Ember.computed(function () {
      return this.get('constructor.modelName');
    }),
    addressLines: Ember.computed('company', 'address1', 'city', 'state', 'zip', 'country', function () {
      var lines = [];
      lines.push(this.get('company'));
      lines.push(this.get('address1'));
      lines.push(this.get('city') + ', ' + this.get('state') + ' ' + this.get('zip'));
      if (this.get('country') !== 'US' && this.get('country') !== '') {
        lines.push(this.get('country'));
      }
      return lines.join("\n");
    })
  });
});