define('loftus/models/category-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    sku: _emberData.default.attr('string', { readOnly: true }),
    title: _emberData.default.attr('string', { readOnly: true }),
    category: _emberData.default.belongsTo('category'),
    categories: _emberData.default.attr({ readOnly: true }),
    item: _emberData.default.belongsTo('item')
  });
});