define('loftus/components/credit-card-entry', ['exports', 'loftus/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    hadSuccess: false,
    willDestroyElement: function willDestroyElement() {
      this.set('hadSuccess', true);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      (function (window, document, Heartland) {
        var hps = new Heartland.HPS({
          publicKey: _environment.default.heartlandKey,
          type: 'iframe',
          fields: {
            cardNumber: {
              target: 'iframesCardNumber',
              placeholder: '•••• •••• •••• ••••'
            },
            cardExpiration: {
              target: 'iframesCardExpiration',
              placeholder: 'MM / YYYY'
            },
            cardCvv: {
              target: 'iframesCardCvv',
              placeholder: 'CVV'
            },
            submit: {
              target: 'iframesSubmit'
            }
          },
          style: {
            'input[type=text], input[type=tel]': {
              'background-color': '#fff',
              'border': '1px solid #ddd',
              'border-radius': '3px',
              'box-shadow': 'inset 0 1px 3px rgba(0, 0, 0, 0.06)',
              'box-sizing': 'border-box',
              'margin-bottom': '0.75em',
              'padding': '0.5em',
              'transition': 'border-color 150ms ease',
              'width': '100%;'
            },
            'input[type=text]:focus, input[type=tel]:focus': {
              'border-color': '#2478CC',
              'box-shadow': 'inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(32, 107, 182, 0.7)',
              'outline': 'none;     '
            },
            'input[type=submit]': {
              'background-color': '#2478CC',
              'border': '0',
              'border-radius': '3px',
              'color': '#fff',
              'cursor': 'pointer',
              'display': 'inline-block',
              'font-family': '-apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif',
              'font-size': '1em',
              '-webkit-font-smoothing': 'antialiased',
              'font-weight': '600',
              'line-height': '1',
              'padding': '0.75em 1.5em',
              'text-align': 'center',
              'text-decoration': 'none',
              'transition': 'background-color 150ms ease',
              'user-select': 'none',
              'vertical-align': 'middle',
              'white-space': 'nowrap;'
            }
          },
          onTokenSuccess: function onTokenSuccess(resp) {

            if (_this.get('hadSuccess')) {
              return;
            }

            _this.set('hadSuccess', true);
            _this.sendAction('action', resp);
          },
          onTokenError: function onTokenError(resp) {
            // if (window.console && window.console.log) {
            //   console.log('There was an error: ' + resp.error.message);
            // }
          }
        });

        Heartland.Events.addHandler(document.getElementById('iframes'), 'submit', function (e) {
          e.preventDefault();
          hps.Messages.post({
            accumulateData: true,
            action: 'tokenize',
            message: _environment.default.heartlandKey
          }, 'cardNumber');
        });
      })(window, document, Heartland);
    }
  });
});