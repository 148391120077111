define('loftus/controllers/product-groups', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    speech: Ember.inject.service(),
    productTypeOptions: ['cogs', 'supplies'],
    actions: {
      addGroup: function addGroup() {
        this.get('store').createRecord('product-group');
      },
      save: function save(group) {
        group.save();
      },
      print: function print(group) {
        var _this = this;
        group.print().then(function (response) {
          if (response.status === 'SUCCESS') {
            _this.get('speech').speak('printed ' + response.quantity + ' labels');
          }
        });
      }
    }
  });
});