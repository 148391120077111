define('loftus/models/zone', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    position: _emberData.default.attr('string'),
    points: _emberData.default.hasMany('point'),
    pods: _emberData.default.hasMany('pod'),
    bots: _emberData.default.hasMany('bot'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    width: Ember.computed('scale', function () {
      return Math.round(2200 * this.get('scale'));
    }),
    height: Ember.computed('scale', function () {
      return Math.round(2600 * this.get('scale'));
    }),
    scale: 1.5,
    inches: 52,
    px: Ember.computed('scale', 'inches', function () {
      return this.get('scale') * this.get('inches');
    })

  });
});