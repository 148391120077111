define('loftus/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),

    load: function load() {
      var _this = this;

      console.log(window.location.href);
      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user', { me: true, ver: '0.1.0' }).then(function (user) {
          _this.set('user', user);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});