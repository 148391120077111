define('loftus/controllers/bots/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    commands: [{ name: 'plate_up', icon: 'arrow-circle-up' }, { name: 'forward', icon: 'chevron-up' }, { name: 'navigate_forward', icon: 'arrow-up' }, { name: 'left', icon: 'arrow-circle-left' }, { name: 'stop', icon: 'stop' }, { name: 'right', icon: 'arrow-circle-right' }, { name: 'plate_down', icon: 'arrow-circle-down' }, { name: 'back', icon: 'chevron-down' }, { name: 'navigate_back', icon: 'arrow-down' }, { name: 'ccw', icon: 'undo' }, { name: 'half', icon: 'refresh' }, { name: 'cw', icon: 'repeat' }, { name: 'orient', icon: 'crosshairs' }, { name: 'off_point_center', icon: 'align-center' }, { name: 'power_cycle', icon: 'power-off' }, { name: 'forward_point', icon: 'arrow-circle-o-up' }, { name: 'clear_bot', icon: 'certificate' }, { name: 'back_point', icon: 'arrow-circle-o-down' }],
    piCommands: [{ name: 'Reload Camera', command: 'reload_camera' }, { name: 'Reboot', command: 'reboot' }],
    actions: {
      control: function control(com) {
        this.set('model.control', com);
        this.get('model').save();
      },
      updateLidar: function updateLidar() {
        this.get('model').save();
      },
      updateSpeed: function updateSpeed() {
        this.get('model').save();
      }
    }
  });
});