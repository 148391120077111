define('loftus/components/project-cart-moving', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    speech: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    model: Ember.computed.alias('record.model'),
    didInsertElement: function didInsertElement() {
      this.$('.scanned-input').focus();
    },
    placeholder: Ember.computed('model.bin.id', function () {
      if (this.get('model.bin.id')) {
        return 'Scan Cart';
      } else {
        return 'Scan Zone';
      }
    }),
    actions: {
      validateScan: function validateScan() {
        var scan = this.get('scannedInput');
        if (this.get('model.bin.id')) {
          var cartRegex = /^(CART|cart)(\d+)$/;
          if (scan && scan.match(cartRegex) && scan.match(cartRegex)[2] * 1 == this.get('model.id') * 1) {
            var bin = this.get('model.bin');
            this.set('model.status', 'moving');
            this.set('model.projectRecordId', null);
            this.get('model').then(function (model) {
              model.save();
            });
            bin.set('receivingCart', null);
            bin.save();
            this.set('scannedInput', null);
            this.$('.scanned-input').focus();
          } else {
            this.set('scannedInput', null);
            this.get('speech').speak('no');
            this.$('.scanned-input').focus();
          }
        } else {
          var zoneRegex = /^(ZONE|zone)(\d+)$/;
          if (scan && scan.match(zoneRegex) && scan.match(zoneRegex)[2] * 1 == this.get('model.zone') * 1) {
            var _this = this;
            this.set('model.status', 'stocking');
            this.set('model.projectRecordId', this.get('record.id'));
            this.get('model').then(function (model) {

              model.save();
              _this.set('record.completedAt', new Date());
            });
          } else {
            this.set('scannedInput', null);
            this.get('speech').speak('no');
            this.$('.scanned-input').focus();
          }
        }
        console.log(this.get('scannedInput'));
      }
    }
  });
});