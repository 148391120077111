define('loftus/models/order-payment', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    paymentType: _emberData.default.attr('string'),
    authorizationCode: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    authAmount: _emberData.default.attr('number'),
    avsResultCode: _emberData.default.attr('string'),
    cvvResultCode: _emberData.default.attr('string'),
    cardType: _emberData.default.attr('string'),
    responseCode: _emberData.default.attr('string'),
    gatewayResponseCode: _emberData.default.attr('string'),
    transactionId: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    maskedCardNumber: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    orderId: _emberData.default.attr('number'),
    order: _emberData.default.belongsTo('order'),
    customer: _emberData.default.belongsTo('customer'),
    paymentToken: _emberData.default.belongsTo('payment-token'),
    emailReceiptData: _emberData.default.attr({ readOnly: true }),
    canVoid: Ember.computed('status', 'amount', 'authAmount', function () {
      return this.get('paymentToken.id') && this.get('amount') === this.get('authAmount') && (this.get('status') === 'pending' || this.get('status') === 'success');
    }),
    canDestroy: Ember.computed('paymentToken.id', function () {
      return !this.get('paymentToken.id') && this.get('order.open');
    }),
    void: (0, _emberApiActions.memberAction)({ path: 'void', type: 'put' }),
    pdfReceipt: (0, _emberApiActions.memberAction)({ path: 'receipt', type: 'get' }),
    emailReceipt: (0, _emberApiActions.memberAction)({ path: 'email_receipt', type: 'put' }),
    capture: (0, _emberApiActions.memberAction)({ path: 'capture', type: 'put' })

  });
});