define('loftus/services/speech', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    speechReader: Ember.inject.service(),
    speak: function speak(phrase) {
      var speechReader = this.get('speechReader');
      var newReader = speechReader.getNewReader(phrase);
      newReader.play();
    }
  });
});