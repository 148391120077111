define('loftus/models/shipment', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    marketId: _emberData.default.attr('string'),
    shippingServiceId: _emberData.default.attr('number'),
    shippingMethodId: _emberData.default.attr('number'),
    charge: _emberData.default.attr('number'),
    credit: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    picker: _emberData.default.belongsTo('user', { async: true }),
    shipperId: _emberData.default.attr('number'),
    carton: _emberData.default.belongsTo('carton'),
    shipTo: _emberData.default.belongsTo('ship-to'),
    shippingService: _emberData.default.belongsTo('shipping-service'),
    trackingNumber: _emberData.default.attr('string'),
    trackingUrl: Ember.computed('trackingNumber', function () {
      var track = this.get('trackingNumber');
      if (track) {
        if (track.slice(0, 2) === '1Z') {
          return 'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + track;
        } else if (track.slice(0, 1) === '7' && track.length === 12 || track.slice(0, 2) === '96' && track.length === 22) {
          return 'https://www.fedex.com/apps/fedextrack/?tracknumbers=' + track;
        } else if (track.slice(0, 2) === '94' && track.length === 22) {
          return 'https://tools.usps.com/go/TrackConfirmAction?tLabels=' + track;
        }
      }
    }),
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    station: _emberData.default.belongsTo('station'),
    pickableAt: _emberData.default.attr('date'),
    pickedAt: _emberData.default.attr('date'),
    validatedAt: _emberData.default.attr('date'),
    shippedAt: _emberData.default.attr('date'),
    guaranteed: _emberData.default.attr('boolean'),
    signatureRequired: _emberData.default.attr('boolean'),
    rateOnly: _emberData.default.attr('boolean'),
    blind: _emberData.default.attr('boolean'),
    shipWhenAvailable: _emberData.default.attr('boolean'),
    carrierName: _emberData.default.attr('string', { readOnly: true }),
    boxed: _emberData.default.attr('boolean', { readOnly: true }),
    willCallBin: _emberData.default.attr('string', { readOnly: true }),
    canShipBy: _emberData.default.attr('date'),
    canShip: Ember.computed('boxes.length', 'boxes.@each.hasDims', function () {
      return this.get('boxes.length') > 0 && (this.get('willCall') || this.get('boxes').filterBy('hasDims', false).length === 0 && this.get('boxes').filterBy('hasDirtyAttributes', true).length === 0);
    }),
    orderSubTotals: Ember.computed.mapBy('orders', 'subTotal'),
    subTotal: Ember.computed.sum('orderSubTotals'),
    boxWeights: Ember.computed.mapBy('boxes', 'weight'),
    weight: Ember.computed.sum('boxWeights'),
    mustShipBy: _emberData.default.attr('date'),
    mustReceiveBy: _emberData.default.attr('date'),
    deliveredAt: _emberData.default.attr('date'),
    rma: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    shipVia: _emberData.default.attr('string'),
    packages: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    orders: _emberData.default.hasMany('order'),
    order: Ember.computed.alias('orders.firstObject'),
    unpackedOrders: Ember.computed('orders.@each.remainingTotes', function (i) {
      var unpacked = [];
      this.get('orders').forEach(function (ord) {
        if (ord.get('remainingTotes.length') > 0) {
          unpacked.pushObject(ord);
        }
      });
      return unpacked;
    }),
    orderIds: _emberData.default.attr(),
    boxes: _emberData.default.hasMany('box', { async: true }),
    orderItems: _emberData.default.hasMany('order-item'),
    pickingBins: _emberData.default.hasMany('picking-bin'),
    isShipped: Ember.computed('boxes.@each.trackingNumber', function () {
      return this.get('boxes').filterBy('hasTrackingNumber', true).length > 0;
    }),
    boxHasImage: Ember.computed('boxes.@each.image', function () {
      return this.get('boxes').filterBy('hasImage', true).length > 0;
    }),
    willCall: Ember.computed('shippingService.name', function () {
      return this.get('shippingService.name') === 'Will Call';
    }),
    printProp65: (0, _emberApiActions.memberAction)({ path: 'print_prop65', type: 'put' }),
    printBoxTag: (0, _emberApiActions.memberAction)({ path: 'print_box_tag', type: 'put' }),
    printBoxTags: (0, _emberApiActions.memberAction)({ path: 'print_box_tags', type: 'put' }),
    combineOrder: (0, _emberApiActions.memberAction)({ path: 'combine_order', type: 'put' }),
    unpick: (0, _emberApiActions.memberAction)({ path: 'unpick', type: 'put' }),
    postShip: (0, _emberApiActions.memberAction)({ path: 'post_ship', type: 'put' }),
    assignPickingBin: (0, _emberApiActions.memberAction)({ path: 'assign_picking_bin', type: 'put' }),
    billShipper: _emberData.default.attr('boolean'),
    thirdParty: _emberData.default.attr('boolean'),
    customer: Ember.computed.alias('orders.firstObject.customer'),
    isBoxed: (0, _emberApiActions.memberAction)({ path: 'is_boxed' }),
    validateAddress: (0, _emberApiActions.memberAction)({ path: 'validate_address' }),
    sortedBoxes: Ember.computed.sort('boxes', 'sortBoxNumber'),
    sortBoxNumber: ['boxNumber'],
    // box: computed('boxes.@each.id',function(){
    //   if (this.get('sortedBoxes').length === 0) {
    //     return this.get('nextBox');
    //   }
    //   return this.get('sortedBoxes').get('lastObject');
    // }),
    // nextBox: computed('boxes.@each.id',function(){
    //   if (this.get('boxes.length') === 0 || this.get('box.contents.length') > 0) {
    //     let _this = this;

    //     return new EmberPromise((resolve) => {
    //       let boxNumber = _this.get('sortedBoxes').length + 1;
    //       _this.store.createRecord('box',{
    //         boxNumber: boxNumber,
    //         shipment: _this,
    //       }).save();
    //     });
    //   } else {
    //     return new EmberPromise((resolve) => {
    //       resolve(this.get('box'));
    //     });
    //   }

    // }),
    unpairedTotes: Ember.computed.filter('pickingTotes.@each.sortingAt', function (tote) {
      return !!tote.get('sortingAt');
    }),
    // pairedTotes: computed.filterBy('pickingTotes','sortingAt',null),
    pairedTotes: Ember.computed.filter('pickingTotes.@each.sortingAt', function (tote) {
      return tote.get('pickingBin.id') && !tote.get('sortingAt');
    }),
    pickingTotes: _emberData.default.hasMany('picking-tote'),
    remainingTotes: Ember.computed.filterBy('pickingTotes', 'isComplete', false),
    podBin: Ember.computed('pickingBins', function () {
      var bin = this.get('pickingBins').filterBy('binType', 'pod').get('firstObject');
      return bin;
    }),
    pickingBin: Ember.computed('pickingBins', function () {

      console.log('pickingBin');
      var bin = this.get('pickingBins').get('firstObject');
      if (bin) {
        return bin;
      } else {

        var context = this;
        this.assignPickingBin().then(function (response) {
          context.store.pushPayload(response);
        });
      }
    }),
    boxCount: Ember.computed('boxes', function () {
      return this.get('boxes.length');
    }),
    serviceObserver: Ember.observer('shippingService.id', function () {
      this.set('updatedAt', new Date());
    })
  });
});