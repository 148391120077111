define('loftus/components/project-shelf-height', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      $('input[autofocus]').focus();
      $('.mobile-title').html(this.get('record.projectBatch.project.name'));
    },
    willDestroyElement: function willDestroyElement() {
      $('.mobile-title').html('');
    },

    actions: {
      setKeypadTarget: function setKeypadTarget(shelf, target) {
        shelf.set('keypadTarget', target);
        shelf.set('keypadValue', shelf.get(shelf.get('keypadTarget')) || 0);
      },
      keypadEntry: function keypadEntry(shelf, size) {
        var dims = size.split('x');
        shelf.set('height', dims[0]);
        shelf.set('keypadValue', dims[0]);
        shelf.set('keypadTarget', 'height');
      },
      keypadDigit: function keypadDigit(shelf, e) {

        shelf.set('keypadTarget', 'height');

        var key = e.target.innerHTML * 1;
        var currentValue = shelf.get('keypadValue');

        var input = $(e.target).closest('.keypad').find('.keypad-input');
        var max = input.attr('max') * 1;

        if (currentValue === '0' || currentValue === '') {
          shelf.set('keypadValue', key);
        } else {

          var new_value = ('' + currentValue + key) * 1;
          if (max > 0) {
            shelf.set('keypadValue', Math.min(new_value * 1, max));
          } else {
            shelf.set('keypadValue', new_value);
          }
        }
        shelf.set(shelf.get('keypadTarget'), shelf.get('keypadValue'));
      },

      keypadBack: function keypadBack(shelf) {
        var currentValue = shelf.get('keypadValue');
        if (currentValue === 0) {
          shelf.set('keypadValue', '');
        } else {
          shelf.set('keypadValue', Math.floor(currentValue / 10));
        }
        shelf.set(shelf.get('keypadTarget'), shelf.get('keypadValue'));
      },

      keypadCancel: function keypadCancel(shelf) {
        shelf.set('height', '');
        shelf.set('keypadValue', '');
        shelf.set(shelf.get('keypadTarget'), shelf.get('keypadValue'));
      },

      keypadSubmit: function keypadSubmit(record) {
        if (record.get('recordable.height') !== 0 && (!record.get('recordable.height') || record.get('recordable.height') === '')) {
          return;
        }

        record.store.peekRecord(record.get('recordable.modelName'), record.get('recordable.id')).save().then(function () {
          record.set('completedAt', new Date());
        });
      }
    }
  });
});