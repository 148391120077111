define('loftus/models/work-order-item-part', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    quantityShort: _emberData.default.attr('number'),
    quantityTransferred: _emberData.default.attr('number'),
    unitsBuilt: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    workOrderItem: _emberData.default.belongsTo('work-order-item'),
    pickedItems: _emberData.default.hasMany('picked-item'),
    assembly: _emberData.default.belongsTo('assembly'),
    projectRecordId: _emberData.default.attr('number'),
    item: Ember.computed.alias('assembly.partItem'),
    totalQuantity: Ember.computed('workOrderItem.quantityOrdered', 'assembly.quantity', function () {
      return this.get('workOrderItem.quantityOrdered') * this.get('assembly.quantity');
    }),
    innersRemaining: Ember.computed('item.innerCode', 'item.innerQuantity', 'quantityRemaining', function () {
      if (this.get('item.uom') === 'EA' && this.get('item.innerCode') && this.get('item.innerCode.length') > 0 && this.get('item.innerQuantity') && this.get('quantityRemaining') % this.get('item.innerQuantity') === 0) {
        return this.get('quantityRemaining') / this.get('item.innerQuantity');
      }
    }),
    quantityRemaining: Ember.computed('quantity', 'quantityPicked', function () {
      return this.get('quantity') - this.get('quantityPicked');
    }),
    casesRemaining: Ember.computed('quantityRemaining', 'item.caseQuantity', function () {
      if (this.get('item.caseQuantity') + 0 === 0) {
        return 0;
      } else if (this.get('item.overstockBins.firstObject.id')) {
        return Math.floor(this.get('quantityRemaining') / this.get('item.caseQuantity'));
      } else {
        return 0;
      }
    }),
    unitsRemaining: Ember.computed('quantityRemaining', 'casesRemaining', 'item.caseQuantity', function () {
      if (!this.get('item.caseQuantity') || this.get('item.caseQuantity') === 0) {
        return this.get('quantityRemaining');
      } else if (this.get('casesRemaining') === 0) {
        return this.get('quantityRemaining');
      } else {
        return this.get('quantityRemaining') % this.get('item.caseQuantity');
      }
    }),
    picked: Ember.computed.mapBy('pickedItems', 'quantity'),
    quantityPicked: Ember.computed.sum('picked'),
    isPicked: Ember.computed('quantityPicked', function () {
      return this.get('quantityPicked') > 0;
    }),
    order: Ember.computed.alias('workOrderItem.workOrder'),
    location: Ember.computed.alias('item.location'),
    unpickedQuantity: Ember.computed('quantity', 'quantityPicked', function () {
      return this.get('quantity') - this.get('quantityPicked') * 1;
    }),
    keypadValue: Ember.computed.alias('unpickedQuantity'),
    title: Ember.computed.alias('item.title')

  });
});