define('loftus/models/project-record', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('number'),
    completedAt: _emberData.default.attr('date'),
    properties: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    projectBatch: _emberData.default.belongsTo('project-batch'),
    recordable: _emberData.default.belongsTo('recordable', { polymorphic: true }),
    modelName: _emberData.default.attr('string', { readOnly: true }),
    model: Ember.computed('recordable.isLoaded', function () {
      if (this.get('recordable.isLoaded')) {
        this.set('recordable.projectRecordId', this.get('id'));
        if (this.get('projectBatch.project.name').indexOf('Case') > -1) {
          this.set('recordable.cases', true);
        } else if (this.get('projectBatch.project.name').indexOf('Pod Picking') > -1) {
          this.set('recordable.podPicking', true);
        }
      }
      return this.get('recordable');
    }),
    binLocation: Ember.computed.alias('model.binLocation'),
    location: Ember.computed('model.bin.id', 'model.bin.location', 'model.binLocation', function () {
      return this.get('model.binLocation') || this.get('model.bin.location');
    }),
    stockingLocation: Ember.computed.alias('model.stockingBin.location'),
    visible: Ember.computed('projectBatch.visibleId', 'model.bin.location', 'model.binLocation', 'model.stockingLocation', function () {
      return this.get('id') === this.get('projectBatch.visibleId');
    })
  });
});