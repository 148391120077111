define('loftus/models/order-return', ['exports', 'ember-api-actions', 'ember-data'], function (exports, _emberApiActions, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    rmaNumber: _emberData.default.attr('string'),
    rmaReason: _emberData.default.attr('string'),
    includeLabel: _emberData.default.attr('boolean'),
    receivedAt: _emberData.default.attr('date'),
    receivedById: _emberData.default.attr('number'),
    trackingNumber: _emberData.default.attr('string'),
    shippingMethodId: _emberData.default.attr('number'),
    transactionId: _emberData.default.attr('string'),
    imageFormat: _emberData.default.attr('number'),
    image: _emberData.default.attr('string', { readOnly: true }),
    restockingFeePercent: _emberData.default.attr('number'),
    refundFreight: _emberData.default.attr('boolean'),
    completedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    order: _emberData.default.belongsTo('order'),
    quote: _emberData.default.belongsTo('quote'),
    user: _emberData.default.belongsTo('user'),
    customer: _emberData.default.belongsTo('customer'),
    generateLabel: (0, _emberApiActions.memberAction)({ path: 'generate_label', type: 'put' }),
    emailLabel: (0, _emberApiActions.memberAction)({ path: 'email_label', type: 'put' }),
    canDestroy: Ember.computed('image', function () {
      return !this.get('image');
    })
  });
});