define('loftus/controllers/totes/scan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      transition: function transition(route, id) {
        this.send('transitionTo', route, id);
      }
    }
  });
});