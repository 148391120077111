define('loftus/components/punch-timeclock', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.$('.user-id').focus();
      var _this = this;
      if (this.get('user')) {
        this.get('user').reload();
        this.get('ajax').request('/api/users/recent_timeclocks').then(function (clock) {
          if (clock.length > 0) {
            _this.set('user.recentTimeClocks', clock);
          }
        });
      }
    },
    user: Ember.computed.alias('currentUser.user'),
    clockedIn: Ember.computed('user.timeClock.inAt', 'user.timeClock.outAt', function () {
      return this.get('user.timeClock.id') && !this.get('user.timeClock.outAt');
    }),
    actions: {
      clearModal: function clearModal() {
        this.sendAction('clearModal');
      },
      punch: function punch() {
        var _this = this;
        if (this.get('requirePin') && (!this.get('pin') || this.get('pin.length') !== 4)) {
          console.log('focusPin');
          $('input.user-pin').focus();

          return;
        }
        if (this.get('id') && this.get('pin') && this.get('pin.length') === 4 && this.get('id.length') > 0) {
          this.get('ajax').request('api/users/authenticate', { method: 'GET', data: { authenticate: { id: this.get('id'), pin: this.get('pin') } } }).then(function (userData) {
            _this.get('store').pushPayload(userData);
            var user = _this.get('store').peekRecord('user', _this.get('id'));
            if (user) {
              _this.set('user', user);
              _this.set('requirePin', false);
              _this.set('pinUsed', true);
            }
          });
        }
      },
      punchIn: function punchIn() {
        var _this = this;
        this.get('store').createRecord('time-clock', {
          user: this.get('user')
        }).save().then(function (tc) {
          _this.set('user.timeClock', tc);
          _this.send('clearModal');
        });
      },
      punchOut: function punchOut() {
        var _this = this;
        this.set('user.timeClock.clockOut', true);
        this.get('user.timeClock').then(function (tc) {
          tc.save();
          _this.send('clearModal');
          if (!_this.get('pinUsed') && _this.get('currentUser.session.isAuthenticated') && _this.get('currentUser.user.department') === 'Warehouse') {
            _this.get('currentUser.session').invalidate();
          }
        });
      }
    }
  });
});