define('loftus/routes/totes', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.findAll('tote');
    },

    actions: {
      transitionTo: function transitionTo(route, id) {
        this.transitionTo(route, id);
      }
    }
  });
});