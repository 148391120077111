define('loftus/components/manage-project-users', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    allZones: Ember.computed(function () {
      return this.get('store').findAll('zone');
    }),
    actions: {
      saveProjectUser: function saveProjectUser(pr) {
        pr.save();
      },
      saveUser: function saveUser(user) {
        user.save();
        user.get('userZones').filterBy('hasDirtyAttributes').invoke('save');
      }
    }
  });
});