define('loftus/components/edit-shelf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    actions: {
      validateShelf: function validateShelf() {
        var _this = this;
        this.get('record').save();

        Ember.RSVP.all(this.get('shelf.bins').filterBy('hasDirtyAttributes', true).invoke('save')).then(function () {
          _this.get('shelf').then(function (shelf) {
            shelf.set('verifiedAt', new Date());
            shelf.set('verifiedBy', _this.get('currentUser.user'));
            shelf.save().then(function () {
              _this.get('record').set('completedAt', new Date());
            });
          });
        });
      },
      reorderBins: function reorderBins(shelf, bins, bin) {
        var _this = this;
        bins.forEach(function (bin, index) {
          bin.set('location', _this.get('shelf.location') + '-' + (index + 1 + '').padStart(2, '0'));
          bin.set('position', index + 1);
        });
      },
      addBin: function addBin() {
        this.get('store').createRecord('bin', {
          shelf: this.get('shelf')
        });
      },
      toggleDelete: function toggleDelete() {
        this.toggleProperty('delete');
      },
      destroyBin: function destroyBin(bin) {
        bin.destroyRecord();
        this.toggleProperty('delete');
      },
      enableSearch: function enableSearch(bin) {
        bin.toggleProperty('enableSearch');
        var _this = this;
        setTimeout(function () {
          _this.$('.bin-item-search').focus();
        }, 50);
      },
      setItem: function setItem() {
        var _this = this;
        this.get('shelf.bins').forEach(function (bin, ix) {
          if (bin.get('enableSearch') && !bin.get('item.id')) {
            var upc = bin.get('search');
            var filter = {};
            if (upc.length > 11) {
              filter['upc'] = upc;
            } else {
              filter['sku'] = upc;
            }
            _this.get('store').queryRecord('item', {
              filter: filter
            }).then(function (item) {
              bin.set('item', item);
              bin.set('enableSearch', false);
            });
          }
        });
      }
    }
  });
});