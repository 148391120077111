define('loftus/authenticators/jwt', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ajax = Ember.$.ajax;
  exports.default = _base.default.extend({
    test: 'true',
    tokenEndpoint: '/api/user_token',
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(creds) {
      var identification = creds.identification,
          password = creds.password;

      var data = JSON.stringify({
        auth: {
          email: identification,
          password: password
        }
      });
      var requestOptions = {
        url: this.tokenEndpoint,
        type: 'POST',
        data: data,
        contentType: 'application/json',
        dataType: 'json'
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajax(requestOptions).then(function (response) {
          var jwt = response.jwt;
          // Wrapping aync operation in Ember.run

          Ember.run(function () {
            resolve({
              token: jwt
            });
          });
        }, function (error) {
          // Wrapping aync operation in Ember.run
          Ember.run(function () {
            reject(error);
          });
        });
      });
    },
    invalidate: function invalidate(data) {
      return Ember.RSVP.Promise.resolve(data);
    }
  });
});