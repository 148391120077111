define('loftus/models/category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    header: _emberData.default.attr('string'),
    lft: _emberData.default.attr('number', { readOnly: true }),
    rgt: _emberData.default.attr('number', { readOnly: true }),
    depth: _emberData.default.attr('number', { readOnly: true }),
    childrenCount: _emberData.default.attr('number', { readOnly: true }),
    itemCount: _emberData.default.attr('number', { readOnly: true }),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    parent: _emberData.default.belongsTo('category', { inverse: 'children' }),
    children: _emberData.default.hasMany('category', { inverse: 'parent' }),
    forceDestroy: _emberData.default.attr('boolean'),
    tree: Ember.computed('parent.tree', 'name', function () {
      var tree = [this.get('parent.tree'), this.get('name')];
      var temp = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = tree[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var i = _step.value;

          i && temp.push(i);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return temp.join(' > ');
    }),
    categoryItems: _emberData.default.hasMany('category-item'),
    buttonText: Ember.computed('hasDirtyAttributes', 'saving', function () {
      if (this.get('saving')) {
        return 'Saving';
      } else if (this.get('hasDirtyAttributes')) {
        return 'Save';
      } else {
        return 'Saved';
      }
    })
  });
});