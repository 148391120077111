define('loftus/models/time-clock', ['exports', 'ember-data', 'moment', 'ember-api-actions'], function (exports, _emberData, _moment, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    inAt: _emberData.default.attr('date'),
    outAt: _emberData.default.attr('date'),
    clockIn: _emberData.default.attr('boolean'),
    clockOut: _emberData.default.attr('boolean'),
    periodEndsAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    tardy: _emberData.default.attr('boolean'),
    absent: _emberData.default.attr('boolean'),
    noNotice: _emberData.default.attr('boolean'),
    user: _emberData.default.belongsTo('user'),
    vacationHours: _emberData.default.attr('number'),
    holidayHours: _emberData.default.attr('number'),
    weekly: _emberData.default.attr('number', { readOnly: true }),
    hours: Ember.computed('inAt', 'outAt', function () {
      if (this.get('outAt')) {
        return Math.round((this.get('outAt') - this.get('inAt')) / 36000) / 100;
      }
      return Math.round((new Date() - this.get('inAt')) / 36000) / 100;
    }),
    momentIn: Ember.computed('inAt', function () {
      if (this.get('inAt')) {
        return (0, _moment.default)(this.get('inAt')).format('MM/DD/YY hh:mm:ss a');
      }
    }),
    momentOut: Ember.computed('inAt', function () {
      if (this.get('outAt')) {
        return (0, _moment.default)(this.get('outAt')).format('MM/DD/YY hh:mm:ss a');
      }
    }),
    emailReport: (0, _emberApiActions.collectionAction)({ path: 'email_report', type: 'get' })

  });
});