define('loftus/components/project-bin-locations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    speech: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.$('.bin-scan-input').focus();
      this.$('.mobile-title').html(this.get('record.projectBatch.project.name'));
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.mobile-title').html('');
    },

    actions: {
      validateScan: function validateScan() {
        if (this.get('record.scan') && this.get('record.scan').toUpperCase() == this.get('record.location')) {
          this.set('record.validated', true);
          this.set('record.model.keypadVisible', true);
        } else {
          this.get('speech').speak('no');
        }

        this.set('record.scan', null);
      },
      unscannable: function unscannable() {
        this.set('record.model.unscannable', true);
        this.set('record.validated', true);
        this.set('record.model.keypadVisible', true);
      },
      noBin: function noBin() {
        if (this.get('record.model.volume') * 1 > 0) {
          this.set('record.model.clearItem', true);
        }
        this.set('record.model.disabled', true);
        this.get('record.model').then(function (bin) {
          bin.save();
        });
        this.set('record.completedAt', new Date());
      },
      noStock: function noStock() {

        this.set('record.model.quantityCounted', this.get('record.model.keypadValue'));
        this.set('record.model.countedAt', new Date());
        this.set('record.model.countedBy', this.get('currentUser.user'));
        this.set('record.model.quantity', this.get('record.model.keypadValue'));
        this.set('record.model.lastMissingAt', null);

        if (this.get('record.model.volume') * 1 > 0) {
          this.set('record.model.clearItem', true);
        }
        this.get('record.model').then(function (bin) {
          bin.save();
        });
        this.set('record.completedAt', new Date());
      },
      picked: function picked() {
        var _this = this;
        var currentTime = new Date();
        this.get('record.model').then(function (item) {

          if (!item.get('lastCountedQuantity') || item.get('lastCountedQuantity') * 1 === 0) {
            item.set('keypadVisible', true);
          } else {
            item.inventory({ quantityCounted: item.get('lastCountedQuantity') }).then(function () {
              _this.set('record.completedAt', currentTime);
            });
          }
        });
      },
      keypadDigit: function keypadDigit(e) {

        var oi = this.get('record.model');
        var key = e.target.innerHTML * 1;
        var currentValue = oi.get('keypadValue');

        if (!currentValue || currentValue === '0' || currentValue === '') {
          oi.set('keypadValue', key);
        } else {

          var newValue = ('' + currentValue + key) * 1;
          oi.set('keypadValue', newValue);
          oi.set('lastCountedQuantity', newValue);
        }
      },

      keypadBack: function keypadBack() {
        var oi = this.get('record.model');
        var currentValue = oi.get('keypadValue');
        oi.set('keypadValue', Math.floor(currentValue / 10));
      },

      keypadCancel: function keypadCancel() {
        var oi = this.get('record.model');
        oi.set('keypadValue', 0);
        oi.set('lastCountedQuantity', 0);
        oi.set('keypadVisible', false);
      },

      keypadSubmit: function keypadSubmit() {
        console.log(this.get('record.model.keypadValue'));
        if (this.get('record.model.keypadValue') !== 0 && (!this.get('record.model.keypadValue') || this.get('record.model.keypadValue').length == 0)) {
          return;
        }
        this.set('record.model.keypadVisible', false);
        if (this.get('record.model.keypadValue') * 1 > 0) {
          this.set('record.model.quantityCounted', this.get('record.model.keypadValue'));
          this.set('record.model.countedAt', new Date());
          this.set('record.model.countedBy', this.get('currentUser.user'));
          this.set('record.model.quantity', this.get('record.model.keypadValue'));
          this.set('record.model.lastMissingAt', null);
          this.get('record.model').then(function (bin) {
            bin.save();
          });
          this.set('record.completedAt', new Date());
        } else {
          this.send('noStock');
        }
      }

    }
  });
});