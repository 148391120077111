define('loftus/components/item-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    itemNumber: '',
    results: [],
    didInsertElement: function didInsertElement() {
      $('input.ember-power-select-search-input').focus();
    },
    actions: {
      select: function select(item) {
        this.sendAction('toggle');
        this.sendAction('transition', 'item.edit', item.id);
      },
      searchAsync: function searchAsync(data) {
        if (data.length > 2) {
          return this.get('store').createRecord('item').autoComplete({ search: data });
        }
      },

      autoComplete: function autoComplete(event) {

        if (this.get('itemNumber').length < 3 || this.get('itemNumber') === this.get('lastSearch')) {
          return;
        }

        var _this = this;
        this.get('store').createRecord('item').autoComplete({ search: this.get('itemNumber') }).then(function (items) {
          _this.set('results', items);
          _this.set('lastSearch', _this.get('itemNumber'));
        });
        return true;
      },
      toggleSearch: function toggleSearch() {
        this.sendAction('toggle');
      },
      searchItems: function searchItems() {
        var _this = this;
        if (this.get('itemNumber') !== '') {
          this.get('store').queryRecord('item', { filter: { sku: this.get('itemNumber') } }).then(function (item) {
            _this.sendAction('transition', 'item.edit', item);
            _this.set('itemNumber', '');
          });
          // let _this = this;
          // this.get('store').findRecord('order',this.get('orderId')).then(function(order){
          //   _this.sendAction('transition','order.edit',order.get('id'));
          //   _this.set('orderNumber','');
          // });
        }
      }
    }
  });
});