define('loftus/controllers/tasks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    allTasks: Ember.computed(function () {
      return this.store.findAll('task');
    }),
    allUsers: Ember.computed(function () {
      return this.store.findAll('user');
    }),
    actions: {
      newTask: function newTask() {
        console.log('new task');
        var task = this.store.createRecord('task');
        task.save();
        this.set('task', task);
      },
      setTask: function setTask(task) {
        this.set('task', task);
      },
      saveTask: function saveTask() {
        this.get('task').save();
      }
    }
  });
});