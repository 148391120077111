define('loftus/models/sales-rep', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    rate: _emberData.default.attr('number'),
    repType: _emberData.default.attr('string'),
    customers: _emberData.default.hasMany('customer'),
    users: _emberData.default.hasMany('user'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    loadStats: (0, _emberApiActions.memberAction)({ path: 'stats', type: 'get' }),
    pdfReport: (0, _emberApiActions.memberAction)({ path: 'report', type: 'get' })
  });
});