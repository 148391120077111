define('loftus/controllers/project-batches', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      release: function release(batch) {
        var _this = this;

        console.log('test');
        batch.release().then(function (response) {
          _this.store.pushPayload(response);
        });
      }
    }
  });
});