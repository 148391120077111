define('loftus/components/manage-reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      picking: function picking() {
        var notify = this.get('notify');
        this.get('ajax').request('/api/reports/picking', { method: 'GET', data: { start: this.get('reportStart') } }).then(function (response) {
          if (response.notify && response.notify.info) {
            notify.info(response.notify.info);
          }
          window.open(urlFromBase64(response.pdf));
        });
      },
      packing: function packing() {
        var notify = this.get('notify');
        this.get('ajax').request('/api/reports/packing', { method: 'GET', data: { start: this.get('reportStart') } }).then(function (response) {
          if (response.notify && response.notify.info) {
            notify.info(response.notify.info);
            window.open(urlFromBase64(response.pdf));
          }
        });
      }
    }
  });
});