define('loftus/routes/customer/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var model = this.store.findRecord('customer', params.customer_id);
      return model;
    },

    titleToken: function titleToken(model) {
      return model.get('number') + ' - ' + model.get('company');
    }
  });
});