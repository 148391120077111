define('loftus/components/order-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    total: Ember.computed('orders.length', function () {
      if (!this.get('orders')) {
        return 0;
      }
      return this.get('orders').reduce(function (acc, value) {
        acc += value.total * 1;
        return acc;
      }, 0);
    }),
    subTotal: Ember.computed('orders.length', function () {
      if (!this.get('orders')) {
        return 0;
      }
      return this.get('orders').reduce(function (acc, value) {
        acc += value.subTotal * 1;
        return acc;
      }, 0);
    })
  });
});