define('loftus/models/import', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    softItemUpdate: (0, _emberApiActions.collectionAction)({ path: 'soft_items_update', type: 'post' }),
    hardItemUpdate: (0, _emberApiActions.collectionAction)({ path: 'hard_items_update', type: 'post' }),
    customerItems: (0, _emberApiActions.collectionAction)({ path: 'customer_items', type: 'post' }),
    catalogItems: (0, _emberApiActions.collectionAction)({ path: 'catalog_items', type: 'post' }),
    orderImport: (0, _emberApiActions.collectionAction)({ path: 'order_import', type: 'post' }),
    uploadFile: (0, _emberApiActions.collectionAction)({ path: 'upload_file', type: 'post' }),
    binsImport: (0, _emberApiActions.collectionAction)({ path: 'bins_import', type: 'post' })
  });
});