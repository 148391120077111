define('loftus/components/keypad-entry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      keypadDigit: function keypadDigit(digit) {
        var currentValue = this.get('keypadValue');

        console.log(currentValue);

        if (currentValue === 0 || currentValue === '0' || currentValue === '' || currentValue === null) {
          this.set('keypadValue', digit);
        } else {

          var new_value = ('' + currentValue + digit) * 1;
          if (this.get('max') > 0) {
            this.set('keypadValue', Math.min(new_value, this.get('max')));
          } else {
            this.set('keypadValue', new_value);
          }
        }
      },

      keypadBack: function keypadBack() {
        var currentValue = this.get('keypadValue');
        if (currentValue === 0) {
          this.set('keypadValue', '');
        } else {
          this.set('keypadValue', Math.floor(currentValue / 10));
        }
      }
    }

  });
});