define('loftus/components/measure-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    canScan: Ember.computed('item.caseQuantity', 'item.innerQuantity', 'item.caseInners', function () {
      console.log(this.get('item.caseQuantity.length'));
      console.log(this.get('item.innerQuantity.length'));
      console.log(this.get('item.caseInners.length'));
      console.log(this.get('item.caseQuantity'));
      console.log(this.get('item.innerQuantity'));
      console.log(this.get('item.caseInners'));

      if (typeof this.get('item.caseInners.length') !== 'undefined' && this.get('item.caseInners.length') === 0) {
        return false;
      }

      if (typeof this.get('item.innerQuantity.length') !== 'undefined' && this.get('item.innerQuantity.length') === 0) {
        return false;
      }

      if (this.get('item.innerQuantity') * 1 === 0 && this.get('item.caseInners') * 1 === 0) {
        return true;
      }

      if (this.get('item.caseQuantity') * 1 === this.get('item.innerQuantity') * this.get('item.caseInners')) {
        return true;
      }

      return false;
    }),
    selectedCubi: 'small',
    actions: {
      toggleCubi: function toggleCubi() {
        if (this.get('selectedCubi') === 'small') {
          this.set('selectedCubi', 'large');
        } else {
          this.set('selectedCubi', 'small');
        }
      },
      splitInners: function splitInners() {
        this.toggleProperty('doSplitInners');
      },
      cubiscanCase: function cubiscanCase() {
        var _this = this;
        if (this.get('item.hasDirtyAttributes')) {
          this.get('item').save();
        }
        this.get('item').cubiscan({ cubi: this.get('selectedCubi'), type: 'case' }).then(function (response) {
          _this.get('store').pushPayload(response);
        });
        this.$('.scan').focus();
      },
      cubiscanInner: function cubiscanInner() {
        var _this = this;
        if (this.get('item.hasDirtyAttributes')) {
          this.get('item').save();
        }
        this.get('item').cubiscan({ cubi: this.get('selectedCubi'), type: 'inner', split_inners: this.get('doSplitInners') }).then(function (response) {
          _this.get('store').pushPayload(response);
        });
        this.$('.scan').focus();
      },
      cubiscanUnit: function cubiscanUnit() {
        var _this = this;
        if (this.get('item.hasDirtyAttributes')) {
          this.get('item').save();
        }
        this.get('item').cubiscan({ cubi: this.get('selectedCubi'), type: 'unit' }).then(function (response) {
          _this.get('store').pushPayload(response);
        });
        this.$('.scan').focus();
      },
      weighUnit: function weighUnit() {
        var _this = this;
        if (this.get('record.model.hasDirtyAttributes')) {
          this.get('record.model').then(function (item) {
            item.save();
          });
        }
        this.get('record.model').then(function (item) {
          item.cubiscan({ type: 'weighUnit' }).then(function (response) {
            _this.get('store').pushPayload(response);
          });
        });
        this.$('.scan').focus();
      },
      applyToAll: function applyToAll() {
        var _this = this;
        this.get('item').cubiscan({ apply_to_price_code: true }).then(function (response) {
          _this.$('.scan').focus();
        });
      },
      loadItem: function loadItem() {
        var _this = this;
        if (this.get('scan').length > 0) {
          this.get('store').queryRecord('item', { filter: { scan: this.get('scan') } }).then(function (item) {
            _this.set('item', item);
          });
        }
        this.set('scan', null);
      }
    }
  });
});