define('loftus/components/edit-article', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    pages: Ember.computed(function () {
      return this.get('store').findAll('page');
    }),
    pageSort: ['tree'],
    pageOptions: Ember.computed.sort('pages', 'pageSort'),
    searchPage: function searchPage(page, term) {
      var words = term.split(' ');
      for (var i = 0; i < words.length; i++) {
        if (page.get('tree').toUpperCase().indexOf(words[i].toUpperCase()) === -1) {
          return -1;
        }
      }
      return 1;
    },
    statusOptions: ['published', 'future', 'draft', 'pending', 'private', 'trash', 'auto_draft'],
    actions: {
      save: function save() {
        this.get('article').save();
      },
      selectItem: function selectItem(item) {
        var _this = this;
        if (item) {
          this.get('store').findRecord('item', item.id).then(function (item) {
            _this.get('store').createRecord('article-item', {
              article: _this.get('article'),
              item: item
            }).save();
          });
        }
      },
      searchItem: function searchItem(data) {
        if (data.length > 2) {
          var limit = '';
          if (this.get('model.customer.limitSelection')) {
            limit = '&limit_selection=true&customer_id=' + this.get('model.customer.customer.id');
          }

          return this.get('ajax').request('/api/searches?model=item' + limit + '&q=' + encodeURIComponent(data));
        }
      },
      deleteArticleItem: function deleteArticleItem(ai) {
        ai.destroyRecord();
      }
    }
  });
});