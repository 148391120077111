define('loftus/models/authorizes', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    modelName: Ember.computed(function () {
      return this.get('constructor.modelName');
    }),
    route: Ember.computed('modelName', function () {
      return this.get('modelName') + 's.process';
    })

  });
});