define('loftus/components/sales-reps-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      pdfReport: function pdfReport() {
        var rep = this.get('salesRep');
        rep.pdfReport({ startDate: this.get('startDate'), endDate: this.get('endDate') }).then(function (response) {
          window.open(urlFromBase64(response.pdf));
        });
      }
    }

  });
});