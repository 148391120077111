define('loftus/routes/user/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      if (params.user_id === 'new') {
        return this.get('store').createRecord('user');
      } else {
        return this.get('store').findRecord('user', params.user_id);
      }
    }
  });
});