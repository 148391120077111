define('loftus/models/received-payment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    reference: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    paidOn: _emberData.default.attr('date'),
    receivedAt: _emberData.default.attr('date'),
    qbId: _emberData.default.attr('string'),
    qbSeq: _emberData.default.attr('string'),
    qbSyncedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    chain: _emberData.default.belongsTo('chain'),
    customer: _emberData.default.belongsTo('customer'),
    user: _emberData.default.belongsTo('user'),
    discounts: _emberData.default.attr('number', { readOnly: true }),
    overpayment: _emberData.default.attr('number', { readOnly: true }),
    customerReceivedPayments: _emberData.default.hasMany('customer-received-payment'),
    customerAppliedPayments: _emberData.default.hasMany('customer-applied-payment')
  });
});