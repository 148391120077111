define('loftus/models/route-point', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    sequence: _emberData.default.attr('number'),
    actions: _emberData.default.attr('', {}),
    expectedEntryAt: _emberData.default.attr('date'),
    expectedExitAt: _emberData.default.attr('date'),
    entryAt: _emberData.default.attr('date'),
    exitAt: _emberData.default.attr('date'),
    completedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    route: _emberData.default.belongsTo('route'),
    point: _emberData.default.belongsTo('point'),
    scale: Ember.computed.alias('point.scale'),
    entry: Ember.computed('expectedEntryAt', 'entryAt', 'route.timer', function () {
      if (!this.get('entryAt')) {
        var date = new Date();
        this.get('route.timer');
        var seconds = Math.max(0, Math.round((this.get('expectedEntryAt') - date) / 1000));
        var minutes = Math.floor(seconds / 60);
        return minutes + ':' + (seconds % 60 < 10 ? '0' : '') + seconds % 60;
      }
    }),
    x: Ember.computed.alias('point.svgX'),
    y: Ember.computed.alias('point.svgY'),
    xx: Ember.computed.alias('point.xx'),
    yy: Ember.computed.alias('point.yy'),
    timeY: Ember.computed('yy,route.bot.id', function () {
      var ys = (this.get('route.bot.id') - 1) * this.get('scale') * 9;
      return this.get('yy') + ys - this.get('scale') * 1;
    }),

    acts: Ember.computed('route.failure', 'actions', 'xx', 'yy', function () {

      var _this = this;

      if (!this.get('route.failure') && this.get('actions') && this.get('point.pointType') !== 'guidance') {
        var acts = this.get('actions').reduce(function (acc, value) {

          var xs = (value.sequence - 1) * _this.get('scale') * 16;
          var ys = (_this.get('route.bot.id') - 1) * _this.get('scale') * 9;

          if (value.action === 'stop') {
            acc.push({ icon: 'stop-circle-o', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
          } else if (value.action === 'raise') {
            acc.push({ icon: 'arrow-circle-o-up', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
          } else if (value.action === 'lower') {
            acc.push({ icon: 'arrow-circle-o-down', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
          } else if (value.action == 'forward') {
            if (value.degrees == 0) {
              acc.push({ icon: 'arrow-right', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 90) {
              acc.push({ icon: 'arrow-up', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 180) {
              acc.push({ icon: 'arrow-left', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 270) {
              acc.push({ icon: 'arrow-down', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            }
          } else if (value.action == 'back') {
            if (value.degrees == 0) {
              acc.push({ icon: 'arrow-left', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 90) {
              acc.push({ icon: 'arrow-down', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 180) {
              acc.push({ icon: 'arrow-right', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 270) {
              acc.push({ icon: 'arrow-up', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            }
          } else if (value.action == 'rotate') {
            if (value.degrees == 0) {
              acc.push({ icon: 'undo', iconClass: 'fa-rotate-180', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 90) {
              acc.push({ icon: 'undo', iconClass: 'fa-rotate-90', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 180) {
              acc.push({ icon: 'undo', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            } else if (value.degrees == 270) {
              acc.push({ icon: 'undo', iconClass: 'fa-rotate-270', style: 'color: ' + _this.get('route.bot.color') + '; position: absolute; top: ' + (_this.get('yy') + ys) + 'px; left: ' + (_this.get('xx') + xs) + 'px; font-size: ' + _this.get('scale') * 1 + 'em;' });
            }
          }
          return acc;
        }, []);

        // console.log(`JS Actions ${this.get('id')}`);
        // console.log(acts);

        return acts;
      }
    })

  });
});