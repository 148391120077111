define('loftus/controllers/purchase-order/receiving', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      printItemLabel: function printItemLabel() {
        var _this = this;

        var scan = this.get('scanData').toUpperCase();
        console.log('receiving scan');

        this.get('model').forEach(function (fs) {
          console.log(fs.id);
          console.log(fs.get('shipmentId'));
          if (fs.get('shipmentId') == scan) {
            _this.set('scanData', null);
            _this.get('router').transitionTo('freight-shipments.receive', fs.id);
            return;
          }
        });

        if (scan && scan.length < 5) {
          _this.set('scanData', null);
          _this.get('router').transitionTo('freight-shipments.receive', scan);
        }

        this.get('store').createRecord('purchase-order-item').printLabel({ scan: scan }).then(function () {
          _this.set('scanData', '');
        });
      }
    }
  });
});