define('loftus/routes/collections/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      if (params.collection_id === 'new') {
        return this.get('store').createRecord('collection');
      } else {
        return this.get('store').findRecord('collection', params.collection_id);
      }
    }
  });
});