define('loftus/controllers/order/pick', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    speech: Ember.inject.service(),
    sortedOrderItems: Ember.computed.sort('model.orderItems', 'sortPicking'),
    sortPicking: ['location', 'item.sku'],
    unpickedItems: Ember.computed.filter('sortedOrderItems', function (item) {
      return !item.get('pickedAt');
    }),
    actions: {
      readText: function readText(oi) {
        var card = this.$('.picking-cards__card:visible').first();
        var scan = card.find('.scanned-item');
        if (!scan.hasClass('spoken')) {
          this.get('speech').speak(oi.get('location').split(/(\d+)([A-Z])?(\d)+/).join(' '));
          this.get('speech').speak('Pick ' + oi.get('quantityRemaining'));
        }
        scan.addClass('spoken');
      },

      keypadDigit: function keypadDigit(oi, e) {
        var key = e.target.innerHTML * 1;
        var currentValue = oi.get('keypadValue');

        var input = this.$(e.target).closest('.keypad').find('.keypad-input');
        var max = input.attr('max') * 1;

        if (currentValue === '0' || currentValue === '') {
          oi.set('keypadValue', key);
        } else {

          var new_value = ('' + currentValue + key) * 1;
          if (max > 0) {
            oi.set('keypadValue', Math.min(new_value * 1, max));
          } else {
            oi.set('keypadValue', new_value);
          }
        }
      },

      keypadBack: function keypadBack(oi) {
        var currentValue = oi.get('keypadValue');
        oi.set('keypadValue', Math.floor(currentValue / 10));
      },

      keypadCancel: function keypadCancel(oi, e) {
        this.$(e.target).closest('.keypad').hide();
      },

      keypadSubmit: function keypadSubmit(oi) {
        this.send('hideKeypad');
        this.send('submitPick', oi);
      },

      splitPick: function splitPick(oi) {
        this.get('speech').speak('split');
        oi.set('keypadValue', 0);
        var card = this.$('.picking-cards__card:visible').first();
        var scannedItem = card.find('.scanned-item');
        var scannedTote = card.find('.scanned-tote');
        if (scannedItem.val() === '') {
          scannedItem.focus();
        } else {
          scannedTote.focus();
        }
      },
      unscannable: function unscannable(oi) {
        oi.set('unscannable', true);
        var card = this.$('.picking-cards__card:visible').first();
        card.find('.scanned-tote').focus();
      },
      shortPick: function shortPick(oi) {
        var currentTime = new Date();
        this.get('speech').speak('short');
        var bo = oi.get('quantityRemaining') + oi.get('quantityBackordered');
        oi.set('quantityBackordered', bo);
        oi.set('backorderedAt', currentTime);
        var card = this.$('.picking-cards__card:visible').first();
        oi.save().then(function () {
          card.hide();
          card.next().find('.scanned-item').focus();
        });
      },
      showKeypad: function showKeypad() {
        var card = this.$('.picking-cards__card:visible').first();
        card.find('.keypad').show();
      },
      hideKeypad: function hideKeypad() {
        var card = this.$('.picking-cards__card:visible').first();
        card.find('.keypad').hide();
      },

      submitPick: function submitPick(oi) {
        var item = oi.get('item');
        var upc = item.get('upc');
        var caseCode = item.get('caseCode');
        var card = this.$('.picking-cards__card:visible').first();
        var scannedItem = card.find('.scanned-item');
        var scannedTote = card.find('.scanned-tote');

        if (scannedItem.val() !== upc && scannedItem.val() !== caseCode && !oi.get('unscannable')) {
          this.get('speech').speak('No');
          scannedItem.val('').focus();
          return;
        }

        if (isNaN(scannedTote.val().toUpperCase().replace('TOTE', '') * 1) || scannedTote.val().toUpperCase().replace('TOTE', '') * 1 === 0) {
          scannedTote.val('').focus();
          return;
        }

        var keypadValue = card.find('.keypad input').val() * 1;

        if (keypadValue === 0 && oi.get('quantityRemaining') > 0) {
          this.send('showKeypad');
          return;
        }

        if (scannedTote.val().toUpperCase().replace('TOTE', '') * 1 > 0) {

          var currentTime = new Date();

          var pickedItem = this.store.createRecord('picked-item');
          pickedItem.set('pickingToteId', scannedTote.val().toUpperCase().replace('TOTE', '') * 1);
          pickedItem.set('pickedAt', currentTime);
          pickedItem.set('createdAt', currentTime);
          pickedItem.set('updatedAt', currentTime);
          pickedItem.set('quantity', oi.get('keypadValue'));
          if (oi.get('unscannable')) {
            pickedItem.set('unscanned', true);
          }
          oi.get('pickedItems').addObject(pickedItem);

          Ember.RSVP.all(oi.get('pickedItems').invoke('save')).then(function () {

            oi.reload().then(function () {
              if (oi.get('unpickedQuantity') === 0) {
                card.hide();
                card.next().find('.scanned-item').focus();
              } else {
                scannedItem.val('').focus();
                scannedTote.val('');
                oi.set('keypadValue', oi.get('unpickedQuantity'));
              }
            });
          });

          //        card.hide();
          //card.next().find('.scanned-item').focus();
        } else {
          scannedTote.val('').focus();
          return;
        }
      }
    }
  });
});