define('loftus/models/event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    preorderStartsAt: _emberData.default.attr('date'),
    preorderEndsAt: _emberData.default.attr('date'),
    processingStartsAt: _emberData.default.attr('date'),
    shippingStartsAt: _emberData.default.attr('date'),
    shippingEndsAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    quotes: _emberData.default.hasMany('quote'),
    orders: _emberData.default.hasMany('order'),
    items: _emberData.default.hasMany('item')
  });
});