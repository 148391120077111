define("loftus/controllers/receiving-carts", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    statusOptions: ["receiving", "full", "moving", "stocking", "empty"],
    actions: {
      addCart: function addCart() {
        this.get('store').createRecord('receiving-cart');
      },
      saveCart: function saveCart(cart) {
        cart.save();
      }
    }
  });
});