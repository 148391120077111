define('loftus/models/catalog-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    packaged: _emberData.default.attr('boolean'),
    floatWarning: _emberData.default.attr('boolean'),
    holographic: _emberData.default.attr('boolean'),
    colorblast: _emberData.default.attr('boolean'),
    mightyBright: _emberData.default.attr('boolean'),
    prismatic: _emberData.default.attr('boolean'),
    heliumSaver: _emberData.default.attr('boolean'),
    valueLine: _emberData.default.attr('boolean'),
    brand: _emberData.default.attr('string'),
    sizeDescription: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    catalogLayout: _emberData.default.attr('string'),
    item: _emberData.default.belongsTo('item')
  });
});