define('loftus/controllers/projects', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    allUsers: Ember.computed(function () {
      return this.store.findAll('user');
    }),
    actions: {
      getRemaining: function getRemaining(project) {
        project.getRemaining().then(function (rem) {
          project.set('remainingCount', rem.count);
        });
        // project.save();
      }
    }
  });
});