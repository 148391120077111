define('loftus/models/page-block', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    blockType: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    imageUrl: _emberData.default.attr('string'),
    route: _emberData.default.attr('string'),
    routeData: _emberData.default.attr('string'),
    anchor: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    justify: _emberData.default.attr('number'),
    htmlClass: _emberData.default.attr('string'),
    html: _emberData.default.attr('string'),
    color: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    page: _emberData.default.belongsTo('page'),
    active: _emberData.default.attr('boolean'),
    startAt: _emberData.default.attr('date'),
    endAt: _emberData.default.attr('date'),
    parentBlock: _emberData.default.belongsTo('page-block', { inverse: 'pageBlocks' }),
    pageBlocks: _emberData.default.hasMany('page-block', { inverse: 'parentBlock' }),
    blockSort: ['position'],
    blocks: Ember.computed.sort('pageBlocks', 'blockSort'),
    slides: Ember.computed('justify', function () {
      return this.get('justify') || 1;
    })

  });
});