define('loftus/controllers/seasons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedStations: Ember.computed.sort('model', 'sortOrder'),
    sortOrder: ['name'],
    userOptions: Ember.computed(function () {
      return this.get('store').findAll('user');
    }),
    actions: {
      save: function save(season) {
        season.save();
      },
      destroy: function destroy(season) {
        season.destroyRecord();
      },
      newSeason: function newSeason() {
        this.get('store').createRecord('season');
      }

    }
  });
});