define('loftus/components/hc-basic', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ajax: Ember.inject.service(),
        pieChart: Ember.computed(function () {

            return {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: this.get('title'),
                    align: 'left'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br/>Revenue: <b>${point.y}</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: this.get('seriesName'),
                    colorByPoint: true,
                    data: this.get('data')
                }]
            };
        }),
        chartOptions: Ember.computed('format', function () {
            var data = this.get(this.get('format'));
            console.log(data);
            return data;
        }),
        columnChart: Ember.computed(function () {
            return {
                chart: {
                    type: 'column'
                },
                title: {
                    text: this.get('title'),
                    align: 'left'
                },
                // subtitle: {
                //     text:
                //         'Source: <a target="_blank" ' +
                //         'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
                //     align: 'left'
                // },
                xAxis: {
                    categories: this.get('categories'),
                    crosshair: true
                    // accessibility: {
                    //     description: 'Countries'
                    // }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: this.get('yAxisTitle') || this.get('chart.yAxisTitle')
                    }
                },
                tooltip: {
                    valueSuffix: this.get('tooltipFormat')
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: this.get('chart.series')
            };
        }),
        multiAxis: Ember.computed(function () {
            return {
                chart: {
                    zoomType: 'xy'
                },
                title: {
                    text: this.get('title'),
                    align: 'left'
                },
                xAxis: {
                    categories: this.get('categories'),
                    crosshair: true
                },
                yAxis: this.get('yAxis'),
                tooltip: {
                    valueSuffix: this.get('tooltipFormat')
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: this.get('chart.series')
            };
        })

    });
});