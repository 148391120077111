define('loftus/models/work-order', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    startedAt: _emberData.default.attr('date'),
    completedAt: _emberData.default.attr('date'),
    completed: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    workOrderItems: _emberData.default.hasMany('work-order-item'),
    addSku: (0, _emberApiActions.memberAction)({ path: 'add_sku', type: 'put' }),
    pdf: (0, _emberApiActions.memberAction)({ path: 'pdf', type: 'get' })
  });
});