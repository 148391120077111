define('loftus/routes/project/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').query('user', { scope: ['active', 'with_projects'] });
    }
  });
});