define('loftus/components/email-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    defaultName: Ember.computed(function () {
      return this.get('emailData.name');
    }),
    defaultEmail: Ember.computed(function () {
      return this.get('emailData.email');
    }),
    defaultSubject: Ember.computed(function () {
      return this.get('emailData.subject');
    }),
    defaultBody: Ember.computed(function () {
      return this.get('emailData.body');
    }),
    options: Ember.computed(function () {
      return this.get('contactOptions');
    }),
    disableEmail: Ember.computed('email', 'defaultEmail', function () {
      return !/(.+)@(.+){2,}\.(.+){2,}/.test(this.get('email') || this.get('defaultEmail'));
    }),
    actions: {
      sendEmail: function sendEmail() {
        var data = { name: this.get('name') || this.get('defaultName'),
          email: this.get('email') || this.get('defaultEmail'),
          subject: this.get('subject') || this.get('defaultSubject'),
          body: this.get('body') || this.get('defaultBody') };
        this.get('sendAction')(data);
      },
      open: function open() {
        this.get('openAction')();
      },
      openExcel: function openExcel() {
        this.get('excelAction')();
      },
      print: function print() {
        this.get('printAction')();
      },
      setContact: function setContact(contact) {
        this.set('selectedContact', contact);
        this.set('name', contact.get('name'));
        this.set('email', contact.get('email'));
      }
    }

  });
});