define('loftus/routes/settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),
        model: function model() {
            return this.get('ajax').request('/api/settings');
        }
    });
});