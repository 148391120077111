define('loftus/components/manage-work-orders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      createWorkOrder: function createWorkOrder() {
        this.get('store').createRecord('work-order').save();
      }
    }
  });
});