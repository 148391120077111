define('loftus/services/speech-reader', ['exports', 'ember-speak/services/speech-reader'], function (exports, _speechReader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _speechReader.default;
    }
  });
});