define('loftus/models/sub-journal', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    uvId: _emberData.default.attr('string'),
    glCode: _emberData.default.attr('string'),
    journalNumber: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    enteredAt: _emberData.default.attr('date'),
    referenceNumber: _emberData.default.attr('string'),
    entryType: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    order: _emberData.default.belongsTo('order'),
    customer: _emberData.default.belongsTo('customer')
  });
});