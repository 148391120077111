define('loftus/components/queue-order', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.$('.keypad-input').focus();
    },
    sortedOrderItems: Ember.computed.sort('model.orderItems', 'sortPicking'),
    sortPicking: ['item.sku'],
    keypadValue: '',
    actions: {
      keypadDigit: function keypadDigit(e) {
        var key = e.target.innerHTML * 1;
        var currentValue = this.get('keypadValue');

        var input = this.$(e.target).closest('.keypad').find('.keypad-input');
        var max = input.attr('max') * 1;

        if (currentValue === '0' || currentValue === '') {
          this.set('keypadValue', key);
        } else {

          var new_value = ('' + currentValue + key) * 1;
          if (max > 0) {
            this.set('keypadValue', Math.min(new_value * 1, max));
          } else {
            this.set('keypadValue', new_value);
          }
        }
      },

      keypadBack: function keypadBack() {
        var currentValue = this.get('keypadValue');
        this.set('keypadValue', Math.floor(currentValue / 10));
      },

      keypadCancel: function keypadCancel() {
        this.set('keypadVisible', false);
        this.set('createIssue', false);
      },

      keypadSubmit: function keypadSubmit() {
        var _this2 = this;

        console.log(this.get('keypadValue'));
        if (!this.get('keypadValue') || this.get('keypadValue') === '') {
          this.get('notify').info('No Order Selected');
        } else {
          var _this = this;
          this.get('store').findRecord('order', this.get('keypadValue') * 1).then(function (order) {
            order.queue().then(function (response) {
              console.log('queued');
              console.log(response);
              _this.set('keypadValue', null);
              _this2.get('router').transitionTo('project.work');
            });
          });
        }
      }
    }
  });
});