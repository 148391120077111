define('loftus/models/stocking-issue', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    userId: _emberData.default.attr('number', { readOnly: true }),
    issueType: _emberData.default.attr('number'),
    resolvedUserId: _emberData.default.attr('number'),
    resolvedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    receivedItem: _emberData.default.belongsTo('received-item')
  });
});