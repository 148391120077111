define('loftus/models/box', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    boxNumber: _emberData.default.attr('number'),
    carton: _emberData.default.belongsTo('carton'),
    pallet: _emberData.default.belongsTo('pallet'),
    weight: _emberData.default.attr('number'),
    trackingNumber: _emberData.default.attr('string'),
    imageFormat: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    shippingMethodId: _emberData.default.attr('number'),
    shippingMethod: _emberData.default.attr('string', { readOnly: true }),
    contents: _emberData.default.hasMany('box-content'),
    sortedContents: Ember.computed.sort('contents', 'sortItemSku'),
    sortItemSku: ['pickedItem.orderItem.item.sku'],
    transactionId: _emberData.default.attr('string'),
    charge: _emberData.default.attr('number'),
    nowShipped: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    palletizedAt: _emberData.default.attr('date'),
    labelScannedAt: _emberData.default.attr('date'),
    shipmentPaired: _emberData.default.attr('boolean', { readOnly: true }),
    shipment: _emberData.default.belongsTo('shipment'),
    length: Ember.computed('carton.length', function () {
      return this.get('carton.length');
    }),
    width: Ember.computed('carton.width', function () {
      return this.get('carton.width');
    }),
    height: Ember.computed('carton.height', function () {
      return this.get('carton.height');
    }),
    order: Ember.computed('shipment', function () {
      return this.get('shipment.order');
    }),
    dims: Ember.computed('carton.length', 'carton.width', 'carton.height', function () {
      if (this.get('carton.length') && this.get('carton.width') && this.get('carton.height')) {
        return this.get('carton.length') + 'x' + this.get('carton.width') + 'x' + this.get('carton.height');
      }
    }),
    dimWeight: Ember.computed('carton.length', 'carton.width', 'carton.height', function () {
      if (this.get('carton.length') && this.get('carton.width') && this.get('carton.height')) {
        return Math.ceil(this.get('carton.length') * this.get('carton.width') * this.get('carton.height') / 166);
      }
    }),
    hasDims: Ember.computed('carton.length', 'carton.width', 'carton.height', 'weight', function () {
      if (this.get('carton') && this.get('carton.length') > 0 && this.get('carton.width') > 0 && this.get('carton.height') > 0 && this.get('weight') > 0) {
        return true;
      } else {
        return false;
      }
    }),
    hasTrackingNumber: Ember.computed('trackingNumber', function () {
      return this.get('trackingNumber.length') > 0;
    }),
    hasImage: Ember.computed('trackingNumber', function () {
      return this.get('image.length') > 0;
    }),
    userId: _emberData.default.attr('number'),
    printTag: (0, _emberApiActions.memberAction)({ path: 'print_tag', type: 'put' }),
    cubiscan: (0, _emberApiActions.memberAction)({ path: 'cubiscan', type: 'put' }),
    scan: (0, _emberApiActions.collectionAction)({ path: 'scan', type: 'put' })

  });
});