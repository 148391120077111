define('loftus/components/quotes-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.send('initDataTable');
    },
    checkIcon: Ember.computed('quotes.@each.selected', function () {
      console.log(this.get('quotes').filterBy('selected', true).length);
      if (this.get('quotes').filterBy('selected', true).length > 0) {
        return 'check-square-o';
      } else {
        return 'square-o';
      }
    }),
    actions: {
      initDataTable: function initDataTable() {
        if (this.$('table:visible tbody tr').length === 0) {
          var _this = this;
          setTimeout(function () {
            _this.send('initDataTable');
          }, 1000);
        }
        setTimeout(function () {
          var table = Ember.$('table:visible');
          if (!table.hasClass('dataTable')) {
            Ember.$('table:visible').dataTable({
              dom: 'Bfrtip',
              buttons: ['copyHtml5', 'excelHtml5', 'csvHtml5', 'pdfHtml5'],
              order: [[1, 'desc']] });
          }
        }, 15);
      },
      invoiceSelected: function invoiceSelected() {

        var ids = this.get('quotes').filterBy('selected', true).filterBy('invoiceDisabled', false).reduce(function (acc, value) {
          acc.push(value.get('id'));
          return acc;
        }, []);

        if (ids.length > 0) {
          this.get('quotes.firstObject').invoiceSelected({ quote_ids: ids });
        }
      }
    }
  });
});