define('loftus/routes/catalog-items', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'loftus/config/environment'], function (exports, _authenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    model: function model() {
      return this.get('ajax').request(_environment.default.host + '/catalog_items?q=table');
    }
  });
});