define('loftus/components/project-pod-picking', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    speech: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this.get('store').findAll('pod-pick').then(function (picks) {
        _this.set('model', picks);
      });
    }
  });
});