define('loftus/controllers/order-payments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      transition: function transition(route, id) {
        this.send('transitionTo', route, id);
      },
      voidPayment: function voidPayment(payment) {
        var _this = this;
        payment.void().then(function (response) {
          _this.get('store').pushPayload(response);
        });
      },
      capturePayment: function capturePayment(payment) {
        var _this = this;
        payment.capture().then(function (response) {
          if (response && response.error) {
            alert(response.error);
          } else {
            _this.get('store').pushPayload(response);
          }
        });
      }
    }
  });
});