define('loftus/routes/order/entries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('ajax').request('/api/orders/entries');
    },
    ajax: Ember.inject.service()
  });
});