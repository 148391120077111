define('loftus/models/bin', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    zone: _emberData.default.attr('number'),
    aisle: _emberData.default.attr('string'),
    side: _emberData.default.attr('number'),
    section: _emberData.default.attr('string'),
    sectionShelf: _emberData.default.attr('number'),
    pickSection: _emberData.default.attr('number', { readOnly: true }),
    position: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    depth: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    volume: _emberData.default.attr('number'),
    maxDim: _emberData.default.attr('number'),
    midDim: _emberData.default.attr('number'),
    minDim: _emberData.default.attr('number'),
    pod: _emberData.default.belongsTo('pod'),
    code: _emberData.default.attr('string', { readOnly: true }),
    bestLocation: Ember.computed('code', 'location', function () {
      return this.get('code') || this.get('location');
    }),
    status: _emberData.default.attr('string'),
    clearItem: _emberData.default.attr('boolean'),
    attemptClear: _emberData.default.attr('boolean'),
    deleteWhenEmpty: _emberData.default.attr('boolean'),
    removeItem: _emberData.default.attr('boolean'),
    disabled: _emberData.default.attr('boolean'),
    countedAt: _emberData.default.attr('date'),
    countedBy: _emberData.default.belongsTo('user'),
    quantityCounted: _emberData.default.attr('number'),
    unscannable: _emberData.default.attr('boolean'),
    restockDirectly: _emberData.default.attr('boolean'),
    highVelocity: _emberData.default.attr('boolean'),
    categoryId: _emberData.default.attr('number'),
    nearbyBins: _emberData.default.attr('', { readOnly: true }),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    item: _emberData.default.belongsTo('item'),
    productType: _emberData.default.belongsTo('product-type'),
    vendor: _emberData.default.belongsTo('vendor'),
    zoneId: _emberData.default.attr('number'),
    podId: _emberData.default.attr('number'),
    binType: Ember.computed('categoryId', function () {
      if (this.get('categoryId') === 1) {
        return 'overstock';
      } else if (this.get('categoryId') === 2) {
        return 'mixed';
      } else if (this.get('categoryId') === 3) {
        return 'pod-single';
      } else if (this.get('categoryId') === 4) {
        return 'pod-case';
      } else {
        return 'standard';
      }
    }),
    fixed: Ember.computed('categoryId', function () {
      return (this.get('categoryId') || 1) < 3;
    }),
    stockingSort: Ember.computed('categoryId', function () {
      console.log('Stocking Sort: ' + this.get('item.sku') + ' - ' + this.get('location') + ' - ' + this.get('categoryId') + ' ' + ((this.get('categoryId') || 0) > 1 ? 0 : 1));
      return (this.get('categoryId') || 0) > 0 ? 1 : 0;
    }),
    standard: Ember.computed('categoryId', function () {
      // console.log(this.get('location'));
      // console.log(this.get('categoryId'));
      // console.log(this.get('categoryId') === 2);
      // console.log(`${this.get('location')}: ${!this.get('categoryId') || this.get('categoryId') === 0 || this.get('categoryId') === 2}`);
      return !this.get('categoryId') || this.get('categoryId') === 0 || this.get('categoryId') === 2;
    }),
    overstock: Ember.computed('categoryId', function () {
      return this.get('categoryId') === 1 || this.get('categoryId') === 2;
    }),
    bin: Ember.computed(function () {
      return this;
    }),
    canReceive: Ember.computed('deleteWhenEmpty', 'removeItem', 'disabled', function () {
      return !this.get('deleteWhenEmpty') && !this.get('disabled') && !this.get('removeItem');
    }),
    lastMissingAt: _emberData.default.attr('date'),
    lastMissingBy: _emberData.default.belongsTo('user'),
    quantityWhenStocked: _emberData.default.attr('number'),
    stockedAt: _emberData.default.attr('date'),
    sku: Ember.computed.alias('item.sku'),
    shelf: _emberData.default.belongsTo('shelf'),
    receivingBin: _emberData.default.belongsTo('receiving-bin'),
    projectRecordId: _emberData.default.attr('number'),
    printLabel: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' }),
    faceStation: (0, _emberApiActions.memberAction)({ path: 'face_station', type: 'put' }),
    valid: true
  });
});