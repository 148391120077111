define('loftus/models/item-alert', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    notifiedAt: _emberData.default.attr('date'),
    notifiedBy: _emberData.default.belongsTo('user'),
    quantity: _emberData.default.attr('number'),
    quantityBackordered: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    orderedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    orderId: _emberData.default.attr('number'),
    order: _emberData.default.belongsTo('order', { readOnly: true }),
    customer: _emberData.default.belongsTo('customer', { readOnly: true }),
    contact: _emberData.default.belongsTo('contact', { readOnly: true }),
    customerNumber: _emberData.default.attr('string', { readOnly: true }),
    company: _emberData.default.attr('string', { readOnly: true }),
    salesRep: _emberData.default.attr('string', { readOnly: true }),
    customerId: _emberData.default.attr('number', { readOnly: true })
  });
});