define('loftus/controllers/shelves', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedShelves: Ember.computed.sort('unmeasured', 'sortOrder'),
    sortOrder: ['location:desc'],
    unmeasured: Ember.computed.filter('model.@each.width', function (shelf) {
      return !shelf.get('width') || shelf.get('width') === 0 || !shelf.get('depth') || shelf.get('depth') === 0 || shelf.get('hasDirtyAttributes');
    }),
    appSearch: Ember.computed(function () {
      return 'Test';
    }),

    actions: {
      setKeypadTarget: function setKeypadTarget(shelf, target) {
        shelf.set('keypadTarget', target);
        shelf.set('keypadValue', shelf.get(shelf.get('keypadTarget')) || 0);
      },
      keypadEntry: function keypadEntry(shelf, size) {
        var dims = size.split('x');
        shelf.set('width', dims[0]);
        shelf.set('depth', dims[1]);
        shelf.set('keypadValue', dims[0]);
        shelf.set('keypadTarget', 'width');
      },
      keypadDigit: function keypadDigit(shelf, e) {
        var key = e.target.innerHTML * 1;
        var currentValue = shelf.get('keypadValue');

        var input = $(e.target).closest('.keypad').find('.keypad-input');
        var max = input.attr('max') * 1;

        if (currentValue === '0' || currentValue === '') {
          shelf.set('keypadValue', key);
        } else {

          var new_value = ('' + currentValue + key) * 1;
          if (max > 0) {
            shelf.set('keypadValue', Math.min(new_value * 1, max));
          } else {
            shelf.set('keypadValue', new_value);
          }
        }
        shelf.set(shelf.get('keypadTarget'), shelf.get('keypadValue'));
      },

      keypadBack: function keypadBack(shelf) {
        var currentValue = shelf.get('keypadValue');
        if (currentValue === 0) {
          shelf.set('keypadValue', '');
        } else {
          shelf.set('keypadValue', Math.floor(currentValue / 10));
        }
        shelf.set(shelf.get('keypadTarget'), shelf.get('keypadValue'));
      },

      keypadCancel: function keypadCancel(shelf) {
        shelf.set('width', '');
        shelf.set('depth', '');
        shelf.set('keypadValue', '');
        shelf.set(shelf.get('keypadTarget'), shelf.get('keypadValue'));
      },

      keypadSubmit: function keypadSubmit(shelf) {
        var context = this;
        shelf.save().then(function () {
          context.store.findAll('shelf');
        });
      }
    }
  });
});