define('loftus/components/project-measure-case-packs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortedShelves: Ember.computed.sort('unmeasured', 'sortOrder'),
    sortOrder: ['location'],
    unmeasured: Ember.computed.filter('model.@each.length', function (item) {
      return !item.get('length') || item.get('length') === 0 || !item.get('width') || item.get('width') === 0 || item.get('hasDirtyAttributes');
    }),
    didInsertElement: function didInsertElement() {
      $('input[autofocus]').focus();
      $('.mobile-title').html(this.get('record.projectBatch.project.name'));
    },
    willDestroyElement: function willDestroyElement() {
      $('.mobile-title').html('');
    },

    actions: {
      setKeypadTarget: function setKeypadTarget(item, target) {
        item.set('keypadTarget', target);
        item.set('keypadValue', item.get(item.get('keypadTarget')) || 0);
      },
      keypadEntry: function keypadEntry(item, size) {
        var dims = size.split('x');
        item.set('length', dims[0]);
        item.set('width', dims[1]);
        item.set('keypadValue', dims[0]);
        item.set('keypadTarget', 'length');
      },
      keypadDigit: function keypadDigit(item, e) {
        var key = e.target.innerHTML * 1;
        var currentValue = item.get('keypadValue');

        var input = $(e.target).closest('.keypad').find('.keypad-input');
        var max = input.attr('max') * 1;

        if (currentValue === '0' || currentValue === '') {
          item.set('keypadValue', key);
        } else {

          var new_value = ('' + currentValue + key) * 1;
          if (max > 0) {
            item.set('keypadValue', Math.min(new_value * 1, max));
          } else {
            item.set('keypadValue', new_value);
          }
        }
        item.set(item.get('keypadTarget'), item.get('keypadValue'));
      },

      keypadBack: function keypadBack(item) {
        var currentValue = item.get('keypadValue');
        if (currentValue === 0) {
          item.set('keypadValue', '');
        } else {
          item.set('keypadValue', Math.floor(currentValue / 10));
        }
        item.set(item.get('keypadTarget'), item.get('keypadValue'));
      },

      keypadCancel: function keypadCancel(item) {
        item.set('length', '');
        item.set('width', '');
        item.set('keypadValue', '');
        item.set(item.get('keypadTarget'), item.get('keypadValue'));
      },

      keypadSubmit: function keypadSubmit(record) {
        record.store.peekRecord(record.get('recordable.modelName'), record.get('recordable.id')).save().then(function () {
          record.set('completedAt', new Date());
        });
      }
    }
  });
});