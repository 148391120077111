define('loftus/models/customer-imprint', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    cutNumber: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    balloons: _emberData.default.attr('string'),
    ink: _emberData.default.attr('string'),
    lastUsedOn: _emberData.default.attr('date'),
    src: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    customer: _emberData.default.belongsTo('customer')
  });
});