define('loftus/models/customer-item', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    customer: _emberData.default.belongsTo('customer'),
    item: _emberData.default.belongsTo('item'),
    itemId: _emberData.default.attr('number'),
    itemType: _emberData.default.attr('string'),
    priceType: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    listPrice: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    barcode: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    printLabels: (0, _emberApiActions.memberAction)({ path: 'print_labels', type: 'put' }),
    itemTypes: ['discount', 'supply', 'cogs', 'label', 'permit', 'deny'],
    priceTypes: ['force', 'best']
  });
});