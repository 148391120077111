define('loftus/models/product-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    htsCode: _emberData.default.attr('string'),
    depth: _emberData.default.attr('number', { readOnly: true }),
    childrenCount: _emberData.default.attr('number', { readOnly: true }),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    parent: _emberData.default.belongsTo('product-type', { inverse: 'children' }),
    children: _emberData.default.hasMany('product-type', { inverse: 'parent' }),
    productTypeProperties: _emberData.default.hasMany('product-type-property'),
    properties: _emberData.default.hasMany('property'),
    tree: Ember.computed('parent.tree', 'name', function () {
      var tree = [this.get('parent.tree'), this.get('name')];
      var temp = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = tree[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var i = _step.value;

          i && temp.push(i);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return temp.join(' > ');
    })

  });
});