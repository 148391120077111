define('loftus/routes/order/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var model = this.store.findRecord('order', params.order_id);
      return model;
    },

    titleToken: function titleToken(model) {
      return model.get('id') + ' - ' + model.get('customer.number') + ' - ' + model.get('customer.company');
    }
  });
});