define('loftus/components/item-labels', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    speech: Ember.inject.service(),
    printerOptions: Ember.computed(function () {
      return this.get('store').query('printer', { filter: { format: 'zpl' } });
    }),
    actions: {
      printLabels: function printLabels(format) {
        var _this = this;
        this.get('model').printLabels({ quantity: this.get('printQuantity'), format: format, printer_id: this.get('printer.id') }).then(function (response) {
          _this.get('speech').speak('Printed');
        });
      },
      saveItem: function saveItem() {
        this.get('groupItem').save();
        this.set('groupItem', null);
        this.$('.scan-field').focus();
      },
      searchProductGroup: function searchProductGroup() {
        var _this = this;

        if (this.get('groupItem') && this.get('scan') == this.get('groupItem.upc')) {
          this.get('groupItem').printLabels({ quantity: 1, format: 'show' });
          this.set('groupItem', null);
          this.set('scan', null);
          this.$('.scan-field').focus();
        } else {

          this.set('groupItem', null);
          this.get('store').queryRecord('item', { filter: { upc: this.get('scan') } }).then(function (item) {
            _this.set('model', item);
            _this.set('scan', '');
            if (item.get('productGroup.id')) {
              // _this.get('speech').speak(item.get('productGroup.id'));
            } else if (!item.get('location') || item.get('caseCode')) {
              setTimeout(function () {
                _this.$('.location input').focus();
              }, 50);
            } else if (!item.get('caseCode')) {
              setTimeout(function () {
                _this.$('.case-code input').focus();
              }, 50);
            }
          });
        }
      }
    }
  });
});