define('loftus/models/price-code', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    wholesale: _emberData.default.attr('number'),
    preferred: _emberData.default.attr('number'),
    premium: _emberData.default.attr('number'),
    grocery: _emberData.default.attr('number'),
    partner: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    vendor: _emberData.default.belongsTo('vendor'),
    itemsCount: _emberData.default.attr('number', { readOnly: true }),
    errorsCount: _emberData.default.attr('number', { readOnly: true })
  });
});