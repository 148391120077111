define('loftus/components/list-contacts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      newContact: function newContact() {
        console.log(this.get('vendor'));
        var contact = this.get('store').createRecord('contact', {
          user: this.get('currentUser.user'),
          customer: this.get('customer'),
          vendor: this.get('vendor'),
          updatedAt: new Date()
        });
        this.set('modalContact', contact);
      },
      clearModalContact: function clearModalContact() {
        this.set('modalContact', null);
      },
      deleteContact: function deleteContact(contact) {
        contact.destroyRecord();
      },
      setTextConversation: function setTextConversation(contact) {
        contact.set('customer.textContact', contact);
      }

    }

  });
});