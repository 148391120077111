define('loftus/routes/quote/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var model = this.store.findRecord('quote', params.quote_id);
      return model;
    },

    titleToken: function titleToken(model) {
      return 'Quote ' + model.get('id') + ' - ' + model.get('customer.company');
    }
  });
});