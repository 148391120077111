define('loftus/components/purchase-order-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    purchaseOrderNumber: '',
    didInsertElement: function didInsertElement() {
      $('input[autofocus]').focus();
    },
    actions: {
      searchPurchaseOrders: function searchPurchaseOrders() {
        console.log('search');
        if (this.get('purchaseOrderNumber') !== '') {
          var _this = this;
          if (this.get('purchaseOrderNumber') !== '') {
            console.log(this.get('purchaseOrderNumber'));
            this.get('store').queryRecord('purchase-order', { filter: { number: this.get('purchaseOrderNumber') } }).then(function (purchaseOrder) {
              console.log(purchaseOrder.get('id'));
              _this.sendAction('transition', 'purchase-order.edit', purchaseOrder);
              _this.set('purchaseOrderNumber', '');
            });
          }
        }
      }
    }
  });
});