define('loftus/models/shipping-service', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    carrier: _emberData.default.attr('string'),
    expedited: _emberData.default.attr('boolean'),
    guaranteed: _emberData.default.attr('boolean'),
    trackingUrl: _emberData.default.attr('string'),
    apiName: _emberData.default.attr('string'),
    apiCode: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    shipments: _emberData.default.hasMany('shipment'),
    restricted: _emberData.default.attr('boolean')

  });
});