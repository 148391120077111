define('loftus/models/purchase-order-item', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    sku: _emberData.default.attr('string'),
    uvId: _emberData.default.attr('string'),
    noteSort: Ember.computed('sku', function () {
      return this.get('sku') === '' ? 1 : 0;
    }),
    title: _emberData.default.attr('string'),
    reorderNumber: _emberData.default.attr('string'),
    unit: _emberData.default.attr('string'),
    unitCost: _emberData.default.attr('number'),
    quantityOrdered: _emberData.default.attr('number'),
    quantityReceived: _emberData.default.attr('number'),
    quantityReceivedToDate: _emberData.default.attr('number'),
    extCost: _emberData.default.attr('number'),
    status: _emberData.default.attr('number'),
    eta: _emberData.default.attr('string'),
    receivedOn: _emberData.default.attr('date'),
    receivedBy: _emberData.default.attr('string'),
    receivedEvents: _emberData.default.hasMany('received-event'),
    position: _emberData.default.attr('number'),
    comments: _emberData.default.attr('string'),
    displayComments: Ember.computed('comments', function () {
      if (this.get('comments')) {
        return this.get('comments').split("\n").map(function (item) {
          return item.trim();
        });
      }
    }),
    innerPrice: Ember.computed('item.wholesale', 'innerQuantity', 'item.innerQuantity', function () {
      if (this.get('item.wholesale') && this.get('innerQty')) {
        return this.get('item.wholesale') * this.get('innerQty');
      }
    }),
    casePrice: Ember.computed('item.wholesale', 'caseQuantity', 'item.caseQuantity', function () {
      if (this.get('item.wholesale') && this.get('caseQty')) {
        return this.get('item.wholesale') * this.get('caseQty');
      }
    }),
    innerLabelConfirmedAt: _emberData.default.attr('date'),
    caseLabelConfirmedAt: _emberData.default.attr('date'),
    innerLabelConfirmed: Ember.computed('innerLabelConfirmedAt', function () {
      return !!this.get('innerLabelConfirmedAt');
    }),
    caseLabelConfirmed: Ember.computed('caseLabelConfirmedAt', function () {
      return !!this.get('caseLabelConfirmedAt');
    }),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    details: _emberData.default.attr('', { readOnly: true }),
    purchaseOrder: _emberData.default.belongsTo('purchase-order'),
    item: _emberData.default.belongsTo('item'),
    scan: _emberData.default.attr('string'),
    vendor: _emberData.default.attr('number'),
    freight: _emberData.default.attr('number'),
    receivedItems: _emberData.default.hasMany('received-item'),
    freightItems: _emberData.default.hasMany('freight-item'),
    printTag: (0, _emberApiActions.memberAction)({ path: 'print_tag', type: 'put' }),
    printLabel: (0, _emberApiActions.collectionAction)({ path: 'print_label', type: 'put' }),
    innerQty: Ember.computed('innerQuantity', 'item.innerQuantity', function () {
      return this.get('innerQuantity') || this.get('item.innerQuantity');
    }),
    innerQuantity: _emberData.default.attr('number'),
    caseQty: Ember.computed('caseQuantity', 'item.caseQuantity', function () {
      return this.get('caseQuantity') || this.get('item.caseQuantity');
    }),
    caseQuantity: _emberData.default.attr('number'),
    innerPdf: (0, _emberApiActions.memberAction)({ path: 'inner_pdf', type: 'get' }),
    casePdf: (0, _emberApiActions.memberAction)({ path: 'case_pdf', type: 'get' }),
    keypadValue: Ember.computed('quantityRemaining', function () {
      return this.get('item.caseQuantity') || 0;
    }),
    receipts: Ember.computed.filter('receivedItems.@each.toteId', function (ri) {
      return ri.get('toteId') === this.get('toteInput').toUpperCase().replace('TOTE', '') * 1;
    }),
    previousReceipt: Ember.computed('receipts', function () {
      return this.get('receipts.firstObject');
    }),
    poiIssues: _emberData.default.hasMany('purchase-order-issue'),
    unscannable: false,
    unstocked: Ember.computed.mapBy('receivedItems', 'quantityUnstocked'),
    quantityUnstocked: Ember.computed.sum('unstocked'),
    quantityRemaining: Ember.computed('quantityReceived', 'quantityUnstocked', function () {
      return this.get('quantityReceived') - this.get('quantityUnstocked');
    }),
    remaining: Ember.computed('quantityOrdered', 'quantityReceivedToDate', function () {
      return this.get('quantityOrdered') - (this.get('quantityReceivedToDate') || 0);
    }),
    keypadVisible: false,
    detailsVisible: Ember.computed('keypadVisible', 'createIssue', function () {
      return !this.get('keypadVisible') && !this.get('createIssue');
    }),
    keypadPlaceholder: Ember.computed('enteringCaseQty', 'receivingIssue', function () {
      if (this.get('enteringCaseQty')) {
        return 'Enter Case Quantity';
      } else if (this.get('receivingIssue') !== '') {
        return this.get('receivingIssue') + ' Quantity';
      } else {
        return 'Enter Quantity';
      }
    }),
    createIssue: false,
    receivingIssue: '',
    toteInput: '',
    receiveMultiple: Ember.computed('quantityReceived', 'item.receivingMultiple', function () {
      return this.get('quantityReceived') * (this.get('item.receivingMultiple') || 1);
    }),
    scanClass: Ember.computed(function () {
      return 'scanned-tote-' + this.get('id');
    }),
    keypadOrCase: Ember.computed('keypadVisible', 'requireCaseQuantity', function () {
      return this.get('keypadVisible') || this.get('requireCaseQuantity');
    }),
    maxQuantity: Ember.computed('receivingIssue', 'quantityRemaining', function () {
      if (this.get('receivingIssue') === 'Overage') {
        return null;
      } else {
        return this.get('quantityRemaining');
      }
    }),
    requireCaseQuantity: Ember.computed('item.caseQuantity', function () {
      if (this.get('item.caseQuantity')) {
        return false;
      } else {
        return true;
      }
    }),
    visible: Ember.computed('purchaseOrder.searchInput', 'purchaseOrder.showRemaining', 'reorderNumber', function () {
      var search = this.get('purchaseOrder.searchInput').toUpperCase();
      var showRemaining = this.get('purchaseOrder.showRemaining');
      console.log(search);
      console.log(this.get('reorderNumber'));
      console.log(search === this.get('reorderNumber'));
      return this.get('quantityRemaining') > 0 && (search !== '' && (search === this.get('item.sku') || search === this.get('item.upc')) || search == this.get('reorderNumber') || showRemaining);
    }),
    ecExtPrice: Ember.observer('quantityOrdered', 'unitCost', function () {
      this.set('extCost', Math.round(this.get('quantityOrdered') * this.get('unitCost') * 100) / 100);
    })
  });
});