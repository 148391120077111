define('loftus/controllers/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    sortedStations: Ember.computed.sort('model', 'sortOrder'),
    sortOrder: ['name'],
    actions: {
      save: function save(setting) {
        console.log(setting);
        this.get('ajax').post('api/settings', { data: { id: setting.id, value: setting.value, value_type: setting.type } });
      }
    }
  });
});