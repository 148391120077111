define('loftus/routes/item-adjustments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').query('item-adjustment', { filter: { reason: 'count_adjustment' }, scope: ['unapproved', 'this_fiscal_year'] });
    }
  });
});