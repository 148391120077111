define('loftus/components/new-order', ['exports', 'ember-keyboard-shortcuts/mixins/component', 'loftus/config/environment', 'moment'], function (exports, _component, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.default, {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    scrollObserver: Ember.observer('model.orderItems', function () {
      console.log('scroll');
      window.scrollTo(0, document.body.scrollHeight);
    }),
    tabs: [{ name: 'General', id: 'general' }, { name: 'Shipment', id: 'shipment' }, { name: 'Items', id: 'order-items' }, { name: 'Payments', id: 'order-payments' }],
    activeTab: 'general',
    loadedTabs: ['general'],
    loadedTabObserver: Ember.observer('activeTab', 'loadedTabs', function () {
      switch (this.get('activeTab')) {
        case 'purchase-history':
          this.set('loadPurchaseHistory', true);
          break;
        case 'order-history':
          if (!$('.recent-orders th:first').hasClass('init-load')) {
            $('.recent-orders th:first').click();
            $('.recent-orders th:first').addClass('init-load');
          }
          break;
        case 'apply-payments':
          if (!$('.billed-orders th:first').hasClass('init-load')) {
            $('.billed-orders th:first').click();
            $('.billed-orders th:first').addClass('init-load');
          }
          break;
      }
    }),

    actions: {
      toggleNote: function toggleNote(oi) {
        oi.set('showNote', !oi.get('showNote'));
      },
      addSku: function addSku() {
        var qty = 1;
        var sku = this.get('searchSku').toUpperCase();
        if (sku.indexOf('*') > -1) {
          qty = sku.slice(0, sku.indexOf('*')) * 1;
          sku = sku.slice(sku.indexOf('*') + 1);
        } else if (sku * 1 + '' === sku) {
          this.set('model.orderItems.lastObject.quantity', sku * 1);
          this.set('searchSku', '');
          return;
        } else if (sku === '-') {
          this.set('model.orderItems.lastObject.order', null);
          this.set('searchSku', '');
          return;
        }

        if (sku[2] !== '-') {
          sku = sku.slice(0, 2) + '-' + sku.slice(2);
        }

        var _this = this;

        this.get('store').queryRecord('item', { filter: { sku: sku } }).then(function (item) {
          _this.send('addItem', item, qty);
        });

        this.set('searchSku', '');
      },
      select: function select(result) {
        if (result && result.id && result.route) {
          var _this = this;
          this.get('store').findRecord('customer', result.id).then(function (customer) {
            _this.set('model.customer', customer);
            _this.set('model.terms', customer.get('terms'));
          });
        }
      },
      selectAddress: function selectAddress(address) {

        if (address) {
          var shipment = this.get('model.shipment');
          shipment.set('company', address.get('company'));
          shipment.set('address1', address.get('address1'));
          shipment.set('address2', address.get('address2'));
          shipment.set('city', address.get('city'));
          shipment.set('state', address.get('state'));
          shipment.set('zip', address.get('zip'));
          shipment.set('country', address.get('country'));
          this.set('model.shipTo', address);
        } else {
          this.set('model.shipTo', undefined);
        }
      },
      searchCustomer: function searchCustomer(data) {

        if (data.length > 2) {
          return this.get('ajax').request(_environment.default.host + '/searches?model=customer&q=' + encodeURIComponent(data));
        }
      },
      addItem: function addItem(item, qty) {

        this.set('model.itemEntryNotice', null);

        if (this.get('model.orderItems').filterBy('item.id', item.id).length > 0) {
          this.set('model.itemEntryNotice', item.get('sku') + ' - ' + item.get('title') + ' - already on order.');
          var _this = this;
          setTimeout(function () {
            _this.set('model.itemEntryNotice', null);
          }, 3000);
        } else if (item.get('discontinuedAt')) {
          this.set('model.itemEntryNotice', item.get('sku') + ' - ' + item.get('title') + ' discontinued ' + (0, _moment.default)(item.get('discontinuedAt')).format('MM/DD/YYYY'));
          var _this2 = this;
          setTimeout(function () {
            _this2.set('model.itemEntryNotice', null);
          }, 3000);
        } else if (this.get('model.customer.limitSelection') && this.get('model.customer.permittedIds').indexOf(item.get('id') * 1) < 0) {
          this.set('model.itemEntryNotice', item.get('sku') + ' - ' + item.get('title') + ' is restricted for this customer');
          var _this3 = this;
          setTimeout(function () {
            _this3.set('model.itemEntryNotice', null);
          }, 3000);
        } else {
          var orderItem = this.get('store').createRecord('order-item', { item: item,
            quantity: qty,
            quantityBackordered: 0,
            title: item.get('title'),
            price: 0 });
          this.get('model.orderItems').addObject(orderItem);
        }
      },
      selectItem: function selectItem(item) {
        var _this = this;
        if (item) {
          this.get('store').findRecord('item', item.id).then(function (item) {
            _this.send('addItem', item, 1);
          });
        }
      },
      searchItem: function searchItem(data) {
        if (data.length > 2) {
          var limit = '';
          if (this.get('model.customer.limitSelection')) {
            console.log('Limit Selection');
            limit = '&limit_selection=true&customer_id=' + this.get('model.customer.customer.id');
          }

          return this.get('ajax').request(_environment.default.host + '/searches?model=item' + limit + '&q=' + encodeURIComponent(data));
        }
      },
      deleteOrderItem: function deleteOrderItem(oi) {
        oi.set('order', null);
      },
      focusTab: function focusTab(activeTab) {
        this.set('activeTab', activeTab.id);
        var loaded = this.get('loadedTabs');
        loaded.push(activeTab.id);
        this.set('loadedTabs', loaded);
        if (activeTab.action) {
          this.send(activeTab.action);
        }
      },
      toggle: function toggle(field) {
        this.set('model.' + field, !this.get('model.' + field));
      },
      setItemModal: function setItemModal(itemId) {
        var _this = this;
        this.get('store').findRecord('item', itemId).then(function (item) {
          _this.set('model.modalItem', item);
        });
      },
      removeModalItem: function removeModalItem() {
        this.set('model.modalItem', null);
      },
      setCustomer: function setCustomer() {
        var _this = this;
        this.get('store').findRecord('customer', 7).then(function (customer) {
          _this.set('model.customer', customer);
        });
      }
    },
    sortedOrderItems: Ember.computed.sort('model.orderItems', 'sortPicking'),
    sortPicking: ['item.sku'],
    keyboardShortcuts: {
      'ctrl+s': {
        action: 'save'
      },
      'ctrl+1': function ctrl1() {
        this.send('focusTab', this.get('tabs')[0]);
        return false;
      },
      'ctrl+2': function ctrl2() {
        this.send('focusTab', this.get('tabs')[2 - 1]);
        return false;
      },
      'ctrl+3': function ctrl3() {
        this.send('focusTab', this.get('tabs')[3 - 1]);
        return false;
      },
      'ctrl+4': function ctrl4() {
        this.send('focusTab', this.get('tabs')[4 - 1]);
        return false;
      },
      'ctrl+5': function ctrl5() {
        this.send('focusTab', this.get('tabs')[5 - 1]);
        return false;
      },
      'ctrl+6': function ctrl6() {
        this.send('focusTab', this.get('tabs')[6 - 1]);
        return false;
      },
      'ctrl+7': function ctrl7() {
        this.send('focusTab', this.get('tabs')[7 - 1]);
        return false;
      },
      'ctrl+8': function ctrl8() {
        this.send('focusTab', this.get('tabs')[8 - 1]);
        return false;
      },
      'ctrl+9': function ctrl9() {
        this.send('focusTab', this.get('tabs')[9 - 1]);
        return false;
      },
      'ctrl+0': function ctrl0() {
        this.send('focusTab', this.get('tabs')[10 - 1]);
        return false;
      },
      'ctrl+m': function ctrlM() {
        this.toggleProperty('overridePrices');
        return false;
      },
      'esc': {
        action: 'removeModalItem'
      }

    }
  });
});