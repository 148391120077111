define('loftus/components/call-dashboard', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        inbound: Ember.computed('user.cdr', function () {
            return this.get('user.cdr').filterBy('direction', 'inbound');
        }),
        outbound: Ember.computed('user.cdr', function () {
            return this.get('user.cdr').filterBy('direction', 'outbound');
        }),
        inboundDuration: Ember.computed('user.cdr', function () {
            return this.get('inbound').reduce(function (acc, value) {
                acc += value.duration || 0;
                return acc;
            }, 0);
        }),
        outboundDuration: Ember.computed('user.cdr', function () {
            return this.get('outbound').reduce(function (acc, value) {
                acc += value.duration || 0;
                return acc;
            }, 0);
        }),
        totalDuration: Ember.computed('user.cdr', function () {
            return this.get('user.cdr').reduce(function (acc, value) {
                acc += value.duration || 0;
                return acc;
            }, 0);
        }),
        callCountChart: Ember.computed('user.cdr', function () {
            return {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: 'Call Distribution'
                },
                tooltip: {
                    pointFormat: '<b>{point.y} calls</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                }
            };
        }),
        callCountData: Ember.computed('user.cdr', function () {
            return [{
                name: 'Distribution',
                colorByPoint: true,
                data: [{
                    name: 'Inbound',
                    y: this.get('inbound.length')
                }, {
                    name: 'Outbound',
                    y: this.get('outbound.length')
                }]
            }];
        }),
        callDurationChart: Ember.computed('user.cdr', function () {
            return {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: 'Call Duration'
                },
                tooltip: {
                    formatter: function formatter() {
                        if (this.y > 3600) {
                            return '<b>' + this.key + '</b><br>' + Math.floor(this.y / 3600) + ' hours ' + Math.floor(this.y % 3600 / 60) + ' minutes ' + this.y % 60 + ' seconds';
                        } else {
                            return '<b>' + this.key + '</b><br>' + Math.floor(this.y / 60) + ' minutes ' + this.y % 60 + ' seconds';
                        }
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                }
            };
        }),
        callDurationData: Ember.computed('user.cdr', function () {
            return [{
                name: 'Duration',
                colorByPoint: true,
                data: [{
                    name: 'Inbound',
                    y: this.get('inboundDuration')
                }, {
                    name: 'Outbound',
                    y: this.get('outboundDuration')
                }]
            }];
        })

    });
});