define('loftus/components/project-clear-bins', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    speech: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      clearBin: function clearBin() {
        var _this = this;
        this.get('record.model').then(function (bin) {
          bin.set('item', null);
          bin.save().then(function (saved) {
            _this.set('record.completedAt', new Date());
          });
        });
      },
      skipBin: function skipBin() {
        var _this = this;
        this.get('record.model').then(function (bin) {
          bin.set('removeItem', null);
          bin.get('item').then(function (item) {
            item.set('requireCountAt', new Date());
            item.save();
          });
          bin.save().then(function (saved) {
            _this.set('record.completedAt', new Date());
          });
        });
      }
    }
  });
});