define('loftus/models/ship-to', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    company: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    contact: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    orderCount: _emberData.default.attr('number', { readOnly: true }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    validatedAt: _emberData.default.attr('date'),
    customer: _emberData.default.belongsTo('customer'),
    isDefault: Ember.computed('id', 'customer.defaultShipToId', function () {
      return this.get('id') * 1 === this.get('customer.defaultShipToId') * 1;
    })
  });
});