define('loftus/models/catalog', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    release: _emberData.default.attr('string'),
    year: _emberData.default.attr('string'),
    catalogType: _emberData.default.attr('string'),
    dynamic: _emberData.default.attr({}),
    slug: _emberData.default.attr('string'),
    priceList: _emberData.default.attr('boolean'),
    partner: _emberData.default.attr('boolean'),
    grocery: _emberData.default.attr('boolean'),
    affiliatePrice: _emberData.default.attr('boolean'),
    salePrice: _emberData.default.attr('boolean'),
    specialPrice: _emberData.default.attr('boolean'),
    generatePdfs: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    buttonText: Ember.computed('catalog.hasDirtyAttributes', function () {
      if (this.get('catalog.hasDirtyAttributes')) {
        return 'Save Catalog';
      } else {
        return 'Saved';
      }
    })

  });
});