define('loftus/controllers/events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedStations: Ember.computed.sort('model', 'sortOrder'),
    sortOrder: ['name'],
    userOptions: Ember.computed(function () {
      return this.get('store').findAll('user');
    }),
    actions: {
      save: function save(event) {
        event.save();
      },
      newEvent: function newEvent() {
        this.store.createRecord('event');
      }
    }
  });
});