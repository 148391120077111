define('loftus/models/task', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    frequency: _emberData.default.attr('string'),
    priority: _emberData.default.attr('number'),
    hoursBetween: _emberData.default.attr('number'),
    expectedTime: _emberData.default.attr('number'),
    parts: _emberData.default.attr('', {}),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    userTasks: _emberData.default.hasMany('user-task'),
    users: _emberData.default.hasMany('user'),
    notifyOnComplete: _emberData.default.attr('boolean')
  });
});