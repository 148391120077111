define('loftus/models/printer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    pageSize: _emberData.default.attr('string'),
    connection: _emberData.default.attr('string'),
    format: _emberData.default.attr('string'),
    topic: _emberData.default.attr('string'),
    host: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    port: _emberData.default.attr('number'),
    ssh: _emberData.default.attr('string'),
    localPort: _emberData.default.attr('number'),
    remoteAddress: _emberData.default.attr('string'),
    remotePort: _emberData.default.attr('number'),
    hardwareAddress: _emberData.default.attr('string'),
    hardwarePath: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    outputType: _emberData.default.attr('string'),
    station: _emberData.default.belongsTo('station')
  });
});