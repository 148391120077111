define('loftus/models/chain', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    freeFreight: _emberData.default.attr('boolean'),
    noveltyFreeFreight: _emberData.default.attr('boolean'),
    discountPercent: _emberData.default.attr('number'),
    requireInners: _emberData.default.attr('boolean'),
    restrictItems: _emberData.default.attr('boolean'),
    domains: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    customers: _emberData.default.hasMany('customer'),
    contact: _emberData.default.belongsTo('contact'),
    page: _emberData.default.belongsTo('page'),
    paymentTokens: _emberData.default.hasMany('payment-token')
  });
});