define('loftus/models/purchase-order-issue', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    issueType: _emberData.default.attr('string'),
    resolvedUserId: _emberData.default.attr('number'),
    notifiedAt: _emberData.default.attr('date'),
    resolvedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    purchaseOrderItem: _emberData.default.belongsTo('purchase-order-item'),
    user: _emberData.default.belongsTo('user'),
    freightItem: _emberData.default.belongsTo('freight-item')
  });
});