define('loftus/models/assortment-pick', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    quantityPicked: _emberData.default.attr('number'),
    pickedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    assortment: _emberData.default.belongsTo('assortment'),
    user: _emberData.default.belongsTo('user'),
    toteId: _emberData.default.attr('number'),
    location: Ember.computed.alias('assortment.assortedItem.location'),
    projectRecordId: _emberData.default.attr('number'),
    maxQuantity: Ember.computed('quantity', function () {
      return Math.max(this.get('quantity'), 10000);
    }),
    keypadValue: Ember.computed('quantity', function () {
      return this.get('quantity');
    }),
    unshortedBins: Ember.computed.filterBy('bins', 'lastMissingAt', null),
    bins: Ember.computed.alias('assortment.assortedItem.standardBins'),
    bin: Ember.computed('assortment.assortedItem.standardBins.@each.quantity', 'assortment.assortedItem.standardBins.@each.lastMissingAt', 'assortment.assortedItem.overstockBins.@each.quantity', 'assortment.assortedItem.overstockBins.@each.lastMissingAt', function () {
      console.log(this.get('id'));
      console.log(this.get('assortment.id'));
      console.log(this.get('assortment.assortedItem.id'));
      console.log(this.get('assortment.assortedItem.sku'));
      console.log(this.get('bins.length'));
      console.log(this.get('bins'));
      var _this = this;
      console.log('Recalculate bin for ' + this.get('assortment.assortedItem.sku'));
      return this.get('bins').sort(function (a, b) {
        console.log(a);
        console.log(b);
        if (b.get('lastMissingAt') && !a.get('lastMissingAt')) {
          return -1;
        }
        if (a.get('lastMissingAt') && !b.get('lastMissingAt')) {
          return 1;
        }

        if (!_this.get('cases')) {
          if ((a.get('quantity') || 0) < 2 && (b.get('quantity') || 0) > 1) {
            return -1;
          }
          if ((a.get('quantity') || 0) > 1 && (b.get('quantity') || 0) < 2) {
            return 1;
          }
        }

        if (a.get('pickSection') < b.get('pickSection')) {
          return -1;
        }
        if (a.get('pickSection') > b.get('pickSection')) {
          return 1;
        }
        if (a.get('location.length') < b.get('location.length')) {
          return -1;
        }
        if (a.get('location.length') > b.get('location.length')) {
          return 1;
        }
        if (a.get('location') < b.get('location')) {
          return -1;
        }
        if (a.get('location') > b.get('location')) {
          return 1;
        }

        return 0;
      })[0];
    })

  });
});