define('loftus/models/faq', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    topic: _emberData.default.attr('string'),
    question: _emberData.default.attr('string'),
    answer: _emberData.default.attr('string'),
    faqId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    position: _emberData.default.attr('number'),
    faqs: _emberData.default.hasMany('faq', { inverse: 'faq' }),
    faq: _emberData.default.belongsTo('faq', { inverse: 'faqs' }),
    faqSort: ['position'],
    sortedFaqs: Ember.computed.sort('faqs', 'faqSort')
  });
});