define('loftus/models/point', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('number'),
    x: _emberData.default.attr('number'),
    y: _emberData.default.attr('number'),
    z: _emberData.default.attr('number'),
    pointType: _emberData.default.attr('string'),
    points: _emberData.default.hasMany('point', { inverse: 'path' }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    zone: _emberData.default.belongsTo('zone'),
    // bot: DS.belongsTo('bot', {inverse: 'point'}),
    // pod: DS.belongsTo('pod', {inverse: 'point'}),
    path: _emberData.default.belongsTo('point'),
    pathType: _emberData.default.attr('string'),
    distanceToPath: _emberData.default.attr('number'),
    code: _emberData.default.attr('string'),
    maxSpeed: _emberData.default.attr('number'),
    loadedMaxSpeed: _emberData.default.attr('number'),
    loadedUp: _emberData.default.attr('number'),
    loadedDown: _emberData.default.attr('number'),
    loadedLeft: _emberData.default.attr('number'),
    loadedRight: _emberData.default.attr('number'),
    lidarUp: _emberData.default.attr('number'),
    lidarDown: _emberData.default.attr('number'),
    lidarLeft: _emberData.default.attr('number'),
    lidarRight: _emberData.default.attr('number'),
    lidarChannel: _emberData.default.attr('number'),
    loadedLidarChannel: _emberData.default.attr('number'),
    actions: _emberData.default.attr('', {}),
    getItems: (0, _emberApiActions.memberAction)({ path: 'get_items', type: 'get' }),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' }),
    px: Ember.computed.alias('zone.px'),
    scale: Ember.computed.alias('zone.scale'),
    labelSize: Ember.computed('zone.scale', function () {
      return this.get('zone.scale') * 8;
    }),
    glowSize: Ember.computed('zone.scale', function () {
      return this.get('zone.scale') * 24;
    }),
    guide: Ember.computed('x', 'y', function () {
      if (1 * this.get('x') % 2 === 0 || 1 * this.get('y') % 2 === 0) {
        return true;
      } else {
        return false;
      }
    }),
    font: Ember.computed('scale', function () {
      return this.get('scale') * 0.5;
    }),
    xx: Ember.computed('z', 'x', function () {
      if (this.get('z') < 6) {
        var x = this.get('scale') * 16;
        return this.get('x') * this.get('px') - 44 * this.get('scale') + x;
      }
    }),
    yy: Ember.computed('z', 'y', function () {
      if (this.get('z') < 6) {
        var y = this.get('scale') * 64;
        return this.get('y') * this.get('px') - 44 * this.get('scale') + y;
      }
    }),
    svgX: Ember.computed('z', 'x', function () {
      if (this.get('z') < 6) {
        var x = this.get('scale') * 16;
        return this.get('x') * this.get('px') + x;
      }
    }),
    svgY: Ember.computed('z', 'y', function () {
      if (this.get('z') < 6) {
        var y = this.get('scale') * 64;
        return this.get('y') * this.get('px') + y;
      }
    }),
    glowX: Ember.computed('z,x', function () {
      if (this.get('z') < 6) {
        var x = this.get('scale') * 16;
        return this.get('x') * this.get('px') + x - 3;
      }
    }),
    glowY: Ember.computed('z,y', function () {
      if (this.get('z') < 6) {
        var y = this.get('scale') * 64;
        return this.get('y') * this.get('px') + y - 3;
      }
    }),
    labelX: Ember.computed('svgX', function () {
      if (this.get('x') < 10) {
        return this.get('svgX') - this.get('scale') * 7;
      } else {
        return this.get('svgX') - this.get('scale') * 10;
      }
    }),
    labelY: Ember.computed('svgX', function () {
      return this.get('svgY') + this.get('scale') * 16;
    }),
    // botX: computed('bot.id','z','x',function(){
    //   if (this.get('z') < 6) {
    //     let x = 8;
    //     return x + 2 + (this.get('x') * this.get('px'));
    //   }
    // }),
    // botY: computed('bot.id','z','y',function(){
    //   if (this.get('z') < 6) {
    //     let y = 32;
    //     return y + 2 + (this.get('y') * this.get('px'));
    //   }
    // }),
    // botTextX: computed('bot.id','z','x',function(){
    //   if (this.get('z') < 6) {
    //     let x = 8;
    //     return x - 4 + (this.get('x') * this.get('px'));
    //   }
    // }),
    // botTextY: computed('bot.id','z','y',function(){
    //   if (this.get('z') < 6) {
    //     let y = 32;
    //     return y + 6 + (this.get('y') * this.get('px'));
    //   }
    // }),
    // botCodeX: computed('bot.id','z','x',function(){
    //   if (this.get('z') < 6) {
    //     let x = 8;
    //     return x + 7 + (this.get('x') * this.get('px'));
    //   }
    // }),
    // botCodeY: computed('bot.id','z','y',function(){
    //   if (this.get('z') < 6) {
    //     let y = 32;
    //     return y - 7 + (this.get('y') * this.get('px'));
    //   }
    // }),   
    // podX: computed('pod.id','z','x',function(){
    //   if (this.get('z') < 6) {
    //     let x = 8;
    //     return (x + this.get('x') * this.get('px')) - 22;
    //   }
    // }),
    // podY: computed('pod.id','z','y',function(){
    //   if (this.get('z') < 6) {
    //     let y = 32;
    //     return (y + this.get('y') * this.get('px')) - 22;
    //   }

    // }),
    // podTextX: computed('pod.id','z','x',function(){
    //   if (this.get('z') < 6) {
    //     let x = 8;
    //     return (x + this.get('x') * this.get('px')) - 20;
    //   }
    // }),
    // podTextY: computed('pod.id','z','y',function(){
    //   if (this.get('z') < 6) {
    //     let y = 32;
    //     return (y + this.get('y') * this.get('px')) - 16;
    //   }
    // }),
    style: Ember.computed('pointType', 'pod.id', function () {
      return 'fill:white;stroke:' + this.get('color') + ';stroke-width:1;';
    }),
    color: Ember.computed('pointType', 'pod.id', function () {
      if (this.get('pointType') === 'guidance') {
        return '#ddd';
      } else if (this.get('pointType') === 'path') {
        return '#e6e63e';
      } else if (this.get('pointType') === 'station') {
        return '#0000ff';
      } else if (this.get('pointType') === 'receiving') {
        return '#ff1493';
      } else if (this.get('pointType') === 'queue') {
        return '#ff8c00';
      } else if (this.get('pointType') === 'station_exit' || this.get('pointType') === 'station_return') {
        return '#db8534';
      } else if (this.get('pointType') === 'staging') {
        return '#800080';
      } else if (this.get('pointType') === 'disabled') {
        return '#fafafa';
      } else if (this.get('pointType') === 'charge') {
        return '#000000';
      } else {
        if (this.get('pod.id')) {
          return '#FF0000';
        } else {
          return '#009400';
        }
      }
    }),
    pathArrow: Ember.computed('pointType', 'pathType', function () {
      if (this.get('pathType') == 'minus_y') {
        return this.get('svgX') + ',' + (this.get('svgY') - this.get('scale') * 4) + ' ' + (this.get('svgX') + this.get('scale') * 4) + ',' + (this.get('svgY') - this.get('scale') * 8) + ' ' + (this.get('svgX') + this.get('scale') * 8) + ',' + (this.get('svgY') - this.get('scale') * 4);
      } else if (this.get('pathType') == 'plus_x') {
        return this.get('svgX') + this.get('scale') * 12 + ',' + this.get('svgY') + ' ' + (this.get('svgX') + this.get('scale') * 16) + ',' + (this.get('svgY') + this.get('scale') * 4) + ' ' + (this.get('svgX') + this.get('scale') * 12) + ',' + (this.get('svgY') + this.get('scale') * 8);
      } else if (this.get('pathType') == 'plus_y') {
        return this.get('svgX') + ',' + (this.get('svgY') + this.get('scale') * 12) + ' ' + (this.get('svgX') + this.get('scale') * 4) + ',' + (this.get('svgY') + this.get('scale') * 16) + ' ' + (this.get('svgX') + this.get('scale') * 8) + ',' + (this.get('svgY') + this.get('scale') * 12);
      } else if (this.get('pathType') == 'minus_x') {
        return this.get('svgX') - this.get('scale') * 4 + ',' + this.get('svgY') + ' ' + (this.get('svgX') - this.get('scale') * 8) + ',' + (this.get('svgY') + this.get('scale') * 4) + ' ' + (this.get('svgX') - this.get('scale') * 4) + ',' + (this.get('svgY') + this.get('scale') * 8);
      } else {
        return;
      }
    })
  });
});