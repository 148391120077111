define('loftus/models/price-change', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    effectiveAt: _emberData.default.attr('date'),
    appliedAt: _emberData.default.attr('date'),
    wholesale: _emberData.default.attr('number'),
    preferred: _emberData.default.attr('number'),
    premium: _emberData.default.attr('number'),
    affiliatePrice: _emberData.default.attr('number'),
    caseWholesale: _emberData.default.attr('number'),
    casePreferred: _emberData.default.attr('number'),
    casePremium: _emberData.default.attr('number'),
    threeCaseWholesale: _emberData.default.attr('number'),
    threeCasePreferred: _emberData.default.attr('number'),
    threeCasePremium: _emberData.default.attr('number'),
    previousWholesale: _emberData.default.attr('number'),
    previousPreferred: _emberData.default.attr('number'),
    previousPremium: _emberData.default.attr('number'),
    previousAffiliatePrice: _emberData.default.attr('number'),
    previousCaseWholesale: _emberData.default.attr('number'),
    previousCasePreferred: _emberData.default.attr('number'),
    previousCasePremium: _emberData.default.attr('number'),
    previousThreeCaseWholesale: _emberData.default.attr('number'),
    previousThreeCasePreferred: _emberData.default.attr('number'),
    previousThreeCasePremium: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    previousCost: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    user: _emberData.default.belongsTo('user')
  });
});