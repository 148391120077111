define('loftus/models/receiving-tote', ['exports', 'loftus/models/current', 'ember-data', 'ember-api-actions'], function (exports, _current, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _current.default.extend({
    filledAt: _emberData.default.attr('date'),
    transferredAt: _emberData.default.attr('date'),
    stockingAt: _emberData.default.attr('date'),
    completedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    tote: _emberData.default.belongsTo('tote'),
    receivingBin: _emberData.default.belongsTo('receiving-bin'),
    receivedItems: _emberData.default.hasMany('received-item'),
    zone: _emberData.default.attr('number'),
    showRemaining: false,
    canSearch: true,
    searchInput: '',
    unstockedItems: Ember.computed.filter('receivedItems.@each.quantityStocked', function (ri) {
      return ri.get('quantityUnstocked') > 0;
    }),
    riKeypads: Ember.computed.filter('receivedItems.@each.keypadVisible', function (ri) {
      return ri.get('keypadVisible');
    }),
    keypadVisible: Ember.computed('riKeypads', function () {
      return this.get('riKeypads.length') > 0;
    }),
    canContinue: Ember.computed('unstockedItems', function () {
      console.log(this.get('unstockedItems').length);
      return this.get('unstockedItems').length === 0;
    }),
    remaining: Ember.computed.alias('unstockedItems'),
    printTag: (0, _emberApiActions.memberAction)({ path: 'print_tag', type: 'put' }),
    projectRecordId: _emberData.default.attr('number'),
    scanPlaceholder: Ember.computed('stockingAt', function () {
      if (this.get('stockingAt')) {
        return 'Scan Barcode';
      } else {
        return 'Scan Tote';
      }
    })

  });
});