define('loftus/controllers/picking-tote/process', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      transition: function transition(route) {
        this.transitionToRoute(route);
      },
      inflatesComplete: function inflatesComplete() {
        var _this = this;
        this.get('model').markInflatesComplete().then(function (tote) {
          _this.get('store').pushPayload(tote);
        });
      },
      clearTote: function clearTote() {
        var _this = this;
        this.set('model.sortingAt', new Date());
        this.get('model').save().then(function (tote) {
          _this.transitionToRoute('index');
        });
      }
    }
  });
});