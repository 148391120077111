define('loftus/models/upc', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    sku: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    info: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' }),
    smiths: Ember.computed('info', function () {
      return this.get('info') && this.get('info').indexOf('SMITHS') > -1;
    }),
    sprouts: Ember.computed('info', function () {
      return this.get('info') && this.get('info').indexOf('SPROUTS') > -1;
    }),
    harmons: Ember.computed('info', function () {
      return this.get('info') && this.get('info').indexOf('HARMONS') > -1;
    }),
    walmart: Ember.computed('info', function () {
      return this.get('info') && this.get('info').indexOf('WALMART') > -1;
    }),
    generic: Ember.computed('info', function () {
      return this.get('info') && this.get('info').indexOf('GENERIC') > -1;
    }),
    status: Ember.computed('info', 'title', 'price', 'item', function () {
      return this.get('title') || this.get('price') || this.get('info') || this.get('item.id') ? 'Used - ' + this.get('item.sku') : 'Available';
    })

  });
});