define('loftus/models/customer-vendor-agreement', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    signedAt: _emberData.default.attr('date'),
    violation: _emberData.default.attr('string'),
    violatedAt: _emberData.default.attr('date'),
    deny: _emberData.default.attr('boolean'),
    agreementUrl: _emberData.default.attr('string'),
    secureUrl: _emberData.default.attr('string', { readOnly: true }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    customer: _emberData.default.belongsTo('customer'),
    vendor: _emberData.default.belongsTo('vendor')
  });
});