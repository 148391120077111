define('loftus/routes/purchase-order/receiving', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Project',
    model: function model() {
      return this.store.query('freight-shipment', { scope: ['shipped'] });
    }
  });
});