define('loftus/routes/zones/manage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').findRecord('zone', params.zone_id);
    },
    titleToken: function titleToken(model) {
      return 'Zone ' + model.get('id');
    }

  });
});