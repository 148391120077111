define('loftus/components/log-issue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentSession: Ember.inject.service('current-user'),
    currentUser: Ember.computed('currentSession', function () {
      return this.get('currentSession.user');
    }),

    actions: {
      close: function close() {
        this.set('visible', false);
      },
      submit: function submit(user) {

        var _this = this;

        var issue = user.store.createRecord('issue');
        issue.set('note', this.get('issueTranscript'));
        user.get('issues').addObject(issue);
        Ember.RSVP.all(user.get('issues').invoke('save')).then(function () {
          _this.set('issueTranscript', '');
          _this.set('visible', false);
        });
      },
      record: function record() {
        var _this = this;

        var recognition = new webkitSpeechRecognition();
        var final_transcript = '';

        recognition.onresult = function (event) {
          _this.set('recording', false);
          for (var i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
              final_transcript += event.results[i][0].transcript;
            }
            _this.set('issueTranscript', final_transcript);
          }
        };

        recognition.onstart = function () {
          _this.set('recording', true);
        };

        recognition.lang = 'en-US';

        recognition.start();
      }
    },

    issueTranscript: '',
    recording: false
  });
});