define('loftus/components/edit-note', ['exports', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_component.default, {
    directionOptions: ['inbound', 'outbound'],
    actions: {
      clearModal: function clearModal() {
        this.sendAction('clearModal');
      },
      save: function save() {
        this.get('note').save();
        this.sendAction('clearModal');
      },
      setPrivate: function setPrivate() {
        var note = this.get('note');
        if (note.get('markedPrivateAt')) {
          note.set('markedPrivateAt', null);
        } else {
          note.set('markedPrivateAt', new Date());
        }
      }
    },
    keyboardShortcuts: {
      'esc': function esc() {
        this.sendAction('clearModal');
        return false;
      }
    }
  });
});