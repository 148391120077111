define('loftus/components/process-tote', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    speech: Ember.inject.service(),
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {

      var _this = this;
      this.get('model.tote').then(function (tote) {
        _this.$('.mobile-title').html('Tote ' + tote.get('code'));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.mobile-title').html('');
    },

    shipment: Ember.computed.alias('model.pickedItems.firstObject.orderItem.order.shipment'),
    orders: Ember.computed.alias('shipment.orders'),
    remainingSorted: Ember.computed.sort('model.remainingItems', 'stockSorting'),
    stockSorting: ['orderItem.item.sku'],
    actions: {
      searchTote: function searchTote() {
        console.log(this.get('searchInput'));
      },
      readText: function readText(pi) {
        pi.get('box');
        if (!pi.spoken) {
          if (pi.get('pickingTote.lastBin') === pi.get('binLocation')) {
            this.get('speech').speak('Put ' + pi.get('quantityRemaining'));
          } else {
            // console.log(pi.get('id'));
            // console.log(pi.get('binLocation'));
            // pi.get('binLocation').then(location => {
            this.get('speech').speak('put ' + pi.get('quantityRemaining'));
            //});
          }

          if (pi.get('orderItem.order.labeledPricing') && pi.get('orderItem.item.productGroup')) {
            this.get('speech').speak('Label Required');
          }

          pi.set('pickingTote.lastBin', pi.get('binLocation'));
        }
        pi.set('pickingTote.lastBin', pi.get('binLocation'));
        pi.set('spoken', true);
      },
      continue: function _continue() {
        var _this2 = this;

        this.set('model.sortingAt', new Date());
        this.get('model').save().then(function (response) {
          _this2.sendAction('transition', 'totes');
        });
      },
      clearSearch: function clearSearch(pt) {
        this.set('model.searchInput', '');
      },
      searchProcessing: function searchProcessing() {
        var _this = this;
        this.set('box', null);
        this.set('showRemaining', null);
        var scan = this.get('searchInput');

        console.log(scan);
        this.get('remainingSorted').forEach(function (ti) {
          if (ti.get('quantityRemaining') > 0 && scan !== '' && (scan === ti.get('orderItem.item.sku') || scan === ti.get('orderItem.item.upc'))) {
            ti.set('visible', true);
          }
        });
        setTimeout(function () {
          _this.$('.scanned-box:visible').first().focus();
        }, 20);
        this.set('searchInput', null);
        // let scan = this.get('model.searchInput').toUpperCase();
        var toteRegex = /^(TOTE|tote)+(\d+)$/;
        console.log(scan);

        if (scan.slice(0, 3) === 'BOX') {
          var _this3 = this;
          this.set('model.searchInput', null);
          var box = this.get('store').createRecord('box');
          box.scan({ tag: scan }).then(function (response) {
            _this3.get('store').pushPayload(response);
            _this3.set('box', _this3.get('store').peekRecord('box', response.data.id));
            _this3.set('scan', '');
            _this3.$('.scanned-box').focus();
            if (_this3.get('box.nowShipped')) {
              _this3.get('speech').speak('Shipment Complete');
            }

            if (_this3.get('box.shipment.willCall')) {
              _this3.get('speech').speak('Will Call');
            }
          });
        } else if (scan.match(toteRegex)) {
          this.set('model.searchInput', null);
          var record = this.get('store').peekRecord('tote', scan.match(toteRegex)[2]);
          if (record) {
            this.get('router').transitionTo(record.get('current.route'), record.get('current.id'));
          } else {
            var _this4 = this;
            this.get('store').findRecord('tote', scan.match(toteRegex)[2]).then(function (record) {
              _this4.get('router').transitionTo(record.get('current.route'), record.get('current.id'));
            });
          }
        }

        if (this.get('toteId') > 0) {}
      },
      loadTote: function loadTote(id) {
        this.$('.search-input input').focus();
        this.get('router').transitionTo('picking-tote.process', id);
      },
      toggleShowRemaining: function toggleShowRemaining() {
        this.set('box', null);
        this.$('.scanned-item').focus();
        this.set('searchInput', null);
        this.get('remainingSorted').forEach(function (ti) {
          ti.set('visible', false);
        });
        this.toggleProperty('showRemaining');
        this.$('.scanned-item').focus();
      },
      showItem: function showItem(pi) {
        var _this = this;
        pi.set('visible', true);
        this.set('showRemaining', false);
        setTimeout(function () {
          _this.$('.scanned-box:visible').first().focus();
        }, 50);
      },
      printLabels: function printLabels(pi) {
        var _this = this;
        pi.get('customerLabel').printLabels({ quantity: pi.get('quantityRemaining') }).then(function (response) {
          pi.set('labelPrinted', true);
          _this.get('speech').speak('Printed');
          setTimeout(function () {
            _this.$('.scanned-box:visible').focus();
          }, 50);
        });
      },
      keypadDigit: function keypadDigit(pi, e) {
        var key = e.target.innerHTML * 1;
        var currentValue = pi.get('keypadValue');

        var input = this.$(e.target).closest('.keypad').find('.keypad-input');
        var max = input.attr('max') * 1;

        if (currentValue === '0' || currentValue === '') {
          pi.set('keypadValue', key);
        } else {

          var new_value = ('' + currentValue + key) * 1;
          if (max > 0) {
            pi.set('keypadValue', Math.min(new_value * 1, max));
          } else {
            pi.set('keypadValue', new_value);
          }
        }
      },

      keypadBack: function keypadBack(pi) {
        var currentValue = pi.get('keypadValue');
        pi.set('keypadValue', Math.floor(currentValue / 10));
      },

      keypadCancel: function keypadCancel(pi) {
        pi.set('keypadVisible', false);
        pi.set('createIssue', false);
      },

      keypadSubmit: function keypadSubmit(pi) {
        var _this = this;
        pi.set('keypadVisible', false);
        if (pi.get('pickingIssue') !== '') {
          this.send('createIssue', pi);
        } else {
          console.log('keypadSubmit');
          pi.set('toteInput', '');
        }
        setTimeout(function () {
          _this.$('.scanned-box:visible').first().focus();
        }, 200);
      },

      splitPick: function splitPick(pi) {
        pi.set('keypadValue', 0);
        pi.set('keypadVisible', true);
        this.get('speech').speak('split');
        var card = this.$('.processing-cards__card:visible').first();
        var scannedBox = card.find('.scanned-box');
        scannedBox.focus();
      },
      printProductGroup: function printProductGroup(pi) {
        var _this = this;
        pi.printLabels({ qty: pi.get('keypadValue') }).then(function (print) {
          _this.get('speech').speak('Printed');
          _this.$('.scanned-box:visible').focus();
        });
      },
      printBoxTag: function printBoxTag(pi) {
        var _this = this;
        pi.get('orderItem.shipment').then(function (shipment) {
          shipment.printBoxTag({ opts: 'toteProcessing' }).then(function (response) {
            _this.get('store').pushPayload(response);
            var box = _this.get('store').peekRecord('box', response.data.id);
            console.log(box);
            console.log(pi.get('shipment.id'));
            pi.set('shipment.box', box);
            pi.set('box', box);
            console.log('Box Response');
            console.log(response);
            _this.$('.processing-cards__card:visible .scanned-box').first().focus();
          });
        });
      },
      pickIssue: function pickIssue(pi) {
        pi.set('keypadValue', '');
        pi.set('createIssue', true);
      },
      setIssue: function setIssue(pi, issue) {
        pi.set('pickingIssue', issue);
        pi.set('createIssue', false);
        if (issue !== '') {
          pi.set('keypadVisible', true);
        }
      },
      createIssue: function createIssue(pi) {
        var _this = this;
        var qty = pi.get('keypadValue');

        var issueType = pi.get('pickingIssue');

        var issue = this.get('store').createRecord('picking-issue');

        if (issueType === 'Backordered') {
          pi.set('orderItem.quantityBackordered', (pi.get('orderItem.quantityBackordered') || 0) + qty);
          pi.set('quantity', pi.get('quantity') - qty);
          issue.set('issueType', 'backordered');
        } else if (issueType === 'Under Picked') {
          issue.set('issueType', 'under_picked');
        } else if (issueType === 'Over Picked') {
          issue.set('issueType', 'over_picked');
        } else if (issueType === 'Wrong Item') {
          issue.set('issueType', 'wrong_item');
        }

        issue.set('quantity', qty);

        pi.set('createIssue', false);
        pi.get('pickingIssues').addObject(issue);

        Ember.RSVP.all(pi.get('pickingIssues').invoke('save')).then(function () {
          _this.get('speech').speak('Issue Saved');

          pi.set('pickingIssue', '');

          pi.save().then(function () {

            if (pi.get('quantityRemaining') === 0) {
              pi.set('pickingTote.searchInput', '');
              _this.$('.scanned-item').focus();
            } else {
              pi.set('toteInput', '');
              pi.set('keypadValue', pi.get('quantityRemaining'));
              _this.$('.scanned-box:visible').first().focus();
            }
          });
        });
      },
      showKeypad: function showKeypad() {
        var card = this.$('.processing-cards__card:visible').first();
        card.find('.keypad').show();
      },
      hideKeypad: function hideKeypad() {
        var card = this.$('.processing-cards__card:visible').first();
        card.find('.keypad').hide();
      },

      submitPick: function submitPick(pi) {
        var _this = this;
        var card = this.$('.processing-cards__card:visible').first();
        var scannedBox = card.find('.scanned-box');

        console.log(pi.get('toteInput').toUpperCase());
        console.log(pi.get('boxCode'));

        if (pi.get('toteInput').toUpperCase() === pi.get('orderItem.item.upc') && pi.get('orderItem.order.labeledPricing') && pi.get('orderItem.item.productGroup')) {
          pi.set('toteInput', null);
          this.send('printProductGroup', pi);
          return;
        }

        if (pi.get('toteInput').toUpperCase() !== pi.get('boxCode')) {
          this.get('speech').speak('No');
          pi.set('toteInput', '');
          scannedBox.focus();
          return;
        }

        if (pi.get('toteInput').toUpperCase() === pi.get('boxCode')) {

          var boxContent = this.get('store').createRecord('box-content');

          var keypadValue = pi.get('keypadValue');
          boxContent.set('quantity', keypadValue);
          boxContent.set('boxId', pi.get('box.id'));
          boxContent.set('box', pi.get('box'));
          if (pi.get('unscannable')) {
            boxContent.set('unscanned', true);
          }

          pi.get('boxContents').addObject(boxContent);

          Ember.RSVP.all(pi.get('boxContents').invoke('save')).then(function () {

            pi.reload().then(function () {
              var _this5 = this;

              if (pi.get('quantityRemaining') === 0) {
                pi.set('pickingTote.searchInput', '');
                _this.$('.scanned-item').focus();
              } else {
                pi.set('pickingTote.searchInput', '');
                pi.set('toteInput', '');
                // pi.set('keypadValue',pi.get('quantityRemaining'));
                pi.set('keypadValue', 0);
                _this.$('.scanned-item').focus();
              }

              pi.get('orderItem.shipment').then(function (shipment) {
                shipment.isBoxed(function (response) {
                  console.log(_this5);
                  //this.store.pushPayload(response);

                  console.log(shipment.get('boxed'));
                  if (shipment.get('boxed')) {
                    _this.get('speech').speak('Shipment Complete');
                  }
                });
              });
            });
          });

          //        card.hide();
          //card.next().find('.scanned-box').focus();
        } else {

          pi.set('keypadValue', '');
          card.find('.scanned-box').focus();
          return;
        }
      }
    }
  });
});