define('loftus/models/project', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    layout: _emberData.default.attr('string'),
    template: _emberData.default.attr('string'),
    model: _emberData.default.attr('string'),
    whereClause: _emberData.default.attr('string'),
    joinTables: _emberData.default.attr('string'),
    orderBy: _emberData.default.attr('string'),
    batchSize: _emberData.default.attr('number'),
    minimumAssignment: _emberData.default.attr('number'),
    priority: _emberData.default.attr('number'),
    timeLimit: _emberData.default.attr('number'),
    refreshTime: _emberData.default.attr('number'),
    reassign: _emberData.default.attr('boolean'),
    mustShip: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    emberSort: _emberData.default.attr('string'),
    emberComplete: _emberData.default.attr('string'),
    emberCompleteTemplate: _emberData.default.attr('string'),
    continueRoute: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    projectUsers: _emberData.default.hasMany('project-user'),
    users: _emberData.default.hasMany('user'),
    remainingCount: _emberData.default.attr('number', { readOnly: true }),
    component: Ember.computed(function () {
      return 'project-' + this.get('template');
    }),
    getRemaining: (0, _emberApiActions.memberAction)({ path: 'remaining', type: 'get' })

  });
});