define('loftus/models/tote', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    maxWeight: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    pickingTotes: _emberData.default.hasMany('picking-tote', { inverse: 'tote' }),
    receivingTotes: _emberData.default.hasMany('receiving-tote', { inverse: 'tote' }),
    current: _emberData.default.belongsTo('current', { polymorphic: true, async: true }),
    type: Ember.computed('length', function () {
      if (this.get('length') == 27) {
        return 'tote';
      } else if (this.get('length') == 60) {
        return 'cart';
      } else {
        return 'unknown';
      }
    })

  });
});