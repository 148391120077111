define('loftus/models/picking-bin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    shipmentId: _emberData.default.attr('number'),
    shipment: _emberData.default.belongsTo('shipment'),
    projectRecordId: _emberData.default.attr('number'),
    tote: _emberData.default.attr('number'),
    // combineTote: DS.attr('number'),
    pickingTote: _emberData.default.belongsTo('picking-tote'),
    binType: _emberData.default.attr('string'),
    maintainShipment: _emberData.default.attr('boolean')

  });
});