define('loftus/models/project-batch', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('number'),
    completedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    details: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    project: _emberData.default.belongsTo('project'),
    recordCount: _emberData.default.attr('number', { readOnly: true }),
    recordStatus: _emberData.default.attr('number', { readOnly: true }),
    projectRecords: _emberData.default.hasMany('project-record'),
    release: (0, _emberApiActions.memberAction)({ path: 'release', type: 'put' }),
    records: Ember.computed.mapBy('projectRecords', 'model'),
    incompleteRecords: Ember.computed('sortedRecords.@each.completedAt', 'projectRecords.@each.location', 'projectRecords.@each.binLocation', function () {
      return this.get('projectRecords').filterBy('completedAt', null);
      //return !record.get('completedAt');
    }),
    cases: Ember.computed('project.name', function () {
      return this.get('project.name').indexOf('Case') > -1;
    }),
    progress: Ember.computed('records', 'incompleteRecords', function () {
      return this.get('records.length') - this.get('incompleteRecords.length') + 1;
    }),
    sortProp: ['model.bin.pickSection', 'model.bin.bestLocation.length', 'model.bin.bestLocation', 'id'],
    sortedRecords: Ember.computed.sort('incompleteRecords', 'sortOrder'),
    // computed('incompleteRecords.@each.completedAt','incompleteRecords.@each.location','projectRecords.@each.binLocation',function(){
    //   if (this.get('cases')) {
    //     this.get('incompleteRecords').forEach(function(rec){
    //       rec.set('model.cases',true);
    //     });
    //   }
    //   return this.get('incompleteRecords').sort(this.get('sortProp'));
    // }),
    sortOrder: Ember.computed('project.emberSort', 'projectRecords.@each.location', 'projectRecords.@each.binLocation', 'projectRecords.@each.stockingLocation', function () {
      console.log("Sort By:");
      console.log(this.get('project.emberSort').split(','));
      return this.get('project.emberSort').split(',');
    }),
    allSortedRecords: Ember.computed.sort('projectRecords', 'sortOrder'),
    sortedLocations: Ember.computed('allSortedRecords', function () {
      return this.get('allSortedRecords').reduce(function (acc, value) {
        acc.push(value.get('model.bin.pickSection') + ' ' + value.get('model.bin.location') + ' ' + value.get('model.item.sku'));
        return acc;
      }, []);
    }),
    visibleId: Ember.computed('sortedRecords.@each.completedAt', 'projectRecords.@each.location', 'projectRecords.@each.binLocation', 'projectRecords.@each.stockingLocation', function () {

      if (this.get('status') === 1) {
        return;
      }
      console.log('calculated visible id');
      var _this = this;
      this.get('sortedRecords').forEach(function (i) {
        _this.get('sortOrder').forEach(function (j) {
          console.log(i.get('id') + ' ' + i.get('model.sku') + ' ' + i.get('model.bin.location') + ' ' + j + ' ' + i.get(j));
        });
        // console.log(`${i.get('model.bin.pickSection')} ${i.get('model.bin.location')} ${i.get('model.item.sku')} ${i.get('id')}`);
        // console.log(`${i.get('location')} ${i.get('completedAt')}`);
      });
      this.get('sortedRecords').forEach(function (i) {
        // console.log(`${i.get('model.sku')} ${i.get('model.bin.location')} ${i.get('model.bin.categoryId')}`);
        console.log(i.get('id') + ' ' + i.get('model.sku') + ' ' + i.get('completedAt') + ' ' + i.get('model.stockingBin.stockingSort') + ' ' + i.get('model.stockingBin.location') + ' ' + i.get('location') + ' ' + i.get('binLocation'));
      });
      console.log('Sorted Record ID: ' + this.get('sortedRecords.firstObject.id'));
      console.log(this.get('sortedRecords.firstObject.completedAt'));
      console.log(this.get('sortedRecords'));
      return this.get('sortedRecords').sortBy('sortOrder').get('firstObject.id');
    }),
    canComplete: Ember.computed('incompleteRecords', 'project.emberCompleteTemplate', 'emberCompleted', function () {
      return (this.get('incompleteRecords').length === 0 || this.get('status') === 1) && this.get('project.emberCompleteTemplate') && !this.get('emberCompleted');
    }),
    canContinue: Ember.computed('incompleteRecords', function () {
      return this.get('incompleteRecords').length === 0 || this.get('status') === 1;
    }),
    seconds: Ember.computed('createdAt', function () {
      return (new Date() - this.get('createdAt')) / 1000;
    }),
    formatDetails: Ember.computed('details', function () {
      if (this.get('details')) {
        return this.get('details').replace(/\n/g, '<br>');
      }
    })
  });
});