define('loftus/controllers/purchase-order/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedOrderItems: Ember.computed.sort('model.orderItems', 'sortPicking'),
    sortPicking: ['location', 'item.sku'],
    sortReceiving: ['item.sku'],
    unpickedItems: Ember.computed.filter('sortedOrderItems', function (item) {
      return !item.get('pickedAt');
    }),
    sortedReceivingItems: Ember.computed.sort('model.orderItems', 'sortReceiving'),
    actions: {
      submit: function submit() {

        Ember.RSVP.all(this.model.get('purchaseOrderItems').invoke('save')).then(function () {
          console.log('saved');
        });
      }
    }
  });
});