define('loftus/models/freight-item', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    quantityReceived: _emberData.default.attr('number'),
    invoiceQuantity: _emberData.default.attr('number'),
    cubicMeters: _emberData.default.attr('number'),
    cartons: _emberData.default.attr('number'),
    otherAmount: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    freightShipment: _emberData.default.belongsTo('freight-shipment'),
    freightCarton: _emberData.default.belongsTo('freight-carton'),
    purchaseOrderItem: _emberData.default.belongsTo('purchase-order-item'),
    receivedItems: _emberData.default.hasMany('received-item'),
    item: Ember.computed.alias('purchaseOrderItem.item'),
    invoice: _emberData.default.attr('string'),
    discrepency: Ember.computed('quantity', 'quantityReceived', function () {
      return this.get('quantity') * 1 - this.get('quantityReceived') * 1;
    }),
    discrepencyValue: Ember.computed('quantity', 'quantityReceived', 'purchaseOrderItem.unitCost', function () {
      return (this.get('quantity') * 1 - this.get('quantityReceived') * 1) * this.get('purchaseOrderItem.unitCost');
    }),
    printTag: (0, _emberApiActions.memberAction)({ path: 'print_tag', type: 'put' }),
    freight: Ember.computed('freightShipment.subTotal', 'freightShipment.cubicMeters', 'freightShipment.freightAmount', 'cubicMeters', function () {
      if (this.get('freightShipment.cubicMeters') * 1 > 0 && this.get('freightShipment.freightAmount') * 1 > 0 && this.get('cubicMeters') * 1 > 0) {
        return this.get('cubicMeters') / this.get('freightShipment.cubicMeters') * this.get('freightShipment.freightAmount');
      } else {
        return this.get('extCost') / this.get('freightShipment.subTotal') * this.get('freightShipment.freightAmount');
      }
    }),
    brokerage: Ember.computed('freightShipment.cubicMeters', 'freightShipment.brokerageAmount', 'cubicMeters', function () {
      if (this.get('freightShipment.cubicMeters') * 1 > 0 && this.get('freightShipment.brokerageAmount') * 1 > 0 && this.get('cubicMeters') * 1 > 0) {
        return this.get('cubicMeters') / this.get('freightShipment.cubicMeters') * this.get('freightShipment.brokerageAmount');
      } else {
        return 0;
      }
    }),
    other: Ember.computed('freightShipment.invoiceTotal', 'freightShipment.otherAmount', 'invoiceTotal', function () {
      if (this.get('freightShipment.otherTotal') > 0) {
        return this.get('otherAmount');
      } else if (this.get('freightShipment.otherAmount') * 1 > 0) {
        return this.get('invoiceTotal') / this.get('freightShipment.invoiceTotal') * this.get('freightShipment.otherAmount');
      } else {
        return 0;
      }
    }),
    extCost: Ember.computed('purchaseOrderItem.unitCost', 'quantity', function () {
      return this.get('purchaseOrderItem.unitCost') * this.get('quantity') * 1;
    }),
    invoiceTotal: Ember.computed('invoiceQuantity', 'purchaseOrderItem.unitCost', function () {
      return this.get('invoiceQuantity') * this.get('purchaseOrderItem.unitCost');
    }),
    landedTotal: Ember.computed('quantity', 'purchaseOrderItem.unitCost', 'freight', 'brokerage', 'customs', function () {
      return this.get('quantity') * this.get('purchaseOrderItem.unitCost') + this.get('freight') + this.get('brokerage') + this.get('customs') + this.get('otherAmount');
    }),
    landedEach: Ember.computed('landedTotal', 'quantity', function () {
      return this.get('landedTotal') / this.get('quantity');
    }),
    avgEach: Ember.computed.alias('item.averageCost'),
    newAvgEach: Ember.computed('avgEach', 'item.onHand', 'quantity', 'landedEach', 'quantityRemaining', function () {
      return (this.get('avgEach') * this.get('item.onHand') + this.get('landedEach') * this.get('quantityRemaining')) / (this.get('quantityRemaining') + this.get('item.onHand'));
    }),
    customs: Ember.computed('invoiceTotal', 'other', 'item.duty', 'freightShipment.mpfRate', 'freightShipment.hmfRate', function () {
      console.log(this.get('purchaseOrderItem.item.sku'));
      console.log(this.get('invoiceTotal') + this.get('other'));
      return this.get('invoiceTotal') * ((this.get('item.duty') || 0) + (this.get('freightShipment.mpfRate') || 0) + (this.get('freightShipment.hmfRate') || 0));
    }),
    inputClass: Ember.computed('uniq', function () {
      return 'freight-item-' + this.get('uniq');
    }),
    quantityRemaining: Ember.computed('quantity', 'quantityReceived', function () {
      return Math.max(this.get('quantity') - this.get('quantityReceived') || 0, 0);
    }),
    incomplete: Ember.computed('quantity', 'quantityReceived', function () {
      return Math.max(this.get('quantity') - this.get('quantityReceived') || 0, 0) > 0;
    }),
    visible: Ember.computed('freightShipment.searchInput', 'freightShipment.showRemaining', 'forceVisible', function () {
      var search = (this.get('freightShipment.searchInput') + '').toUpperCase().split(' ')[0];
      return this.get('forceVisible') || this.get('quantityRemaining') > 0 && search !== '' && (search === this.get('item.sku') || search === this.get('item.upc') || search === this.get('item.reorderNumber') || search === this.get('item.caseCode') || search === this.get('item.innerCode'));
    })

  });
});