define('loftus/components/catalog-item-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.send('initDataTable');
    },
    actions: {
      initDataTable: function initDataTable() {
        if (this.$('table:visible tbody tr').length === 0) {
          var _this = this;
          setTimeout(function () {
            _this.send('initDataTable');
          }, 1000);
        }
        setTimeout(function () {
          var table = Ember.$('table:visible');
          if (!table.hasClass('dataTable')) {
            Ember.$('table:visible').dataTable({
              dom: 'Bfrtip',
              buttons: ['copyHtml5', 'excelHtml5', 'csvHtml5', 'pdfHtml5'],
              order: [[0, 'desc']] });
          }
        }, 15);
      }
    }
  });
});