define('loftus/components/manage-articles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      newArticle: function newArticle() {
        var article = this.get('store').createRecord('article');
        this.get('router').transitionTo('articles.edit', article);
      }
    }
  });
});