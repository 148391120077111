define('loftus/models/payment-token', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    token: _emberData.default.attr('string'),
    ccExpiresOn: _emberData.default.attr('date'),
    lastFour: _emberData.default.attr('string'),
    cardType: _emberData.default.attr('string'),
    avsResult: _emberData.default.attr('string'),
    cvvResult: _emberData.default.attr('string'),
    tokenType: _emberData.default.attr('string'),
    lastUsedAt: _emberData.default.attr('date'),
    gateway: _emberData.default.attr('string'),
    nonce: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    tokenError: _emberData.default.attr('string', { readOnly: true }),
    customer: _emberData.default.belongsTo('customer'),
    chain: _emberData.default.belongsTo('chain'),
    order: _emberData.default.belongsTo('order', { inverse: 'paymentToken' }),
    orders: _emberData.default.hasMany('order', { inverse: 'paymentToken' }),
    gatewayIcon: Ember.computed('gateway', function () {
      if (this.get('gateway') === 'heartland') {} else {}
    }),
    tokenDisplay: Ember.computed('tokenType', function () {
      if (this.get('tokenType')) {
        return this.get('tokenType').replace(/_/g, ' ');
      }
    }),
    cardIcon: Ember.computed('cardType', function () {
      if (this.get('cardType')) {
        return 'cc-' + this.get('cardType').toLowerCase();
      }
    }),
    expiredNotice: Ember.computed('ccExpiresOn', function () {
      if (this.get('ccExpiresOn') < new Date()) {
        return '[EXPIRED]';
      }
    })

  });
});