define('loftus/components/dynamic-high-charts', ['exports', 'ember-highcharts/components/high-charts'], function (exports, _highCharts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _highCharts.default.extend({

    // contentDidChange: Ember.observer('content.@each.isLoaded', function() {
    // var chart = this.get('chart');
    // console.log('chart');
    // console.log(chart);
    // var seriesName = this.get('content')[0].name;
    // chart.series[0].update({ name: seriesName, data: this.get('content')[0].data }, false);
    // chart.setTitle(null, { text: seriesName }, false);
    // chart.redraw();
    // })

  });
});