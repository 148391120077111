define('loftus/routes/order/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var shipment = this.store.createRecord('shipment');
      var order = this.store.createRecord('order', {
        shipment: shipment,
        salesTax: 0,
        subTotal: 0,
        freight: 0,
        total: 0,
        rbTotal: 0,
        freightAllowance: 0,
        credit: 0,
        status: 'pending'

      });

      return order;
    }
  });
});