define('loftus/components/order-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    orderNumber: '',
    orderId: Ember.computed('orderNumber', function () {
      return this.get('orderNumber') * 1;
    }),
    didInsertElement: function didInsertElement() {
      $('input.ember-power-select-search-input').focus();
    },
    actions: {
      select: function select(order) {
        this.sendAction('toggle');
        this.sendAction('transition', 'order.edit', order.id);
      },
      searchAsync: function searchAsync(data) {
        if (data.length > 2) {
          return this.get('store').createRecord('order').autoComplete({ search: data });
        }
      },
      searchOrders: function searchOrders() {
        console.log('search');
        if (this.get('orderId') > 0) {

          console.log(this.get('orderId'));
          this.sendAction('transition', 'order.edit', this.get('orderId'));
          this.set('orderNumber', '');
        }
      }
    }
  });
});