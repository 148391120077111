define('loftus/models/note', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    subject: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    markedPrivateAt: _emberData.default.attr('date'),
    createdById: _emberData.default.attr('number'),
    updatedById: _emberData.default.attr('number'),
    //notableType: DS.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    zohoId: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    customer: _emberData.default.belongsTo('customer', { async: true, inverse: 'notes' }),
    markedPrivate: Ember.computed('markedPrivateAt', function () {
      return !!this.get('markedPrivateAt');
    }),
    isPublic: Ember.computed('markedPrivate', function () {
      return !this.get('markedPrivate');
    })

    //notable: DS.belongsTo('notable')
  });
});