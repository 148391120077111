define('loftus/controllers/receiving-bins', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    cartOptions: Ember.computed(function () {
      return this.get('store').findAll('receiving-cart');
    }),
    binTypeOptions: ['tote', 'cart', 'full_tote', 'full_cart'],
    actions: {
      addCart: function addCart() {
        this.get('store').createRecord('receiving-bin');
      },
      saveBin: function saveBin(bin) {
        bin.save();
      }
    }
  });
});