define('loftus/components/project-confirmed-missing-items', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    speech: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.$('input[autofocus]').focus();
      this.$('.mobile-title').html(this.get('record.projectBatch.project.name'));
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.mobile-title').html('');
    },

    actions: {
      noStock: function noStock(record) {
        var currentTime = new Date();
        this.get('speech').speak('short');
        record.get('model').then(function (item) {
          item.set('lastCountedQuantity', 0);
          item.set('lastCountedAt', currentTime);
          item.set('confirmMissingValidated', true);
          item.save().then(function () {
            record.set('completedAt', new Date());
          });
        });
      },
      picked: function picked(record) {
        var currentTime = new Date();
        var _this = this;
        record.get('model').then(function (item) {

          if (!item.get('lastCountedQuantity') || item.get('lastCountedQuantity') * 1 === 0) {
            item.set('keypadVisible', true);
          } else {
            _this.get('speech').speak('picked');
            item.set('lastCountedAt', currentTime);
            item.set('confirmMissingValidated', true);
            item.save().then(function () {
              record.set('completedAt', new Date());
            });
          }
        });
      },
      keypadDigit: function keypadDigit(oi, e) {
        var key = e.target.innerHTML * 1;
        var currentValue = oi.get('keypadValue');

        if (currentValue === '0' || currentValue === '') {
          oi.set('keypadValue', key);
        } else {

          var newValue = ('' + currentValue + key) * 1;
          oi.set('keypadValue', newValue);
          oi.set('lastCountedQuantity', newValue);
        }
      },

      keypadBack: function keypadBack(oi) {
        var currentValue = oi.get('keypadValue');
        oi.set('keypadValue', Math.floor(currentValue / 10));
      },

      keypadCancel: function keypadCancel(oi) {
        oi.set('keypadVisible', false);
      },

      keypadSubmit: function keypadSubmit(record) {
        record.set('model.keypadVisible', false);
        this.send('picked', record);
      }

    }
  });
});