define('loftus/controllers/purchase-order/receive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      model: function model() {
        this.get('store').findAll('freight-shipment');
      },
      transition: function transition(route) {
        this.transitionToRoute(route);
      }
    }
  });
});