define('loftus/models/purchase-order-approval', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    approvedAt: _emberData.default.attr('date'),
    notes: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    purchaseOrder: _emberData.default.belongsTo('purchase-order'),
    user: _emberData.default.belongsTo('user'),
    disabled: Ember.computed('purchaseOrder.placedAt', function () {
      return !!this.get('purchaseOrder.placedAt');
    })
  });
});