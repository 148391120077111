define('loftus/models/receiving-bin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr('string'),
    binType: _emberData.default.attr('string'),
    bins: _emberData.default.hasMany('bin'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    position: _emberData.default.attr('number'),
    zone: _emberData.default.attr('number'),
    receivingTote: _emberData.default.belongsTo('receiving-tote'),
    receivingCart: _emberData.default.belongsTo('receiving-cart')
  });
});