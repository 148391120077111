define('loftus/components/project-correct-barcodes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      $('input[autofocus]').focus();
      $('.mobile-title').html(this.get('record.projectBatch.project.name'));
    },
    willDestroyElement: function willDestroyElement() {
      $('.mobile-title').html('');
    },

    actions: {
      submitUnscannable: function submitUnscannable(record) {
        record.get('model').then(function (item) {
          item.set('noUpc', true);
          item.save().then(function () {
            record.set('completedAt', new Date());
          });
        });
      },
      submitCorrection: function submitCorrection(record) {
        record.get('model').then(function (item) {
          item.set('upc', record.get('newUpc'));
          item.save().then(function () {
            record.set('completedAt', new Date());
          });
        });
      }
    }
  });
});