define('loftus/components/survey-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submitSurvey: function submitSurvey() {
        // Gather the entered questions and submit as a payload
        var surveyData = {
          questions: this.get('model.questions')
        };

        // Send the payload to your Ruby on Rails backend using Ember Data or AJAX
        // Example using Ember Data:
        this.get('model').setProperties(surveyData);
        this.get('model').save().then(function () {
          // Handle successful submission
        }).catch(function (error) {
          // Handle errors
        });
      }
    }
  });
});