define('loftus/models/shelf', ['exports', 'ember-api-actions', 'ember-data', 'loftus/models/recordable'], function (exports, _emberApiActions, _emberData, _recordable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _recordable.default.extend({
    location: _emberData.default.attr('string'),
    zone: _emberData.default.attr('number'),
    position: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    depth: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    bins: _emberData.default.hasMany('bin'),
    items: _emberData.default.hasMany('items', { async: true }),
    projectRecordId: _emberData.default.attr('number'),
    verifiedAt: _emberData.default.attr('date'),
    verifiedBy: _emberData.default.belongsTo('user'),
    section: Ember.computed('location', function () {
      return this.get('location').slice(0, -1);
    }),
    verify: (0, _emberApiActions.memberAction)({ path: 'verify', type: 'put' }),
    nextSku: '',
    nextItem: Ember.computed.filter('items.@each.upc', function (item) {
      return item.get('upc') === this.get('nextSku');
    }),
    keypadValue: 0,
    keypadVisible: true,
    keypadTarget: 'width',
    binnedItems: Ember.computed('items.@each.bins', function () {
      return this.get('items');
    }),
    binnedIds: Ember.computed.map('bins', function (bin) {
      return +bin.get('id');
    }),
    unbinnedItems: Ember.computed.filter('binnedItems', function (item) {
      console.log(item.get('sku'));
      console.log(item.get('bins.length'));
      console.log(item.get('bins.firstObject.shelfId'));
      console.log(item.get('bins').filterBy('shelfId', this.get('id')).length);
      console.log(item.get('bins').filterBy('shelf.id').get('firstObject.location'));
      return Ember.isEmpty(item.get('bins').filterBy('shelf.id'), this.get('id'));
    }),
    sortLocation: ['location'],
    sortedBins: Ember.computed.sort('bins', 'sortLocation'),
    sortedItems: Ember.computed.sort('unbinnedItems', 'sortSku'),
    sortSku: ['sku'],
    nextBinOpen: (0, _emberApiActions.memberAction)({ path: 'create_open_bin', type: 'put' })

  });
});