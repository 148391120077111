define('loftus/models/order', ['exports', 'ember-data', 'ember-api-actions', 'moment'], function (exports, _emberData, _emberApiActions, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    placedAt: _emberData.default.attr('date'),
    terms: _emberData.default.attr('string'),
    poNumber: _emberData.default.attr('string'),
    enteredBy: _emberData.default.attr('string'),
    origin: _emberData.default.attr('string'),
    replyBy: _emberData.default.attr('string'),
    subTotal: _emberData.default.attr('number'),
    freightAllowance: _emberData.default.attr('number'),
    freight: _emberData.default.attr('number'),
    freightDiscountPercent: _emberData.default.attr('number'),
    freightCost: _emberData.default.attr('number'),
    handlingCost: _emberData.default.attr('number'),
    credit: _emberData.default.attr('number'),
    salesTax: _emberData.default.attr('number'),
    recent: _emberData.default.attr({ readOnly: true }),
    totes: _emberData.default.attr({ readOnly: true }),
    totalsBalance: _emberData.default.attr({ readOnly: true }),
    total: _emberData.default.attr('number'),
    paymentAmount: _emberData.default.attr('number'),
    waiveSmallOrderFee: _emberData.default.attr('boolean'),
    labeledPricing: _emberData.default.attr('boolean'),
    specialOrder: _emberData.default.attr('boolean'),
    dropShip: _emberData.default.attr('boolean'),
    callOnArrival: _emberData.default.attr('boolean'),
    holdForSubstitutions: _emberData.default.attr('boolean'),
    coldCall: _emberData.default.attr('boolean'),
    fillBackorders: _emberData.default.attr('boolean'),
    notifyBackorders: _emberData.default.attr('boolean'),
    invoiceHash: _emberData.default.attr('string'),
    prop65Labeling: _emberData.default.attr('string'),
    invoiceLink: _emberData.default.attr('string', { readOnly: true }),
    billedAt: _emberData.default.attr('date', { readOnly: true }),
    cancelAt: _emberData.default.attr('date'),
    ediSentAt: _emberData.default.attr('date'),
    rbTotal: _emberData.default.attr('number', { readOnly: true }),
    billWarning: _emberData.default.attr('boolean', { readOnly: true }),
    processFailure: _emberData.default.attr('string'),
    uvFullyImported: _emberData.default.attr('boolean', { readOnly: true }),
    canHardSave: Ember.computed('status', function () {
      return this.get('status') !== 'billed' && this.get('status') !== 'paid';
    }),
    cost: _emberData.default.attr('number'),
    orderType: _emberData.default.attr('number'),
    status: _emberData.default.attr('string', { readOnly: true }),
    approvedAt: _emberData.default.attr('date'),
    approvedBy: _emberData.default.belongsTo('user'),
    largeApproval: Ember.computed('events', function () {
      return this.get('events').filterBy('activity', 'Attempted Order Approval').get('firstObject');
    }),
    clearApproval: _emberData.default.attr('boolean'),
    user: _emberData.default.belongsTo('user'),
    quote: _emberData.default.belongsTo('quote'),
    promo: _emberData.default.belongsTo('promo'),
    hasPromo: Ember.computed('promo.id', function () {
      return false;
      //return !!this.get('promo.id');
    }),
    prop65Needed: Ember.computed('customer.prop65', 'orderItems.@each.prop65', function () {
      var option = false;
      this.get('orderItems').forEach(function (qi) {
        if (qi.get('item.prop65') === 'label_required' || qi.get('item.prop65') === 'restricted') {
          option = true;
        }
      });
      return option && this.get('customer.prop65');
    }),
    prop65Satisfied: Ember.computed('customer.prop65', 'prop65Labeling', 'orderItems.@each.prop65', function () {
      if (!this.get('customer.prop65')) {
        return true;
      }
      if (this.get('prop65Labeling')) {
        return true;
      }
      var option = true;
      this.get('orderItems').forEach(function (qi) {
        if (qi.get('prop65') === 'label_required' || qi.get('prop65') === 'restricted') {
          option = false;
        }
      });
      return option;
    }),
    orderIssues: Ember.computed('orderItems.@each.issuesLength', function () {
      var issues = [];
      if (this.get('orderItems')) {
        this.get('orderItems').forEach(function (oi) {
          if (oi.get('orderIssues')) {
            oi.get('orderIssues').forEach(function (issue) {
              issues.pushObject(issue);
            });
          }
        });
      }

      return issues;
    }),
    disableForPromo: Ember.computed('open', 'hasPromo', function () {
      return this.get('hasPromo') || this.get('closed');
    }),
    disableTracking: Ember.computed('closed', 'trackingNumber', function () {
      if (this.get('closed') && this.get('shipment.trackingNumber.length') > 0 && !this.get('shipment.hasDirtyAttributes')) {
        return true;
      }
    }),
    profit: _emberData.default.attr('number', { readOnly: true }),
    grossProfit: Ember.computed('cost', 'ecSubTotal', function () {
      return this.get('ecSubTotal') - this.get('cost');
    }),
    canApprove: Ember.computed('validations', function () {
      return !this.get('approvedAt') && !this.get('validations') || this.get('enteredBy') === 'APPROVED';
    }),
    ecTotalsBalance: Ember.computed('ecTotal', 'rbTotal', 'subTotal', 'ecSubTotal', 'totalsBalance', function () {
      return this.get('ecTotal') === this.get('rbTotal') && this.get('ecSubTotal') === this.get('subTotal') && this.get('totalsBalance');
    }),
    canBill: Ember.computed('freight', 'terms', 'open', 'ecTotal', 'rbTotal', 'totalsBalance', function () {
      return !this.get('hasDirtyAttributes') && this.get('open') && this.get('ecTotal') === this.get('rbTotal') && this.get('totalsBalance') && (this.get('rbTotal') >= 0 || this.get('terms') && this.get('terms').indexOf('NET') === -1);
    }),
    canReverse: Ember.computed('open', 'billedAt', function () {
      return !this.get('open') && this.get('billedAt') && this.get('billedAt') > new Date() - 60 * 60 * 12 * 1000;
    }),
    canReverseExtended: Ember.computed('open', 'billedAt', function () {
      return this.get('billedAt') && this.get('billedAt').getMonth() == new Date().getMonth() && this.get('billedAt').getYear() == new Date().getYear();
    }),
    canFillBackorders: Ember.computed('customer.contacts.@each.purchasing', function () {
      return !!this.get('customer.contacts').filterBy('purchasing', true).get('firstObject');
    }),
    validations: Ember.computed('changed', 'shipment.thirdParty', 'prop65Labeling', 'unfulfillable', 'notifyBackorders', 'fillBackorders', 'cancelAt', 'origin', 'specialOrder', 'event.id', 'orderItems.@each.eventId', 'orderItems.@each.quantity', 'orderItems.@each.quantityBackordered', 'orderItems.@each.hazmat', 'event.shippingStartsOn', 'payments.length', 'shipment.validatedAt', 'customer.disablePurchasing', 'paymentToken.id', 'status', 'enteredBy', 'approvedAt', 'approvedBy', 'paymentToken.id', 'terms', 'shipment.shippingService.id', 'shipment.city', 'shipment.state', 'shipment.country', 'shipment.shippingService.carrier', 'shipment.canShipBy', 'event.shippingStartsOn', function () {
      if (this.get('approvedAt') || this.get('enteredBy') === 'APPROVED' || this.get('closed')) {
        return;
      }
      var validations = [];

      if (this.get('customer.disablePurchasing')) {
        validations.pushObject('Customer cannot purchase');
      }

      if (!this.get('terms')) {
        validations.pushObject('Terms must be selected.');
      }

      if (this.get('terms') && (this.get('terms').indexOf('CASH') > -1 || this.get('terms').indexOf('CREDIT CARD') > -1) && this.get('terms') !== 'CASH' && !this.get('paymentToken.id')) {
        validations.pushObject('Credit Card must be selected');
      }

      var _this = this;

      var events = this.get('orderItems').filter(function (oi) {
        if (oi.get('available') < 0 && oi.get('quantityBackordered') < oi.get('quantity') && _this.get('origin') !== 'WEB' && _this.get('origin') !== 'EMAIL' && _this.get('origin') !== 'FAX' && _this.get('origin') !== 'IMPORT' && _this.get('origin') !== 'SHOW' && _this.get('event.id') * 1 !== 1 && (!_this.get('event.id') || _this.get('event.id') !== oi.get('item.event.id'))) {
          return true;
        }
      });

      if (events.length > 0 && !this.get('shipment.thirdParty')) {
        validations.pushObject('Correct event must be set');
      }

      if (!this.get('shipment.shippingService.id')) {
        validations.pushObject('Must select a Shipping Service');
      }

      if (!this.get('prop65Satisfied')) {
        validations.pushObject('Select Prop 65 Handling');
      }

      if (!this.get('shipment.willCall') && this.get('orderItems').filterBy('hazmat', 'pressurized').length > 0) {
        var hazmat = { value: 0, cases: 0 };
        this.get('orderItems').filterBy('hazmat', 'pressurized').forEach(function (oi) {
          var cs = oi.get('title').slice(0, 2);
          if (!hazmat[cs]) {
            hazmat[cs] = { qty: 0, value: 0, caseQty: oi.get('item.caseQuantity') };
          }
          hazmat[cs]['qty'] = hazmat[cs]['qty'] + oi.get('quantity');
          hazmat['value'] = hazmat['value'] + oi.get('ecExtPrice');
          hazmat['cases'] = hazmat['cases'] + oi.get('quantity') / oi.get('item.caseQuantity');
        });

        if (hazmat['value'] < 750) {

          if (hazmat['12'] && hazmat['12']['qty'] % hazmat['12']['caseQty'] > 0) {
            validations.pushObject('Must add ' + (hazmat['12']['caseQty'] - hazmat['12']['qty'] % hazmat['12']['caseQty']) + ' 12" cannons');
          }
          if (hazmat['18'] && hazmat['18']['qty'] % hazmat['18']['caseQty'] > 0) {
            validations.pushObject('Must add ' + (hazmat['18']['caseQty'] - hazmat['18']['qty'] % hazmat['18']['caseQty']) + ' 18" cannons');
          }
          if (hazmat['24'] && hazmat['24']['qty'] % hazmat['24']['caseQty'] > 0) {
            validations.pushObject('Must add ' + (hazmat['24']['caseQty'] - hazmat['24']['qty'] % hazmat['24']['caseQty']) + ' 24" cannons');
          }
          this.set('hazmatFee', Math.round(Math.ceil(hazmat.cases) * 100) / 100 * 30);
        } else {
          this.set('hazmatFee', 0);
        }
      }

      if (this.get('customer.preauthRequiredAt') && this.get('terms') && this.get('terms') === 'CREDIT CARD' && this.get('payments.length') === 0 && !this.get('shipment.canShipBy') && !this.get('event.shippingStartsOn') && !this.get('specialOrder')) {
        validations.pushObject('Pre-Auth Required');
      }

      if (this.get('unfulfillable')) {
        if (!this.get('event') && !this.get('notifyBackorders') && !this.get('fillBackorders')) {
          validations.pushObject('Select Fill or Notify Backorders');
        } else if (this.get('fillBackorders') && !this.get('cancelAt')) {
          validations.pushObject('Select Cancel Date for Backorders');
        }

        if (this.get('event') && this.get('event.preorderEndsAt') < new Date()) {
          validations.pushObject('Preorder ended. Can\'t oversell.');
        }
      }

      // if (this.get('shipment.city') !== this.get('shipment.city').toUpperCase()) {
      //   validations.pushObject('Shipment City must be capitalized');
      // }

      // if (this.get('shipment.state') && this.get('shipment.state') !== this.get('shipment.state').toUpperCase()) {
      //   validations.pushObject('Shipment State must be capitalized');
      // }

      if (this.get('shipment.country').length !== 2) {
        validations.pushObject('Shipment Country must be 2 characters');
      }

      if (this.get('shipment.country') === 'US' && this.get('shipment.state').length !== 2) {
        validations.pushObject('Shipment State must be 2 characters');
      }

      if (this.get('shipment.country') !== this.get('shipment.country').toUpperCase()) {
        validations.pushObject('Shipment Country must be capitalized');
      }

      if (!this.get('shipment.validatedAt')) {
        validations.pushObject('Shipment Address must be validated');
      }

      if (this.get('terms') === 'PROFORMA') {
        validations.pushObject('PROFORMA can\'t be approved');
      }

      if (this.get('changed')) {
        validations.pushObject('Save Order to Approve');
      }

      if (validations.length > 0) {
        return validations;
      }
    }),
    commissionRate: _emberData.default.attr('number'),
    invoicedAt: _emberData.default.attr('date'),
    taxRegion: _emberData.default.attr('string'),
    international: _emberData.default.attr('boolean'),
    requiresSpecialProcessing: _emberData.default.attr('boolean'),
    fba: _emberData.default.attr('boolean'),
    creditCard: Ember.computed('terms', function () {
      return ['CASH', 'CREDIT CARD', 'CASH VISA', 'CASH AMEX', 'CASH MASTERCARD', 'CASH DISCOVER', 'AMEX', 'MASTERCARD', 'VISA', 'DISCOVER', 'CREDIT MEMO'].indexOf(this.get('terms')) > -1;
    }),
    chargeTerms: Ember.computed('terms', 'status', function () {
      return this.get('status') === 'billed' && this.get('terms').indexOf('NET') > -1;
    }),
    canCharge: Ember.computed('paymentsTotal', 'ecTotal', 'creditCard', 'total', 'rbTotal', function () {
      console.log(this.get('chargeTerms') || this.get('creditCard'));
      console.log(this.get('ecTotal'));
      console.log(this.get('rbTotal'));
      console.log(this.get('paymentsTotal'));

      return (this.get('chargeTerms') || this.get('creditCard')) && this.get('ecTotal') === this.get('rbTotal') && this.get('ecTotal') > 0 && this.get('paymentsTotal') < this.get('ecTotal');
    }),
    canRefund: Ember.computed('paymentsTotal', 'ecTotal', 'creditCard', 'total', 'rbTotal', 'paymentAmount', function () {
      return this.get('creditCard') && this.get('ecTotal') === this.get('rbTotal') && (this.get('ecTotal') < this.get('paymentsTotal') || this.get('paymentAmount') < 0);
    }),
    canSaveClosed: Ember.computed('canCharge', 'canRefund', function () {
      return this.get('canCharge') || this.get('canRefund') || !this.get('disableTracking');
    }),
    comments: _emberData.default.attr('string'),
    customerComments: _emberData.default.attr('string'),
    warehouseNotes: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    customer: _emberData.default.belongsTo('customer', { async: true }),
    purchaseOrders: _emberData.default.hasMany('purchase-order'),
    documents: _emberData.default.hasMany('document'),
    itemAlerts: _emberData.default.hasMany('item-alert'),
    paymentToken: _emberData.default.belongsTo('payment-token', { inverse: 'orders' }),
    shipTo: _emberData.default.belongsTo('ship-to'),
    salesRep: _emberData.default.belongsTo('sales-rep', { async: true }),
    shipment: _emberData.default.belongsTo('shipment'),
    shipmentId: _emberData.default.attr('number'),
    relatedOrderId: _emberData.default.attr('number'),
    event: _emberData.default.belongsTo('event'),
    events: _emberData.default.hasMany('order-event'),
    eventSort: ['createdAt:desc'],
    sortedEvents: Ember.computed.sort('events', 'eventSort'),
    returns: _emberData.default.hasMany('order-returns'),
    paymentTokens: _emberData.default.hasMany('payment-token', { inverse: 'order' }),
    orderItems: _emberData.default.hasMany('order-item'),
    ownedItems: Ember.computed.alias('orderItems'),
    payments: _emberData.default.hasMany('order-payment'),
    subJournals: _emberData.default.hasMany('sub-journal'),
    endCommitment: Ember.computed('createdAt', 'approvedAt', function () {
      var week = new Date();
      week.setDate(week.getDate() - 7);
      if (!this.get('approvedAt') && this.get('specialOrder')) {
        return "Special Orders must be approved for inventory to be committed.";
      }
      if (!this.get('approvedAt') && this.get('createdAt') < week) {
        return "This order is unapproved and older than 1 week.  Inventory will no longer be committed for this order.";
      }
    }),
    shipExpectation: Ember.computed('approvedAt', 'event.shippingStartsAt', 'event.shippingEndsAt', 'specialOrder', 'shipment.shipWhenAvailable', 'shipment.mustShipBy', 'shipment.mustReceiveBy', 'shipment.canShipBy', 'ecTotal', 'closed', 'shipment.shippedAt', function () {
      if (this.get('closed') || this.get('shipment.shippedAt')) {
        return;
      }

      if (this.get('event')) {
        return { from: this.get('event.shippingStartsAt'), to: this.get('event.shippingEndsAt'), approved: this.get('approvedAt') };
      }

      return { estimate: 'Tomorrow', approved: this.get('approvedAt') };
    }),

    paymentsTotal: Ember.computed('payments.@each.amount', 'payments.@each.status', function () {
      return this.get('payments').reduce(function (acc, value) {
        console.log(value);
        if (value.get('status') === 'pending' || value.get('status') === 'success') {
          acc += value.get('amount');
        }
        return acc;
      }, 0);
    }),
    oversold: Ember.computed('orderItems.@each.oversold', function () {
      var oversold = false;
      this.get('orderItems').forEach(function (oi) {
        if (oi.get('oversold')) {
          oversold = true;
        }
      });
      return oversold;
    }),
    canContinue: false,
    addonItems: Ember.computed('approvedAt', 'orderItems.@each.createdAt', function () {
      var _this = this;
      return this.get('orderItems').filter(function (item) {
        if (item.get('item.itemType') === 'special') {
          return false;
        }
        return _this.get('approvedAt') && item.get('createdAt') > _this.get('approvedAt');
      });
    }),
    hasAddons: Ember.computed('approvedAt', 'addonItems', function () {
      return this.get('approvedAt') && this.get('addonItems').length > 0;
    }),
    deleteable: Ember.computed('open', 'approvedAt', function () {
      return this.get('open') && !this.get('approvedAt');
    }),
    voidable: Ember.computed('open', function () {
      return this.get('open') && !this.get('approvedAt');
    }),
    open: Ember.computed('status', function () {
      return this.get('status') !== 'paid' && this.get('status') !== 'billed' && this.get('status') !== 'void';
    }),
    canEdit: Ember.computed('shipment.station.id', function () {
      return !this.get('shipment.station.id');
    }),
    packing: Ember.computed('shipment.station.id', function () {
      return !!this.get('shipment.station.id');
    }),
    closed: Ember.computed('status', function () {
      return this.get('status') === 'paid' || this.get('status') === 'billed' || this.get('status') === 'void';
    }),
    openShipments: Ember.computed.filter('shipments', function (shipment) {
      return !shipment.get('shippedAt');
    }),
    openShipment: Ember.computed('openShipments', function () {
      return this.get('openShipments.firstObject');
    }),
    allShipments: Ember.computed.filter('shipments', function () {
      return true;
    }),
    boxSize: _emberData.default.attr('number'),
    boxWatch: Ember.observer('shipment.boxes', 'boxCount', function () {
      this.set('boxSize', this.get('boxCount'));
    }),
    boxCount: Ember.computed('shipment.boxes', function () {
      return this.get('shipment.boxes.length') || 0;
    }),
    unboxedPicks: Ember.computed.filterBy('orderItems', 'isUnboxedRemaining'),
    boxedPicks: Ember.computed.filterBy('orderItems', 'isBoxed'),
    pickedItems: Ember.computed.filterBy('orderItems', 'isPicked'),
    pickingTotes: Ember.computed('orderItems.@each.pickTotes', function () {
      var ids = [];
      return this.get('orderItems').reduce(function (acc, value) {
        value.get('pickTotes').forEach(function (pt) {
          if (ids.indexOf(pt.get('id')) === -1) {
            ids.push(pt.get('id'));
            acc.push(pt);
          }
        });
        return acc;
      }, []);
    }),
    remainingTotes: Ember.computed.filterBy('pickingTotes', 'isComplete', false),
    freeFreight: Ember.computed('ecSubTotal', 'customer.discountFreightPercent', 'customer.noFreightAmount', 'customer.chain.freeFreight', 'orderItems.@each.quantity', function () {
      if (this.get('customer.noFreightAmount') > 0 && this.get('customer.noFreightAmount') < this.get('ecSubTotal')) {
        // && this.get('customer.discountFreightPercent') === 100) {
        return true;
      }
      if (this.get('customer.chain.freeFreight')) {
        return true;
      }
      var skus = this.get('orderItems').reduce(function (acc, value) {
        acc.push(value.get('item.sku'));
        return acc;
      }, []);
      return skus.indexOf('PR-E') > -1;
    }),
    discountFreight: Ember.computed('orderItems.@each.quantity', function () {
      var skus = this.get('orderItems').reduce(function (acc, value) {
        acc.push(value.get('item.sku'));
        return acc;
      }, []);
      return skus.indexOf('PR-E10') > -1;
    }),
    baseSubTotal: Ember.computed('orderItems.@each.basePrice', 'orderItems.@each.quantity', function () {
      return Math.round(this.get('orderItems').reduce(function (acc, value) {
        acc += value.get('basePrice') * value.get('quantity');
        return acc;
      }, 0) * 100) / 100;
    }),
    promoSubTotal: Ember.computed('promo.id', 'promo.promoItems.@each.discountCategory', 'orderItems.@each.basePrice', 'orderItems.@each.quantity', function () {
      if (!this.get('promo.id')) {
        return 0;
      }
      return Math.round(this.get('orderItems').reduce(function (acc, value) {

        if (value.get('promoEligible')) {
          acc += value.get('basePrice') * value.get('quantity');
        }
        return acc;
      }, 0) * 100) / 100;
    }),
    liveSubTotal: Ember.observer('orderItems.@each.quantity', 'orderItems.@each.quantityBackordered', function () {
      var _this = this;
      if (!this.get('isLoaded')) {
        return;
      }
      return this.get('orderItems').reduce(function (acc, value) {
        var subTotal = acc + value.get('extPrice'); //(value.get('price') * (value.get('quantity') * 1 - value.get('quantityBackordered') * 1));

        subTotal = Math.round(subTotal * 100) / 100;
        _this.set('subTotal', subTotal);
        return subTotal;
      }, 0);
    }),
    ecSubTotal: Ember.computed('orderItems.@each.quantity', 'orderItems.@each.quantityBackordered', 'orderItems.@each.ecExtPrice', function () {
      return this.get('filterCommentsOrderItems').reduce(function (acc, value) {
        var subTotal = acc + value.get('ecExtPrice'); //(value.get('price') * (value.get('quantity') * 1 - value.get('quantityBackordered') * 1));
        subTotal = Math.round(subTotal * 100) / 100;
        return subTotal;
      }, 0);
    }),
    ecAvailable: Ember.computed('ownedItems.@each.availableExt', function () {
      return Math.round(this.get('ownedItems').reduce(function (acc, value) {
        acc += value.get('availableExt');
        return acc;
      }, 0) * 100) / 100;
    }),
    ecSmallRequired: Ember.computed('ownedItems.@each.availableExt', function () {
      return Math.round((100 - this.get('ecAvailable')) * 100) / 100;
    }),
    salesTaxObserver: Ember.observer('ecSalesTax', function () {
      this.set('salesTax', this.get('ecSalesTax'));
    }),
    ecSalesTax: Ember.computed('status', 'ecSubTotal', 'customer.taxable', function () {
      if (!this.get('open')) {
        return this.get('salesTax');
      }
      if (this.get('customer.taxable')) {
        return Math.round(this.get('ecSubTotal') * 7.75) / 100;
      } else {
        return 0;
      }
    }),
    liveTax: Ember.observer('status', 'subTotal', 'customer.taxable', function () {
      if (this.get('open') && this.get('customer.taxable')) {
        this.set('salesTax', Math.round(this.get('subTotal') * 7.75) / 100);
      }
    }),
    insurance: Ember.computed('freightPortion', 'shipment.charge', 'boxCount', 'subTotal', function () {
      if (!this.get('open')) {
        return 0;
      }
      if (this.get('freightPortion') || 0 > 0) {

        return Math.round(Math.ceil(this.get('subTotal') / 100) * 35) / 100;
      } else {
        return 0;
      }
    }),
    freightPortion: Ember.computed('customer.chain.name', 'subTotal', 'shipment.subTotal', 'shipment.charge', function () {
      if (this.get('shipment.charge') + this.get('shipment.subTotal') === 0 || this.get('customer.chain.name') === "Smith's") {
        return 0;
      } else {
        return Math.round(this.get('subTotal') / this.get('shipment.subTotal') * this.get('shipment.charge') * 100) / 100;
      }
    }),
    finalFreightPortion: Ember.computed('subTotal', 'shipment.subTotal', 'shipment.charge', 'shipment.credit', function () {
      if (this.get('shipment.charge') + this.get('shipment.subTotal') === 0) {
        return 0;
      } else {
        return Math.round(this.get('subTotal') / this.get('shipment.subTotal') * (this.get('shipment.charge') + this.get('shipment.credit')) * 100) / 100;
      }
    }),
    shipmentPlusInsurance: Ember.computed('freightPortion', 'insurance', 'shipment.charge', function () {
      if (!this.get('open')) {
        return this.get('freight');
      }
      if ((this.get('freightPortion') || 0) * 1 === 0) {
        return 0;
      }
      return (this.get('insurance') || 0) * 1 + (this.get('freightPortion') || 0) * 1;
    }),
    // liveFreight: observer('shipment.charge','shipment.credit','boxCount','subTotal','freight','insurance',function(){
    //   if (!this.get('open')) {
    //     return;
    //   }
    //   let freight = 0;

    //   if (this.get('freeFreight')) {
    //     freight = 0; 
    //   } else if (this.get('boxCount') === 0 && this.get('shipment.charge') === 0) {
    //     freight = this.get('freight');
    //   } else {
    //     freight = this.get('shipmentPlusInsurance') + this.get('freightAllowance');
    //   }
    //   this.set('freight',freight);

    // }),
    ecTotal: Ember.computed('ecSubTotal', 'shipmentPlusInsurance', 'ecFreightAllowance', 'ecSalesTax', 'ecCredit', function () {
      return Math.round((this.get('ecSubTotal') + (this.get('shipmentPlusInsurance') || 0) + (this.get('ecFreightAllowance') || 0) + (this.get('ecSalesTax') || 0) + (this.get('ecCredit') || 0)) * 100) / 100;
    }),
    liveTotal: Ember.observer('subTotal', 'freight', 'freightAllowance', 'credit', 'salesTax', function () {

      if (this.get('model.open')) {
        var total = Math.round((this.get('subTotal') + this.get('salesTax') * 1 + this.get('freight') * 1 + this.get('credit') * 1) * 100) / 100;
        this.set('total', total);
      }
    }),
    freightAllowanceDisplay: Ember.computed('freeFreight', 'shipmentPlusInsurance', 'subTotal', 'customer.noFreightAmount', 'customer.discountFreightPercent', function () {
      return this.get('ecFreightAllowance') * -1;
    }),
    ecFreight: Ember.computed('ecFreightAllowance', 'shipmentPlusInsurance', function () {
      console.log('ecFreight');
      console.log(this.get('shipmentPlusInsurance'));
      console.log(this.get('ecFreightAllowance'));
      return Math.max(this.get('shipmentPlusInsurance') + this.get('ecFreightAllowance'), 0);
    }),
    ecFreightAllowance: Ember.computed('orderItems.@each.quantity', 'orderItems.@each.quantityBackordered', 'orderItems.@each.ecExtPrice', 'freeFreight', 'shipmentPlusInsurance', 'ecSubTotal', 'subTotal', 'customer.noFreightAmount', 'customer.discountFreightPercent', 'customer.chain.name', 'freightDiscountPercent', function () {

      if (this.get('closed')) {
        return this.get('freightAllowance');
      } else if (this.get('ecSubTotal') <= 0 || this.get('shipment.shippingService.expedited')) {
        return 0;
      } else {
        if (this.get('shipmentPlusInsurance') === 0) {
          return 0;
        } else if (this.get('freeFreight')) {
          //- || this.get('customer.noFreightAmount') > 0 && this.get('customer.noFreightAmount') <= this.get('subTotal')) {
          return this.get('shipmentPlusInsurance') * -1;
        } else if (this.get('customer.noFreightAmount') > 0 && this.get('customer.noFreightAmount') < this.get('ecSubTotal') && this.get('customer.discountFreightPercent') > 0) {
          return Math.min(this.get('shipmentPlusInsurance'), Math.round(this.get('subTotal') * this.get('customer.discountFreightPercent')) / 100) * -1;
        } else if (this.get('freightDiscountPercent') > 0) {
          return Math.min(this.get('shipmentPlusInsurance'), Math.round(this.get('subTotal') * this.get('freightDiscountPercent')) / 100) * -1;
        } else if (this.get('discountFreight')) {
          if (this.get('shipmentPlusInsurance') || 0 > 0) {}
          return Math.min(this.get('shipmentPlusInsurance'), Math.round(this.get('subTotal') * 10) / 100) * -1;
        }
      }

      return 0;
    }),
    creditDisplay: Ember.computed('credit', function () {
      return this.get('ecCredit') * -1;
    }),
    ecCredit: Ember.computed('ecSubTotal', 'ecFreightAllowance', 'customer.discountPercent', function () {
      if (this.get('closed')) {
        return this.get('credit') || 0;
      } else if (this.get('ecSubTotal') <= 0) {
        return 0;
      } else if (this.get('customer.chain.discountPercent') && this.get('customer.chain.discountPercent') > 0) {
        return Math.round(this.get('customer.chain.discountPercent') / 100 * this.get('ecSubTotal') * 100) / 100 * -1;
      } else if (this.get('customer.discountPercent') && (!this.get('ecFreightAllowance') || this.get('ecFreightAllowance') === 0 /*|| this.get('customer.chain.name') === "Smith's"*/) || this.get('customer.chain.name') === "Reams") {
        // && !this.get('discountFreight') && !this.get('freeFreight')) {
        return Math.round(this.get('customer.discountPercent') / 100 * this.get('ecSubTotal') * 100) / 100 * -1;
      } else {
        return 0;
      }
    }),
    ecCreditDisplay: Ember.computed('ecCredit', function () {
      return this.get('ecCredit') * -1;
    }),
    sortedOrderItems: Ember.computed.sort('orderItems', 'sortPicking'),
    inflateOption: Ember.computed('customer.alwaysInflate', 'orderItems.@each.canInflate', function () {
      return !this.get('customer.alwaysInflate') && this.get('orderItems').filterBy('canInflate').length > 0;
    }),
    unfulfillableItems: Ember.computed.filterBy('orderItems', 'unfulfillable', true),
    unfulfillable: Ember.computed('unfulfillableItems', function () {
      return this.get('unfulfillableItems.length') > 0;
    }),
    filterCommentsOrderItems: Ember.computed.filter('sortedOrderItems', function (oi) {
      return !oi.get('commentOrderItemId');
    }),
    sortPicking: ['position', 'uvId', 'item.sku'],
    duplicate: (0, _emberApiActions.memberAction)({ path: 'duplicate', type: 'put' }),
    bill: (0, _emberApiActions.memberAction)({ path: 'bill', type: 'put' }),
    reverse: (0, _emberApiActions.memberAction)({ path: 'reverse', type: 'put' }),
    ship: (0, _emberApiActions.memberAction)({ path: 'ship', type: 'put' }),
    voidShipment: (0, _emberApiActions.memberAction)({ path: 'void_shipment', type: 'put' }),
    queue: (0, _emberApiActions.memberAction)({ path: 'queue', type: 'put' }),
    addItems: (0, _emberApiActions.memberAction)({ path: 'add_items', type: 'put' }),
    voidOrder: (0, _emberApiActions.memberAction)({ path: 'void_order', type: 'put' }),
    pdfPickTicket: (0, _emberApiActions.memberAction)({ path: 'pick_ticket', type: 'get' }),
    pdfPickTicketAddons: (0, _emberApiActions.memberAction)({ path: 'pick_ticket', type: 'get' }),
    pdfInvoice: (0, _emberApiActions.memberAction)({ path: 'invoice', type: 'get' }),
    pdfPackingSlip: (0, _emberApiActions.memberAction)({ path: 'packing_slip', type: 'get' }),
    excelInvoice: (0, _emberApiActions.memberAction)({ path: 'excel', type: 'get' }),
    emailInvoice: (0, _emberApiActions.memberAction)({ path: 'email_invoice', type: 'put' }),
    emailPackingSlip: (0, _emberApiActions.memberAction)({ path: 'email_packing_slip', type: 'put' }),
    printPackingSlip: (0, _emberApiActions.memberAction)({ path: 'print_packing_slip', type: 'put' }),
    printPickTicket: (0, _emberApiActions.memberAction)({ path: 'print_pick_ticket', type: 'put' }),
    printInvoice: (0, _emberApiActions.memberAction)({ path: 'print_invoice', type: 'put' }),
    chargeCard: (0, _emberApiActions.memberAction)({ path: 'charge_card', type: 'put' }),
    refundCard: (0, _emberApiActions.memberAction)({ path: 'refund_card', type: 'put' }),
    authCard: (0, _emberApiActions.memberAction)({ path: 'auth_card', type: 'put' }),
    getEmailConfirmation: (0, _emberApiActions.memberAction)({ path: 'email_confirmation', type: 'get' }),
    autoComplete: (0, _emberApiActions.collectionAction)({ path: 'ac', type: 'get' }),
    query: (0, _emberApiActions.collectionAction)({ path: '/', type: 'get' }),
    emailInvoiceData: Ember.computed('customer.contact', 'customer.email', function () {
      return { name: this.get('customer.contact'),
        email: this.get('customer.email'),
        subject: 'Loftus International Invoice #' + this.get('id'),
        body: 'A copy of your invoice is attached.' };
    }),
    emailPackingSlipData: Ember.computed('customer.contact', 'customer.email', function () {
      return { name: this.get('customer.contact'),
        email: this.get('customer.email'),
        subject: 'Loftus International Packing Slip #' + this.get('id'),
        body: 'A copy of your packing slip is attached.' };
    }),
    emailReturnLabelData: Ember.computed('customer.contact', 'customer.email', function () {
      return { name: this.get('customer.contact'),
        email: this.get('customer.email'),
        subject: 'Loftus International Return Label for Order #' + this.get('id'),
        body: 'A return label is attached.' };
    }),
    changed: Ember.computed('hasDirtyAttributes', 'shipment.hasDirtyAttributes', 'orderItems.@each.hasDirtyAttributes', 'returns.@each.hasDirtyAttributes', function () {
      if (this.get('hasDirtyAttributes')) {}

      return this.get('hasDirtyAttributes') || this.get('shipment.hasDirtyAttributes') || this.get('orderItems').filterBy('hasDirtyAttributes', true).length > 0 || this.get('returns').filterBy('hasDirtyAttributes', true).length > 0;
    }),
    saveButtonText: Ember.computed('changed', function () {
      if (this.get('changed')) {
        return 'Save Order';
      } else {
        return 'Order Saved';
      }
    })
  });
});