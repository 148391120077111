define('loftus/routes/user/oauth', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      return this.get('ajax').request('/api/users/oauth');
    },
    afterModel: function afterModel(model, transition) {
      console.log(model);
      console.log(transition);
      window.open(model.url);
    }
  });
});