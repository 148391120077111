define('loftus/models/alert', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    voice: _emberData.default.attr('boolean'),
    email: _emberData.default.attr('boolean'),
    sms: _emberData.default.attr('boolean'),
    model: _emberData.default.attr('string'),
    event: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    contact: _emberData.default.belongsTo('contact')
  });
});