define('loftus/models/project-user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    priority: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    project: _emberData.default.belongsTo('project'),
    user: _emberData.default.belongsTo('user'),
    training: _emberData.default.attr('boolean'),
    effectivePriority: Ember.computed('priority', 'project.priority', function () {
      return this.get('priority') || this.get('project.priority');
    })
  });
});