define('loftus/components/order-stream', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cableService: Ember.inject.service('cable'),
    currentSession: Ember.inject.service('current-user'),
    users: [],
    activeSeconds: 10,
    updateFrequency: 5,
    willDestroyElement: function willDestroyElement() {
      this.set('users', []);
      this.get('consumer').subscriptions.remove('OrdersChannel');
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      return;

      if (this.get('subscription')) {
        this.get('subscription').send({ user: this.get('currentSession.user.name'), orderId: this.get('order.id'), lastActivity: new Date(), trigger: 'subscription' });
      } else {
        var _this = this;
        var consumer = this.get('cableService').createConsumer('wss://api.loftus.com/websocket');
        this.set('consumer', consumer);
        var subscription = consumer.subscriptions.create("OrdersChannel", {
          connected: function connected() {
            setTimeout(function () {
              _this.send('updateStatus');
            }, _this.get('updateFrequency') * 1000);
            this.send({ user: _this.get('currentSession.user.name'), orderId: _this.get('order.id'), lastActivity: new Date(), trigger: 'connected' });
          },

          received: function received(data) {
            if (data.orderId * 1 === _this2.get('order.id') * 1) {
              if (data.user !== _this2.get('currentSession.user.name')) {

                var users = _this.get('users');
                for (var i = 0; i < users.length; i++) {
                  if (users[i].user === data.user) {
                    users.splice(i, 1);
                  }
                }

                users.pushObject(data);

                var activeUsers = users.filter(function (data) {
                  return (0, _moment.default)(data.lastActivity) > (0, _moment.default)().subtract(_this.get('activeSeconds'), 'seconds');
                });

                _this.set('users', activeUsers);

                setTimeout(function () {
                  _this.send('checkStatus');
                }, _this2.get('activeSeconds') * 1000);
              }
            }
          },
          disconnected: function disconnected() {}
        });

        this.set('subscription', subscription);
      }
    },
    actions: {
      updateStatus: function updateStatus() {
        this.get('subscription').send({ user: this.get('currentSession.user.name'), orderId: this.get('order.id'), lastActivity: new Date(), trigger: 'refreshed' });
        var _this = this;
        setTimeout(function () {
          _this.send('updateStatus');
        }, this.get('updateFrequency') * 1000);
      },
      checkStatus: function checkStatus() {
        var _this = this;
        var activeUsers = this.get('users').filter(function (data) {
          return (0, _moment.default)(data.lastActivity) > (0, _moment.default)().subtract(_this.get('activeSeconds'), 'seconds');
        });

        this.set('users', activeUsers);
      }
    }
  });
});