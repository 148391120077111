define('loftus/models/route', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    action: _emberData.default.attr('string'),
    assignedAt: _emberData.default.attr('date'),
    delta: 0,
    interval: null,
    startAt: null,
    timer: Ember.computed('assignedAt', 'abortedAt', 'completedAt', 'failure', 'delta', function () {
      if (!this.get('startAt') && this.get('assignedAt')) {
        this.set('startAt', new Date());
      }
      var _this = this;

      // console.log(`StartAt: ${this.get('startAt')}`);
      // console.log(`assignedAt: ${this.get('assignedAt')}`);
      // console.log(`completedAt: ${this.get('completedAt')}`);
      // console.log(`abortedAt: ${this.get('abortedAt')}`);
      // console.log(`failure: ${this.get('failure')}`);

      if (this.get('startAt') && !this.get('completedAt') && !this.get('abortedAt') && !this.get('failure')) {

        setTimeout(function () {
          var start = _this.get('startAt');
          var delta = Date.now() - start;
          _this.set('delta', delta);
          console.log('update delta');
        }, 900);
      }

      // return this.get('delta');
    }),
    completedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    queuedAt: _emberData.default.attr('date'),
    centerAt: _emberData.default.attr('date'),
    centeringAt: _emberData.default.attr('date'),
    centeredAt: _emberData.default.attr('date'),
    canceledAt: _emberData.default.attr('date'),
    abortedAt: _emberData.default.attr('date'),
    bot: _emberData.default.belongsTo('bot', { inverse: 'route' }),
    botId: _emberData.default.attr('number'),
    pod: _emberData.default.belongsTo('pod'),
    bin: _emberData.default.belongsTo('bin'),
    point: _emberData.default.belongsTo('point'),
    routeType: _emberData.default.attr('string'),
    details: _emberData.default.attr('', { readOnly: true }),
    failure: _emberData.default.attr('boolean'),
    nextRoute: _emberData.default.belongsTo('route'),
    routePoints: _emberData.default.hasMany('route-point'),
    bots: _emberData.default.hasMany('bot', { inverse: 'route' })
  });
});