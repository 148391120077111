define('loftus/routes/purchase-order-issues', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.query('purchase-order-issue', { scope: ['unresolved', 'notified'] });
    }
  });
});