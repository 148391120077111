define('loftus/models/freight-shipment', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    shipmentId: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    shippedAt: _emberData.default.attr('date'),
    portArrivalAt: _emberData.default.attr('date'),
    customsClearedAt: _emberData.default.attr('date'),
    deliveredAt: _emberData.default.attr('date'),
    freightAmount: _emberData.default.attr('number'),
    dutyAmount: _emberData.default.attr('number'),
    brokerageAmount: _emberData.default.attr('number'),
    otherAmount: _emberData.default.attr('number'),
    netWeight: _emberData.default.attr('number'),
    grossWeight: _emberData.default.attr('number'),
    cubicMeters: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    cartonCount: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    vendor: _emberData.default.belongsTo('vendor'),
    printer: _emberData.default.belongsTo('printer'),
    mpfRate: _emberData.default.attr('number'),
    hmfRate: _emberData.default.attr('number'),
    pods: _emberData.default.attr('boolean'),
    totalUnits: _emberData.default.attr('number', { readOnly: true }),
    discrepancy: _emberData.default.attr('number', { readOnly: true }),
    discrepancyValue: _emberData.default.attr('number', { readOnly: true }),
    subTotals: Ember.computed.mapBy('freightItems', 'extCost'),
    subTotal: Ember.computed.sum('subTotals'),
    freightItems: _emberData.default.hasMany('freight-item'),
    documents: _emberData.default.hasMany('document'),
    itemSort: ['createdAt'],
    sortedItems: Ember.computed.sort('freightItems', 'itemSort'),
    invoiceTotals: Ember.computed.mapBy('freightItems', 'invoiceTotal'),
    invoiceTotal: Ember.computed.sum('invoiceTotals'),
    freightTotals: Ember.computed.mapBy('freightItems', 'freight'),
    freightTotal: Ember.computed.sum('freightTotals'),
    customsTotals: Ember.computed.mapBy('freightItems', 'customs'),
    customsTotal: Ember.computed.sum('customsTotals'),
    brokerageTotals: Ember.computed.mapBy('freightItems', 'brokerage'),
    brokerageTotal: Ember.computed.sum('brokerageTotals'),
    otherTotals: Ember.computed.mapBy('freightItems', 'otherAmount'),
    otherTotal: Ember.computed.sum('otherTotals'),
    landedTotals: Ember.computed.mapBy('freightItems', 'landedTotal'),
    landedTotal: Ember.computed.sum('landedTotals'),
    volumes: Ember.computed.mapBy('freightItems', 'cubicMeters'),
    volumeTotal: Ember.computed.sum('volumes'),
    volume: Ember.computed('freightItems.@each.cubicMeters', 'volumeTotal', function () {
      console.log(this.get('volumeTotal'));
      return Math.round(this.get('volumeTotal') * 1000) / 1000;
    }),
    emailReport: (0, _emberApiActions.memberAction)({ path: 'email_report', type: 'put' }),
    emailDiscrepencies: (0, _emberApiActions.collectionAction)({ path: 'email_discrepencies', type: 'put' }),
    receive: (0, _emberApiActions.memberAction)({ path: 'receive', type: 'put' }),
    import: (0, _emberApiActions.memberAction)({ path: 'import', type: 'post' }),
    remainingItems: Ember.computed.filter('freightItems.@each.quantityRemaining', function (fi) {
      return fi.get('quantityRemaining') > 0;
    })

  });
});