define('loftus/components/customers-index', ['exports', 'loftus/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(
  //  KeyboardShortcuts,
  {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    chainOptions: Ember.computed(function () {
      return this.get('store').findAll('chain');
    }),
    clubOptions: Ember.computed(function () {
      return ['Balloon Planet', 'PFA', 'NCA', 'ANY'];
    }),
    salesRepOptions: Ember.computed(function () {
      return this.get('store').findAll('sales-rep');
    }),
    categoryOptions: ["Balloon Planet", "Chain Grocery", "Costume", "Decorator", "Direct Importer", "Distributor/Manufacturer", "Ecommerce", "Education", "Entertainer", "Floral", "Health Care", "Independent Grocery", "Internal", "International", "Magic/Novelty", "Party", "Sales Rep", "Seasonal", "Spanish", "Specialty"],
    discountOptions: ["No Discount", "Fa Discount", "Trade Discount", "Defective Discount"],
    pricingOptions: ["Wholesale", "Preferred", "Premium", "Partner", "Grocery"],
    columns: [{ title: 'Account', name: 'account', column: 0, targets: [0], visible: true }, { title: 'Company', name: 'company', column: 1, targets: [1], visible: true }, { title: 'DBA', name: 'dba', column: 2, targets: [2], visible: false }, { title: 'Address', name: 'address', column: 3, targets: [3], visible: false }, { title: 'City', name: 'city', column: 4, targets: [4], visible: true }, { title: 'State', name: 'state', column: 5, targets: [5], visible: true }, { title: 'Zip', name: 'zip', column: 6, targets: [6], visible: false }, { title: 'Country', name: 'country', column: 7, targets: [7], visible: false }, { title: 'Contact', name: 'contact', column: 8, targets: [8], visible: false }, { title: 'Phone', name: 'phone', column: 9, targets: [9], visible: false }, { title: 'Email', name: 'email', column: 10, targets: [10], visible: false }, { title: 'Amazon Seller', name: 'amazon_seller', column: 11, targets: [11], visible: false }, { title: 'Account Created', name: 'entered_at', column: 12, targets: [12], visible: false }, { title: 'Pricing', name: 'customer_type', column: 13, targets: [13], visible: false }, { title: 'Discount', name: 'discount_type', column: 14, targets: [14], visible: false }, { title: 'Chain', name: 'chain', column: 15, targets: [15], visible: false }, { title: 'Club', name: 'club', column: 16, targets: [16], visible: false }, { title: 'Category', name: 'category', column: 17, targets: [17], visible: false }],
    actions: {
      pikaDate: function pikaDate(field, date) {
        this.set(field, date);
      },
      fetchReport: function fetchReport() {

        var query = {
          q: 'query',
          query: {
            dates: {
              created_start_date: this.get('createdStartDate'),
              created_end_date: this.get('createdEndDate'),
              last_order_start_date: this.get('lastOrderStartDate'),
              last_order_end_date: this.get('lastOrderEndDate')
            },
            strings: {
              company: this.get('company'),
              name: this.get('name'),
              address1: this.get('address1'),
              city: this.get('city'),
              state: this.get('state'),
              zip: this.get('zip'),
              country: this.get('country'),
              contact: this.get('contact'),
              phone: this.get('phone'),
              email: this.get('email'),
              category: this.get('category'),
              amazon_seller: this.get('amazonSeller') },
            symbols: {
              customer_type: this.get('pricing'),
              discount_type: this.get('discount')
            },
            matches: {
              chain_id: this.get('chain.id'),
              sales_rep_id: this.get('salesRep.id'),
              status: this.get('orderStatus'),
              growth_account: this.get('growthAccount')
            },

            club: this.get('club')
          }
        };
        var _this = this;
        this.set('queryResults', null);
        this.get('ajax').request(_environment.default.host + '/customers', { method: 'GET', data: query }).then(function (response) {
          _this.set('queryResults', response);
          setTimeout(function () {
            Ember.$('table').dataTable({
              lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
              dom: 'Blfrtip',
              columnDefs: _this.get('columns'),
              buttons: ['copyHtml5', 'excelHtml5', 'csvHtml5', 'pdfHtml5'] });
          }, 50);
        });
      },
      setSelect: function setSelect(field, selected) {
        this.set(field, selected);
      },
      toggleField: function toggleField(field) {
        var table = Ember.$('table').dataTable();
        var column = table.api().column(field.column);
        column.visible(!column.visible());
      }

    }

  });
});